// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-param-reassign */
import makeEverythingAttachToOTHelpers from './makeEverythingAttachToOTHelpers';

const domExtras = {};

domExtras.isElementNode = function (node) {
  return node && typeof node === 'object' && node.nodeType === 1;
};

domExtras.createElement = function (nodeName, attributes, children, doc) {
  const element = (doc || document).createElement(nodeName);
  attributes = attributes || {};

  Object.keys(attributes).forEach((name) => {
    if (typeof (attributes[name]) === 'object') {
      if (!element[name]) {
        element[name] = {};
      }

      const subAttrs = attributes[name];

      Object.keys(subAttrs).forEach((n) => {
        element[name][n] = subAttrs[n];
      });
    } else if (name === 'className') {
      element.className = attributes[name];
    } else {
      element.setAttribute(name, attributes[name]);
    }
  });

  const setChildren = function (child) {
    if (typeof child === 'string') {
      element.innerHTML += child;
    } else {
      element.appendChild(child);
    }
  };

  if (Array.isArray(children)) {
    children.forEach(setChildren);
  } else if (children) {
    setChildren(children);
  }

  return element;
};

makeEverythingAttachToOTHelpers(domExtras);

export default domExtras;

export default (contentHint) => {
  let scalabilityMode;
  switch (contentHint) {
    case 'motion':
      scalabilityMode = 'L3T1';
      break;
    case 'detail':
    case 'text':
    case '':
    default:
      scalabilityMode = 'L1T3';
      break;
  }
  return scalabilityMode;
};

const ExceptionCodes = {
  JS_EXCEPTION: 2000,
  AUTHENTICATION_ERROR: 1004,
  INVALID_SESSION_ID: 1005,
  CONNECT_FAILED: 1006,
  CONNECT_REJECTED: 1007,
  CONNECTION_TIMEOUT: 1008,
  NOT_CONNECTED: 1010,
  INVALID_PARAMETER: 1011,
  P2P_CONNECTION_FAILED: 1013,
  API_RESPONSE_FAILURE: 1014,
  PEER_CONNECTION_NOT_CONNECTED: 1015,
  TERMS_OF_SERVICE_FAILURE: 1026,
  CONNECTION_LIMIT_EXCEEDED: 1027,
  UNABLE_TO_PUBLISH: 1500,
  UNABLE_TO_SUBSCRIBE: 1501,
  UNSUPPORTED_VIDEO_CODEC: 1502,
  UNABLE_TO_FORCE_DISCONNECT: 1520,
  UNABLE_TO_FORCE_UNPUBLISH: 1530,
  UNABLE_TO_FORCE_MUTE: 1540,
  PUBLISHER_ICE_WORKFLOW_FAILED: 1553,
  SUBSCRIBER_ICE_WORKFLOW_FAILED: 1554,
  STREAM_LIMIT_EXCEEDED: 1605,
  UNEXPECTED_SERVER_RESPONSE: 2001,
  REPORT_ISSUE_ERROR: 2011,
  SET_PROXY_URL_TIMING_ERROR: 2021,
  PROXY_URL_ALREADY_SET_ERROR: 2022,
  GET_RTC_STATS_REPORT_NOT_SUPPORTED: 2031,
  ANVIL_BADLY_FORMED_RESPONSE: 3001,
  ANVIL_INVALID_HTTP_STATUS: 3002,
  ANVIL_XDOMAIN_OR_PARSING_ERROR: 3003,
  ANVIL_UNKNOWN_HTTP_ERROR: 3004,
  ANVIL_UNEXPECTED_ERROR_CODE: 3005,
  ANVIL_EMPTY_RESPONSE_BODY: 3006,
  ANVIL_CONNECT_FAILED: 3007,
  UNABLE_TO_ACCESS_MEDIA_ENGINE: 5001,
};

export default ExceptionCodes;

/* eslint-disable no-self-assign */
// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-param-reassign, global-require, one-var */
/* eslint-disable no-cond-assign,  max-len, no-void, prefer-const */

import eventing from '../../../helpers/eventing';
import createLogger from '../../../helpers/log';
import eventHelper from '../../../helpers/eventHelper';
import destroyObj from '../../../helpers/destroyObj';
import patchSrcObject from '../../../helpers/patchSrcObject';
import isDomElementVisible from '../../../helpers/isDomElementVisible';
import promiseDelay from '../../../helpers/promiseDelay';
import env from '../../../helpers/env';
import noop from '../../../helpers/noop';
import deviceHelpers from '../../deviceHelpers';
import currentAudioOutputDevice from '../../../ot/currentAudioOutputDevice';
import audioContextFactory from '../../../helpers/audio_context';
import webAudioAudioLevelSampler from '../../../helpers/audio_level_samplers/webaudio_audio_level_sampler';
import canBeOrientatedMixinDefault from '../can_be_oriented_mixin';
import listenForTracksEndedFactory from '../listenForTracksEnded';
import OTHelpersDefault from '../../../common-js-helpers/OTHelpers';
import videoElementErrorMapFactory from '../videoElementErrorMap';
import createVideoElement from '../../createVideoElement/index';

const { hasAudioOutputApiSupport } = deviceHelpers();
const { getCurrentAudioOutputDeviceId } = currentAudioOutputDevice;

function createDomVideoElement(fallbackText, muted) {
  const videoElement = createVideoElement();
  patchSrcObject(videoElement);
  videoElement.innerHTML = fallbackText;

  if (muted === true) {
    videoElement.muted = 'true';
  }

  return videoElement;
}

function createDomAudioOnlyVideoElement() {
  const audioOnlyVideoElement = createDomVideoElement('');
  audioOnlyVideoElement.setAttribute('style', 'display:none');

  return audioOnlyVideoElement;
}

/**
 * NativeVideoElementWrapperFactory DI container
 *
 * @package
 * @param {any} [deps={}]
 */
function NativeVideoElementWrapperFactory(deps = {}) {
  const audioContextProvider = deps.audioContext || audioContextFactory();
  const canBeOrientatedMixin = deps.canBeOrientatedMixin || canBeOrientatedMixinDefault;
  /** @type {Document} */
  const document = deps.document || global.document;
  const listenForTracksEnded =
    deps.listenForTracksEnded || listenForTracksEndedFactory();
  const createLog = deps.logging || createLogger;
  const OTHelpers = deps.OTHelpers || OTHelpersDefault;
  const videoElementErrorMap = deps.videoElementErrorMap || videoElementErrorMapFactory();
  const WebAudioLevelSampler = deps.WebaudioAudioLevelSampler || webAudioAudioLevelSampler;
  const windowMock = deps.global || global;

  let id = 1;

  /**
   * NativeVideoElementWrapper
   *
   * @package
   * @class
   * @param {Object} options
   * @param {Object} [options._inject] injected variables @todo move to DI
   * @param {Function} [options._inject.createVideoElement] function used to create video element
   * @param {Function} [options._inject.createAudioOnlyVideoElement] function used to create audio only video element
   * @param {Boolean} [options.muted] initial mute state
   * @param {String} [options.fallbackText] text to display when not supported
   * @param {Function} errorHandler callback for when there are errors
   * @param {Number} defaultAudioVolume the initial audio volume
   */

  class NativeVideoElementWrapper {
    /** @type {HTMLVideoElement|undefined} */
    _domElement;
    _domAudioOnlyVideoElement;
    /** @type {number|undefined} */
    _blockedVolume;
    _mediaStoppedListener;
    _audioLevelSampler;
    _playInterrupts = 0;
    _isVideoLoadedMetaData = false;
    _audioOnlyVideoElementWatcher;
    _blinkStartTime = null;

    constructor(
      {
        _inject: {
          createVideoElement: _createVideoElement = createDomVideoElement,
          createAudioOnlyVideoElement:
            _createAudioOnlyVideoElement = createDomAudioOnlyVideoElement,
        } = {},
        muted,
        fallbackText,
        widgetType,
      },
      defaultAudioVolume
    ) {
      this.logging = createLog(`NativeVideoElementWrapper:${id}`);
      id += 1;
      eventing(this);
      this._defaultAudioVolume = defaultAudioVolume;
      this._widgetType = widgetType;

      this._emitVideoPlayAttempt = () => {
        this._blinkStartTime = (new Date()).getTime();
        this.trigger('amrLogEvent', 'AMRTransitionVideo', 'Attempt');
      };

      this._emitVideoPlaySuccess = () => {
        const attemptDuration = (new Date()).getTime() - this._blinkStartTime;
        this.trigger('amrLogEvent', 'AMRTransitionVideo', 'Success', { attemptDuration });
        this._blinkStartTime = null;
      };

      this._emitVideoPlayFailure = () => {
        this.trigger('amrLogEvent', 'AMRTransitionVideo', 'Failure');
      };

      let _videoElementMovedWarning = false;
      // / Private API

      // The video element pauses itself when it's reparented, this is
      // unfortunate. This function plays the video again and is triggered
      // on the pause event.
      const _playVideoOnPause = () => {
        if (!_videoElementMovedWarning) {
          this.logging.warn('Video element paused, auto-resuming. If you intended to do this, ' +
            'use publishVideo(false) or subscribeToVideo(false) instead.');
          _videoElementMovedWarning = true;
        }
        this.play();
      };

      this._domElement = _createVideoElement(fallbackText, muted);

      this.trigger('videoElementCreated', this._domElement);

      this._domElementEvents = eventHelper(this._domElement);
      this._domElementEvents.on('loadstart', () => this._onDomElementLoadStart());
      this._domElementEvents.on('timeupdate', (...args) => this.trigger('timeupdate', ...args));
      this._domElementEvents.on('loadedmetadata', async (...args) => {
        this._isVideoLoadedMetaData = true;
        this.trigger('loadedmetadata', ...args);

        // We can safely remove the audio only element since the video element
        // could trigger the loadedmetada, meaning it can start playing video.
        // For more info, see OPENTOK-41739.
        if (this._domAudioOnlyVideoElement) {
          const hasSrcObject = this._domAudioOnlyVideoElement.srcObject;
          this._removeAudioOnlyVideoElement();
          // We need to call play only if _domAudioOnlyVideoElement had
          // a srcObject, meaning it was used for audio.
          if (hasSrcObject) {
            // In Safari, a delay is needed else element may fail to play.
            await noop();
            this.play();
          }
        }
      });

      const onError = (event) => {
        this.trigger('error', videoElementErrorMap(event.target.error));
      };

      this._domElementEvents.on('error', onError, false);
      this._domElementEvents.on('pause', _playVideoOnPause);

      this.on('destroyed', () => {
        this._domElementEvents.removeAll();

        if (this._domAudioOnlyVideoElementEvents) {
          this._domAudioOnlyVideoElementEvents.removeAll();
        }
      });

      canBeOrientatedMixin(this, () => this._domElement);

      // Safari subscribers needs special handling for audio-only video
      // https://bugs.webkit.org/show_bug.cgi?id=174152
      if (env.isSafari && this._widgetType === 'subscriber') {
        this._domAudioOnlyVideoElement = _createAudioOnlyVideoElement();
        this._bindAudioOnlyVideoElementEvents();
      }
    }

    async _onDomElementLoadStart() {
      if (this._widgetType === 'subscriber' && hasAudioOutputApiSupport()) {
        await this._setActiveAudioOutputDevice();
      }
    }

    async _setActiveAudioOutputDevice() {
      const deviceId = getCurrentAudioOutputDeviceId();
      if (deviceId === '' || deviceId === 'default') {
        // We can stop here since the default audio device will be used.
        return;
      }
      await this.setSinkId(deviceId);
    }

    _rebindSrcObject() {
      if (this._domElement) {
        this._domElement.srcObject = this._domElement.srcObject;
      }
    }

    _pauseAndPlay() {
      if (this._domElement) {
        this._domElement.pause();
        this._domElement.play();
      }
    }

    async _startAudioOnlyVideoElementWatcher() {
      // In Safari, after setting the srcObject the video element gets paused.
      // Calling play() is not sufficient to unpause it.
      // A workaround for this is to rebind when we detect that the video gets paused.
      // Notice that if it's iOS we need to call play() after the rebind, otherwise
      // it won't play.
      const watcherIntervalTimeout = 1000;
      const shouldPlay = env.isiOS;
      if (this._audioOnlyVideoElementWatcher) {
        clearInterval(this._audioOnlyVideoElementWatcher);
      }
      this._audioOnlyVideoElementWatcher = setInterval(() => {
        if (this._domAudioOnlyVideoElement && this._domAudioOnlyVideoElement.paused) {
          this._domAudioOnlyVideoElement.srcObject = this._domAudioOnlyVideoElement.srcObject;
          if (shouldPlay) {
            this._domAudioOnlyVideoElement.play();
          }
        }
      }, 100);
      await promiseDelay(watcherIntervalTimeout);
      clearInterval(this._audioOnlyVideoElementWatcher);
      this._audioOnlyVideoElementWatcher = null;
    }

    async _removeAudioOnlyVideoElement() {
      this._domAudioOnlyVideoElement.srcObject = null;
      this._domAudioOnlyVideoElement.parentNode.removeChild(this._domAudioOnlyVideoElement);
      if (this._domAudioOnlyVideoElementEvents) {
        this._domAudioOnlyVideoElementEvents.removeAll();
      }
      this._domAudioOnlyVideoElement = null;
    }

    async bindAudioTrackOnly() {
      const videoTracks = this._stream.getVideoTracks();
      const audioTracks = this._stream.getAudioTracks();
      const [videoTrack = {}] = videoTracks;
      const [audioTrack = {}] = audioTracks;

      await noop();
      const isAudioOnlyVideo = !videoTrack.enabled && audioTrack.enabled;

      if (!this._isVideoLoadedMetaData && isAudioOnlyVideo && this._domAudioOnlyVideoElement) {
        this._domAudioOnlyVideoElement.srcObject = new windowMock.MediaStream(audioTracks);
        this._startAudioOnlyVideoElementWatcher();
      }
    }

    _bindAudioOnlyVideoElementEvents() {
      this._domAudioOnlyVideoElementEvents = eventHelper(this._domAudioOnlyVideoElement);

      // Safari may stop playing audio if the browser is not visible or losses
      // focus.  When it does, continue playing it.
      this._domAudioOnlyVideoElementEvents.on('pause', () => this.play());
      this._domAudioOnlyVideoElementEvents.on('timeupdate', (...args) =>
        this.trigger('timeupdate', ...args));
      this._domAudioOnlyVideoElementEvents.on('loadedmetadata', (...args) =>
        this.trigger('loadedmetadata', ...args));
    }
    whenTimeIncrements(callback, context) {
      this.once('timeupdate', () => {
        callback.call(context, this);
      });
    }
    /**
     * Get the underlying DOM video element
     * @return {Element}
    */
    domElement() {
      return this._domElement;
    }
    videoWidth() {
      return this._domElement ?
        Number(this._domElement[`video${this.isRotated() ? 'Height' : 'Width'}`]) : 0;
    }
    videoHeight() {
      return this._domElement ?
        Number(this._domElement[`video${this.isRotated() ? 'Width' : 'Height'}`]) : 0;
    }
    aspectRatio() {
      return this.videoWidth() / this.videoHeight();
    }
    imgData() {
      const canvas = OTHelpers.createElement('canvas', {
        width: this.videoWidth(),
        height: this.videoHeight(),
        style: { display: 'none' },
      });
      document.body.appendChild(canvas);
      let imgData = null;
      try {
        canvas.getContext('2d').drawImage(this._domElement, 0, 0, canvas.width, canvas.height);
        imgData = canvas.toDataURL('image/png');
      } catch (err) {
        // Warning emitted for imgData === null below
      }
      OTHelpers.removeElement(canvas);
      if (imgData === null || imgData === 'data:,') {
        // 'data:,' is sometimes returned by canvas.toDataURL when one cannot be
        // generated.
        this.logging.warn('Cannot get image data yet');
        return null;
      }
      return imgData.replace('data:image/png;base64,', '').trim();
    }
    // Append the Video DOM element to a parent node
    appendTo(parentDomElement) {
      parentDomElement.appendChild(this._domElement);

      if (this._domAudioOnlyVideoElement) {
        parentDomElement.appendChild(this._domAudioOnlyVideoElement);
      }

      return this;
    }
    isAudioBlocked() {
      return this._blockedVolume !== undefined;
    }
    async unblockAudio() {
      if (!this.isAudioBlocked()) {
        this.logging.warn('Unexpected call to unblockAudio() without blocked audio');
        return;
      }

      const blockedVolume = this._blockedVolume;
      this._blockedVolume = undefined;

      this.setAudioVolume(blockedVolume);

      try {
        await this.play();
      } catch (err) {
        this._blockedVolume = blockedVolume;
        this._domElement.muted = true;

        throw err;
      }

      this.trigger('audioUnblocked');
    }
    // useful for some browsers (old chrome) that show black when a peer connection
    // is renegotiated
    async rebind() {
      if (!this._domElement) {
        throw new Error('Can\'t rebind because _domElement no longer exists');
      }
      this._playInterrupts++;
      this._domElement.srcObject = this._domElement.srcObject;

      if (this._hasAudioOnlyVideoElement()) {
        this._domAudioOnlyVideoElement.srcObject = this._domAudioOnlyVideoElement.srcObject;
      }
    }
    _createAudioLevelSampler() {
      this._removeAudioLevelSampler();
      if (this._stream.getAudioTracks().length > 0) {
        try {
          this._audioContext = audioContextProvider();
        } catch (e) {
          this.logging.warn('Failed to get AudioContext(), audio level visualisation will not work', e);
        }
        if (this._audioContext) {
          this._audioLevelSampler = new WebAudioLevelSampler(this._audioContext);
          this._audioLevelSampler.webRTCStream(this._stream);
        }
      }
    }
    _removeAudioLevelSampler() {
      if (this._audioContext) {
        delete this._audioContext;
        delete this._audioLevelSampler;
      }
    }
    // For MediaStreams with multiple tracks, video tracks are disabled and
    // then re-enabled
    async _toggleVideoTracks() {
      const enabledTracks = this._domElement.srcObject.getTracks().filter(track => track.enabled);
      const hasMultipleTracks = enabledTracks.length > 1;

      if (hasMultipleTracks) {
        const videoTracks = enabledTracks.filter(track => track.kind === 'video');

        videoTracks.forEach((track) => {
          track.enabled = false;
        });

        await noop();

        videoTracks.forEach((track) => {
          track.enabled = true;
        });
      }
    }
    _hasAudioOnlyVideoElement() {
      return this._domAudioOnlyVideoElement && this._domAudioOnlyVideoElement.srcObject;
    }
    async play() {
      const playInterruptsOnStart = this._playInterrupts;
      // Which element do we try to play?  The `audio` or `video` element?
      const domElement = this._hasAudioOnlyVideoElement() ?
        this._domAudioOnlyVideoElement : this._domElement;

      // In Safari, if a HTMLMediaElement property is mutated or if the element is moved,
      // a delay is needed else element may fail to play
      if (env.isSafari) {
        await noop();
      }

      if (env.isiOS && env.isSafari) {
        if (this._widgetType === 'publisher') {
          const isBuggediOS = env.iOSVersion >= 14 && env.iOSVersion <= 14.2;
          const [videoTrack = {}] = this._stream.getVideoTracks();
          const [audioTrack = {}] = this._stream.getAudioTracks();

          /**
           * OPENTOK-41742: Prevent to call play() on a hidden video element. Otherwise the
           * readyState of the MediaStreamTrack will be ended and the publisher will stop
           * publishing video.
           * This issue happens only on iOS Safari >= 14.0 and <= 14.2
           * */
          if (isBuggediOS && !isDomElementVisible(domElement) && videoTrack.enabled) {
            return;
          }

          // OPENTOK-42233: Prevent calling play if the audio track is muted.
          if (audioTrack.muted) {
            return;
          }
        }

        // OPENTOK-39347: Toggle video tracks, else audio maybe garbled
        this._toggleVideoTracks();
      }

      try {
        // We want to measure the duration of the blink: OPENTOK-46931
        this._emitVideoPlayAttempt();
        await domElement.play();
        this._emitVideoPlaySuccess();
        return;
      } catch (err) {
        this._emitVideoPlayFailure();
        const { name: errorName } = err || {};
        const isAbortError = errorName === 'AbortError';

        // Play request was interrupted
        if (isAbortError) {
          this._playInterrupts++;
        }

        if (this._playInterrupts > playInterruptsOnStart) {
          // The play request might've been interrupted by a new load request.
          // Ignore error and try again.
          // https://goo.gl/LdLk22
          await this.play();
          return;
        }

        throw err;
      }
    }
    _bindToStream(webRtcStream) {
      this._stream = webRtcStream;
      this._domElement.srcObject = webRtcStream;

      // Audio-only video won't play in Safari since the browser won't dispatch
      // the `loadedmetadata` event (since the video has unknown dimensions)
      // https://bugs.webkit.org/show_bug.cgi?id=174152
      if (this._domAudioOnlyVideoElement) {
        this.bindAudioTrackOnly();
      }
    }
    async bindToStream(webRtcStream) {
      if (!this._domElement) {
        throw new Error('Can\'t bind because _domElement no longer exists');
      }

      this._bindToStream(webRtcStream);

      (async () => {
        try {
          await this.play();
        } catch (err) {
          const hasAudio = !!webRtcStream.getAudioTracks().length;

          if (this._hasAudioOnlyVideoElement() && err.name === 'NotAllowedError') {
            // Audio-only video failed to play.  Mute audio and try again.
            this._domAudioOnlyVideoElement.muted = true;
            await this.play();

            // Now that audio-only stream is playing, unmute it.
            this._domAudioOnlyVideoElement.muted = false;
          } else if (hasAudio) {
            // Media (with audio) failed to play.  Mute audio and try again.
            this._blockedVolume = this.getAudioVolume();
            this._domElement.muted = true;

            await this.play();
            this.trigger('audioBlocked');
          } else {
            // Media failed to play.  Mute audio, even though there's no audio
            // track (e.g., a screenshare), and try again.
            this._domElement.muted = true;

            await this.play();
          }
        }
      })().catch((err) => {
        this.logging.debug('.play() failed: ', err);
      });

      const currentVideoSize = {
        width: this._domElement.videoWidth,
        height: this._domElement.videoHeight,
      };
      this.trigger('videoDimensionsChanged', { ...currentVideoSize }, { ...currentVideoSize });
      this._domElementEvents.on('resize', () => {
        const { videoWidth: width, videoHeight: height } = this._domElement;
        const widthChanged = width !== currentVideoSize.width;
        const heightChanged = height !== currentVideoSize.height;
        if (widthChanged || heightChanged) {
          this.trigger('videoDimensionsChanged', { ...currentVideoSize }, { width, height });
          currentVideoSize.width = width;
          currentVideoSize.height = height;
        }
      });
      const onAllEnded = () => {
        try {
          // This callback may outlive the reference to this object.  If so,
          // then an exception will be thrown.
          this._mediaStoppedListener.stop();
        } catch (err) {
          // Bail, since this object was likely destroyed already and executing
          // the rest of the code will generate exceptions as well.
          return;
        }
        if (this._domElement) {
          this._domElement.onended = null;
        }
        this.trigger('mediaStopped');
      };
      const onSingleEnded = (track) => {
        this.trigger('mediaStopped', track);
      };

      this._domElement.onended = () => onAllEnded();
      this._mediaStoppedListener = listenForTracksEnded(webRtcStream, onAllEnded, onSingleEnded);
      this._createAudioLevelSampler();
      return undefined;
    }
    // Unbind the currently bound stream from the video element.
    unbindStream() {
      if (this._domElement) {
        this._domElement.srcObject = null;
      }

      if (this._domAudioOnlyVideoElement) {
        this._domAudioOnlyVideoElement.srcObject = null;
      }

      this._removeAudioLevelSampler();

      return this;
    }
    setAudioVolume(rawValue) {
      if (this.isAudioBlocked()) {
        this._blockedVolume = rawValue;
        return;
      }

      const value = parseFloat(rawValue) / 100;

      const domElements = [this.domElement()];
      if (this._domAudioOnlyVideoElement) {
        domElements.push(this._domAudioOnlyVideoElement);
      }

      domElements.forEach((domElement) => {
        if (domElement) {
          domElement.volume = value;
          // In Safari on iOS setting the volume does not work but setting muted does so at
          // least this will mute when you click the mute icon
          // https://bugs.webkit.org/show_bug.cgi?id=176045
          domElement.muted = value === 0;
        }
      });
    }
    getAudioVolume() {
      if (this.isAudioBlocked()) {
        return this._blockedVolume;
      }

      // Return the actual volume of the DOM element
      const domElement = this.domElement();

      if (domElement) {
        return domElement.muted ? 0 : domElement.volume * 100;
      }
      return this._defaultAudioVolume;
    }
    // see https://wiki.mozilla.org/WebAPI/AudioChannels
    // The audioChannelType is currently only available in Firefox. This property returns
    // "unknown" in other browser. The related HTML tag attribute is "mozaudiochannel"
    audioChannelType(type) {
      const domElement = this.domElement();

      if (type !== void 0) {
        domElement.mozAudioChannelType = type;
      }
      if ('mozAudioChannelType' in this._domElement) {
        return domElement.mozAudioChannelType;
      }
      return 'unknown';
    }
    getAudioInputLevel() {
      return this._audioLevelSampler.sample();
    }
    refreshTracks() {
      if (this._mediaStoppedListener) {
        this._mediaStoppedListener.refresh();
      }
      this._createAudioLevelSampler();
    }
    destroy() {
      // Unbind events first, otherwise 'pause' will trigger when the
      // video element is removed from the DOM.
      if (this._mediaStoppedListener) {
        this._mediaStoppedListener.stop();
      }
      this.logging.debug('removing domElementEvents');
      this._domElementEvents.removeAll();

      if (this._domAudioOnlyVideoElementEvents) {
        this._domAudioOnlyVideoElementEvents.removeAll();
      }

      if (this._audioOnlyVideoElementWatcher) {
        clearInterval(this._audioOnlyVideoElementWatcher);
      }

      this.unbindStream();

      if (this._domElement) {
        OTHelpers.removeElement(this._domElement);
        this._domElement = null;
      }

      if (this._domAudioOnlyVideoElement) {
        OTHelpers.removeElement(this._domAudioOnlyVideoElement);
        this._domAudioOnlyVideoElement = null;
      }

      this.trigger('destroyed');
      destroyObj('NativeVideoElementWrapper', this);
      return void 0;
    }
    setSinkId(deviceId) {
      const videoElements = [this._domElement, this._domAudioOnlyVideoElement];
      const setSinkIdPromises = videoElements.map((videoElement) => {
        if (!videoElement) {
          return undefined;
        }
        return videoElement.setSinkId(deviceId);
      });
      return Promise.all(setSinkIdPromises);
    }
  }
  return NativeVideoElementWrapper;
}

export default NativeVideoElementWrapperFactory;

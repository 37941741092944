export default function destroyObj(name, obj) {
  const throwErr = (action, key) => () => {
    throw new Error(`Cannot ${action} ${key}, ${name} is destroyed`);
  };

  Object.keys(obj)
    .forEach((key) => {
      if (typeof obj[key] === 'function') {
        // eslint-disable-next-line no-param-reassign
        obj[key] = throwErr('call', key);
      } else {
        Object.defineProperty(obj, key, {
          get: throwErr('get', key),
          set: throwErr('set', key),
        });
      }
    });
}

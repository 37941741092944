import promiseDelay from '../helpers/promiseDelay';

export default async (webSocket, {
  BUFFER_DRAIN_INTERVAL = 100,
  BUFFER_DRAIN_MAX_RETRIES = 10,
} = {}) => {
  for (let attemptNo = 0; attemptNo < BUFFER_DRAIN_MAX_RETRIES; attemptNo += 1) {
    if (webSocket.bufferedAmount === 0) break;
    await promiseDelay(BUFFER_DRAIN_INTERVAL); // eslint-disable-line no-await-in-loop
  }
};

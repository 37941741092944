export default function noConflict() {
  const globalOT = global.OT;
  const globalTB = global.TB;
  return () => {
    const OT = global.OT;
    global.OT = globalOT;
    global.TB = globalTB;
    return OT;
  };
}

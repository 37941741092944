import deviceHelpers from '../helpers/deviceHelpers';

const { getInputMediaDevices } = deviceHelpers();

/**
 * Enumerates the audio input devices (such as microphones) and video input devices
 * (cameras) available to the browser.
 * <p>
 * The array of devices is passed in as the <code>devices</code> parameter of
 * the <code>callback</code> function passed into the method.
 *
 * @param callback {Function} The callback function invoked when the list of devices
 * devices is available. This function takes two parameters:
 * <ul>
 *   <li><code>error</code> &mdash; This is set to an error object when
 *   there is an error in calling this method; it is set to <code>null</code>
 *   when the call succeeds.</li>
 *
 *   <li><p><code>devices</code> &mdash; An array of objects corresponding to
 *   available microphones and cameras. Each object has three properties: <code>kind</code>,
 *   <code>deviceId</code>, and <code>label</code>, each of which are strings.
 *   <p>
 *   The <code>kind</code> property is set to <code>"audioInput"</code> for audio input
 *   devices or <code>"videoInput"</code> for video input devices.
 *   <p>
 *   The <code>deviceId</code> property is a unique ID for the device. You can pass
 *   the <code>deviceId</code> in as the <code>audioSource</code> or <code>videoSource</code>
 *   property of the <code>options</code> parameter of the
 *   <a href="#initPublisher">OT.initPublisher()</a> method.
 *   <p>
 *   The <code>label</code> property identifies the device. The <code>label</code>
 *   property is set to an empty string if the user has not previously granted access to
 *   a camera and microphone.</li>
 * </ul>
 * <i>Note:</i> The browser may limit the number of returned audio and video input devices
 * to one each before the user has granted access to a camera or microphone.
 *
 * @see <a href="#initPublisher">OT.initPublisher()</a>
 * @method OT.getDevices
 * @memberof OT
 */
export default function getDevices(callback) {
  getInputMediaDevices().then(
    devices => callback(undefined, devices),
    callback
  );
}

// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable global-require, no-param-reassign */
import OTHelpersDefault from '../../common-js-helpers/OTHelpers';

export default function setCertificatesFactory(deps = {}) {
  const OTHelpers = deps.OTHelpers || OTHelpersDefault;
  const windowMock = deps.global || global;

  const { RTCPeerConnection } = windowMock;

  return function setCertificates(pcConfig, completion) {
    if (
      OTHelpers.env.name === 'Firefox' &&
      RTCPeerConnection &&
      RTCPeerConnection.generateCertificate
    ) {
      RTCPeerConnection.generateCertificate({
        name: 'RSASSA-PKCS1-v1_5',
        hash: 'SHA-256',
        modulusLength: 2048,
        publicExponent: new Uint8Array([1, 0, 1]),
      }).catch((err) => {
        completion(err);
      }).then((cert) => {
        pcConfig.certificates = [cert];
        completion(undefined, pcConfig);
      });
    } else {
      setTimeout(() => {
        completion(undefined, pcConfig);
      });
    }
  };
}

import DefaultParameters from './defaultParameters';
import { CRITICAL, MEDIUM, LOW } from '../congestionLevels';

const audioPacketLossFactor = 0.8;
const mediumFactor = 0.5;

const createBandwidthCongestionLevel = (customParams = {}) => {
  const params = {
    ...DefaultParameters,
    ...customParams,
  };

  const videoPacketLossCriticalThreshold = params.packetLossCriticalThreshold;
  const videoPacketLossMediumThreshold = videoPacketLossCriticalThreshold * mediumFactor;

  const audioPacketLossCriticalThreshold = videoPacketLossCriticalThreshold * audioPacketLossFactor;
  const audioPacketLossMediumThreshold = audioPacketLossCriticalThreshold * mediumFactor;

  // adapted from https://github.com/opentok/mantis/blob/development/src/registry/CongestionLevelPolicy.cpp#L54
  return {
    getLevel({ audioPacketLoss, videoPacketLoss }) {
      if (
        audioPacketLoss > audioPacketLossCriticalThreshold
        || videoPacketLoss > videoPacketLossCriticalThreshold
      ) {
        return CRITICAL;
      } else if (
        audioPacketLoss > audioPacketLossMediumThreshold
        || videoPacketLoss > videoPacketLossMediumThreshold
      ) {
        return MEDIUM;
      }
      return LOW;
    },
  };
};

export default createBandwidthCongestionLevel;

import generateSimpleStateMachineFactory from '../generate_simple_state_machine';

const generateSimpleStateMachine = generateSimpleStateMachineFactory();

// Models a Subscriber's subscribing State
//
// Valid States:
//     NotSubscribing            (the initial state
//     Init                      (basic setup of DOM
//     Connecting                (Failure Cases -> No Route, Bad Offer, Bad Answer
//     BindingRemoteStream       (Failure Cases -> Anything to do with the media being
//                               (invalid, the media never plays
//     Subscribing               (this is 'onLoad'
//     Failed                    (terminal state, with a reason that maps to one of the
//                               (failure cases above
//     Destroyed                 (The subscriber has been cleaned up, terminal state
//
//
// Valid Transitions:
//     NotSubscribing ->
//         Init
//
//     Init ->
//             Connecting
//           | BindingRemoteStream         (if we are subscribing to ourselves and we alreay
//                                         (have a stream
//           | NotSubscribing              (destroy()
//
//     Connecting ->
//             BindingRemoteStream
//           | NotSubscribing
//           | Failed
//           | NotSubscribing              (destroy()
//
//     BindingRemoteStream ->
//             Subscribing
//           | Failed
//           | NotSubscribing              (destroy()
//
//     Subscribing ->
//             NotSubscribing              (unsubscribe
//           | Failed                      (probably a peer connection failure after we began
//                                         (subscribing
//
//     Failed ->
//             Destroyed
//
//     Destroyed ->                        (terminal state)
//
//
// @example
//     var state = new SubscribingState(function(change) {
//       console.log(change.message);
//     });
//
//     state.set('Init');
//     state.current;                 -> 'Init'
//
//     state.set('Subscribing');      -> triggers stateChangeFailed and logs out the error message
//
//
const initialState = 'NotSubscribing';

const validStates = [
  'NotSubscribing', 'Init', 'Connecting',
  'BindingRemoteStream', 'Subscribing', 'Failed',
  'Destroyed',
];

const validTransitions = {
  NotSubscribing: ['NotSubscribing', 'Init', 'Destroyed'],
  Init: ['NotSubscribing', 'Connecting', 'BindingRemoteStream', 'Destroyed'],
  Connecting: ['NotSubscribing', 'BindingRemoteStream', 'Failed', 'Destroyed'],
  BindingRemoteStream: ['NotSubscribing', 'Subscribing', 'Failed', 'Destroyed',
    'BindingRemoteStream'],
  Subscribing: ['NotSubscribing', 'Failed', 'Destroyed', 'BindingRemoteStream'],
  Failed: ['Destroyed'],
  Destroyed: [],
};

const SubscribingState = generateSimpleStateMachine(initialState, validStates, validTransitions);

SubscribingState.prototype.isDestroyed = function () {
  return this.current === 'Destroyed';
};

SubscribingState.prototype.isFailed = function () {
  return this.current === 'Failed';
};

SubscribingState.prototype.isSubscribing = function () {
  return this.current === 'Subscribing';
};

SubscribingState.prototype.isAttemptingToSubscribe = function () {
  return [
    'Init',
    'Connecting',
    'BindingRemoteStream',
  ].indexOf(this.current) !== -1;
};

SubscribingState.prototype.isStreamAdded = function () {
  return this.current === 'BindingRemoteStream' || this.isSubscribing();
};

export default SubscribingState;

import assign from 'lodash/assign';
import find from 'lodash/find';
import pick from 'lodash/pick';
import getStatsHelpers from './get_stats_helpers';
import calculateFrameRate from './calculate-frame-rate';

const watchFrameRate = (getStats, delay = 5000) => {
  let timeoutId;
  let destroyed = false;
  const lastStat = {};

  const getVideoInboundStat = (stats = []) => (
    find(stats, stat => getStatsHelpers.isVideoInboundRtpStat(stat))
  );

  const query = () => {
    if (destroyed) {
      return;
    }
    getStats((error, stats) => {
      if (destroyed) {
        return;
      }

      if (!error) {
        const stat = getVideoInboundStat(stats);
        if (stat) {
          assign(lastStat, pick(stat, ['timestamp', 'framesDecoded']));
        } else if (!lastStat.timestamp) {
          assign(lastStat, {
            timestamp: Date.now(),
            framesDecoded: 0,
          });
        }
      }

      timeoutId = setTimeout(query, delay);
    });
  };

  query();

  return {
    getFrameRateFromStat(stat) {
      return calculateFrameRate({
        currentStatFrames: stat.framesDecoded,
        currentTimestamp: stat.timestamp,
        prevStatFrames: lastStat.framesDecoded,
        prevTimestamp: lastStat.timestamp,
      });
    },
    destroy() {
      destroyed = true;
      if (timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = null;
      }
    },
  };
};

export default watchFrameRate;

import eventNames from './eventNames';
import ExceptionCodes from '../ot/exception_codes';
import EventsFactory from '../ot/events';

const Events = EventsFactory();

export default device => new Events.ExceptionEvent(
  eventNames.ACCESS_DENIED,
  `${device} permission denied during the call`,
  'accessDenied',
  ExceptionCodes.UNABLE_TO_PUBLISH
);

import assign from 'lodash/assign';
import intersection from 'lodash/intersection';
import createLogger from '../helpers/log';

const logging = createLogger('Event');

export default class Event {
  constructor(type, cancelable = true, props = {}) {
    this.type = type;
    this.cancelable = cancelable;
    this._defaultPrevented = false;

    const reservedKeys = intersection(Object.keys(this), Object.keys(props));

    if (reservedKeys.length > 0) {
      throw new Error(`Cannot used reserved property names: ${reservedKeys.join(',')}`);
    }

    assign(this, props);
  }
  preventDefault() {
    if (this.cancelable) {
      this._defaultPrevented = true;
    } else {
      logging.warn('Event.preventDefault :: Trying to preventDefault on an ' +
        'event that isn\'t cancelable');
    }
  }
  isDefaultPrevented() {
    return this._defaultPrevented;
  }
}

const AMRStateDurations = {
  // Publisher keeps sending media for 5 secs
  KEEP_SENDING_MEDIA_AFTER_TRANSITIONED: 5 * 1000,
  // Subscriber has 5 secs to load the video before Publisher stops sending
  VIDEO_LOADING_TIMEOUT: 5 * 1000,
  // 5 secs to load the video + 1 sec tolerance to establish the PeerConnection
  TRANSITION_TIMEOUT: (5 + 1) * 1000,
  // Send media for 3 secs to keep alive
  KEEP_SENDING_MEDIA_TO_KEEP_ALIVE: 3 * 1000,
  // Send media every 30 secs to keep alive
  KEEP_SENDING_RTCP_DELAY: 30 * 1000,
  // Worst cases observed buffer took a max of 1.x secs to load
  VIDEO_BUFFER_TIMEOUT: 2 * 1000,
};

export default AMRStateDurations;

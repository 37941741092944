// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-param-reassign */

// TODO - Move this back within the factory once all modules have been
// converted to DI factories. This will ensure different unit tests are
// using distinct instances of AudioContext.
let context;

export default function audioContextFactory(deps = {}) {
  const AudioContext = deps.AudioContext || global.AudioContext || global.webkitAudioContext;

  /*
   * Lazy instantiates an audio context and always return the same instance on following calls
   *
   * @returns {AudioContext}
   */

  return function audioContext() {
    context = context || (AudioContext && new AudioContext());
    // Safari does not throw, it simply returns null when it cannot create an
    // audio context...
    // @todo report this to Apple
    if (context == null) {
      throw new Error('AudioContext could not be created, hardware limit reached.');
    }
    return context;
  };
}

// The top-level namespace, also performs basic DOMElement selecting.
//
// @example Get the DOM element with the id of 'domId'
//   OTHelpers('#domId')
//
// @example Get all video elements
//   OTHelpers('video')
//
// @example Get all elements with the class name of 'foo'
//   OTHelpers('.foo')
//
// @example Get all elements with the class name of 'foo',
// and do something with the first.
//   var collection = OTHelpers('.foo');
//   console.log(collection.first);
//
//
// The second argument is the context, that is document or parent Element, to
// select from.
//
// @example Get a video element within the element with the id of 'domId'
//   OTHelpers('video', OTHelpers('#domId'))
//
//
//
// OTHelpers will accept any of the following and return a collection:
//   OTHelpers()
//   OTHelpers('css selector', optionalParentNode)
//   OTHelpers(DomNode)
//   OTHelpers([array of DomNode])
//
// The collection is a ElementCollection object, see the ElementCollection docs for usage info.
//
import defaults from 'lodash/defaults';
import OTHelpers from './elementCollection/shorthandSelector';
import eventing from '../helpers/eventing';
import log from '../helpers/log';
import env from '../helpers/env';
import setDeprecatedProperty from '../helpers/setDeprecatedProperty';
import ElementCollection from './elementCollection/index';
import ajax from './ajax';
import statable from './behaviours/statable';
import Analytics from '../helpers/analytics/analytics';
import Collection from './collection';
import domExtras from './domExtras';
import Error from './error';

OTHelpers.logging = log('OT.$');
OTHelpers.env = env;
OTHelpers.ElementCollection = ElementCollection;
OTHelpers.ajax = ajax;
OTHelpers.statable = statable;
OTHelpers.Analytics = Analytics;
OTHelpers.Collection = Collection;
OTHelpers.domExtras = domExtras;
OTHelpers.Error = Error;

setDeprecatedProperty(
  OTHelpers,
  'useLogHelpers',
  {
    value(mixin) {
      const logging = log('useLogHelpersDeprecatedMixin');
      // eslint-disable-next-line no-param-reassign
      Object.keys(logging).forEach((key) => { mixin[key] = logging[key]; });
      return mixin;
    },
    name: 'OT.useLogHelpers',
    getWarning: 'Please use an external library for your debugging needs',
  }
);

OTHelpers.defaults = defaults;

setDeprecatedProperty(
  OTHelpers,
  'eventing',
  {
    value: eventing,
    name: 'OT.$.eventing',
    getWarning: 'Please use an external library to create an event emitter.',
  }
);

// TODO: Remove the need for this kind of bazzadry.
Object.keys(OTHelpers).forEach((key) => {
  const component = OTHelpers[key];
  const attachments = component._attachToOTHelpers || {};

  Object.keys(attachments).forEach((attachmentName) => {
    if (
      Object.hasOwnProperty.call(OTHelpers, attachmentName) &&
      !(key === 'util' && attachmentName === 'bind')
    ) {
      // eslint-disable-next-line no-console
      console.warn(
        `More than one module is trying to define ${attachmentName} on OTHelpers.`
      );
    }
    OTHelpers[attachmentName] = attachments[attachmentName];
  });
});

export default OTHelpers;

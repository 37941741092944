export default class TruthyChangeCounter {
  value = null;
  changeCount = 0;

  onValue(newValue) {
    if (!newValue) {
      return;
    }

    if (newValue !== this.value) {
      if (this.value) {
        this.changeCount += 1;
      }

      this.value = newValue;
    }
  }
}

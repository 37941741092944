import memoizeDefault from 'lodash/memoize';
import generateKeyDefault from '../sframe/generateKey';
import arrayBufferToHexDefault from './arrayBufferToHex';

const createExtractSenderId = ({
  memoize = memoizeDefault,
  generateKey = generateKeyDefault,
  arrayBufferToHex = arrayBufferToHexDefault,
} = {}) => {
  const extractSenderId = memoize(async ({ sessionId, connectionId }) => {
    // Note: this same algorithm is being use by the native SDKs.  (We have a unit
    // test to confirm correctness as well).
    const key = await generateKey(sessionId, connectionId);

    const exportedKey = await window.crypto.subtle.exportKey('raw', key);
    const trimmedKeyBuffer = [...new Uint8Array(exportedKey)];

    // We trim to workaround javascript's issues with large integers
    trimmedKeyBuffer.splice(6);
    const keyAsHex = arrayBufferToHex(trimmedKeyBuffer);

    return parseInt(keyAsHex, 16);
  },
  // Memoize cache resolver function
  ({ sessionId, connectionId }) => `${sessionId}${connectionId}`);
  return extractSenderId;
};

export default createExtractSenderId;

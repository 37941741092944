import hasOpenTokSupportHelpers from '../helpers/hasOpenTokSupport';

const hasOpenTokSupport = hasOpenTokSupportHelpers.once;

// Indicates if the browser supports RTCP Mux
//
// Broadly:
// * All recent versions of WebRTC (both Chrome and Firefox) support RtcpMux

export default () => hasOpenTokSupport();

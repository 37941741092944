import { setVonageMetadata } from '@vonage/media-processor';
import { createVonageMediaProcessor } from '@vonage/ml-transformers';

// This is copy pasta from @vonage/media-processor, basically.  The only
// difference is their's is an async call, whereas this one is synchronous.
const isSupported = () =>
  typeof MediaStreamTrackProcessor !== 'undefined' && typeof MediaStreamTrackGenerator !== 'undefined';

// Vonage's low-level machine-learning-based media processor libraries
const ML = {
  isSupported,
  setMetadata: setVonageMetadata,
  createProcessor: createVonageMediaProcessor,
};

export default ML;

import prependProxyToUrlIfNeeded from '../../../helpers/proxyUrlHelper';
import envDefault from '../../../helpers/env';

// Returns URL of CDN on which ML assets are hosted
const getCdnUrl = (proxyUrl, library, env) => {
  let host;
  const mlPath = 'ml-transformers/v4.0.0';
  const nsPath = 'noise-suppression/v1.0.0';
  const path = library === 'noise-suppression' ? nsPath : mlPath;
  if (env.isProd) {
    host = 'https://static.opentok.com';
  } else if (env.isRel) {
    host = 'https://static.rel.tokbox.com';
  } else {
    host = 'https://www.dev.tokbox.com';
  }

  if (proxyUrl) {
    if (proxyUrl.includes('euproxy.opentok.com')) {
      host = 'https://static-eu.opentok.com';
    } else {
      host = prependProxyToUrlIfNeeded(host, proxyUrl);
    }
  }

  return `${host}/${path}`;
};

// Default configuration that may be shared by all ML transforms
const DefaultConfig = {
  getConfig: (deps = {}) => {
    const {
      proxyUrl,
      library,
      env = envDefault,
    } = deps;

    const cdnUrl = getCdnUrl(proxyUrl, library, env);

    return {
      mediapipeBaseAssetsUri: `${cdnUrl}`,
      wasmAssetUriPath: `${cdnUrl}/`,
      tfliteAssetUriPath: `${cdnUrl}/`,
      assetsDirBaseUrl: `${cdnUrl}`,
    };
  },
};

export default DefaultConfig;

// Values taken from https://github.com/opentok/chef-rumor/blob/master/attributes/default.rb#L65
const maxBitratePerResolution = [
  { width: 320, height: 180, maxBitrate: 300000 },
  { width: 320, height: 240, maxBitrate: 400000 },
  { width: 352, height: 288, maxBitrate: 500000 },
  { width: 480, height: 270, maxBitrate: 550000 },
  { width: 640, height: 360, maxBitrate: 800000 },
  { width: 640, height: 480, maxBitrate: 800000 },
  { width: 960, height: 540, maxBitrate: 1100000 },
  { width: 1280, height: 720, maxBitrate: 2500000 },
  { width: 1920, height: 1080, maxBitrate: 4500000 },
].sort((a, b) => a.maxBitrate - b.maxBitrate); // ensure sorted by desc bitrate

// Use maxBitrate for 1920x1080 as default if lookup fails.
const defaultMaxBitrate = 4500000;

const getMaxBitrateForResolution = (videoWidth, videoHeight) => {
  if (!videoWidth || !videoHeight) return defaultMaxBitrate;

  // Step function returns upper limit max bitrate for resolution area
  // using lookup table as input data.
  const bitrate = maxBitratePerResolution.find(({ height, width }) =>
    (videoHeight * videoWidth <= height * width))?.maxBitrate;

  return bitrate || defaultMaxBitrate;
};

export default getMaxBitrateForResolution;

import DefaultConfig from './defaultConfig';

const getDefaultConfig = opt => ({
  ...DefaultConfig.getConfig(opt),
  transformerType: 'VirtualBackground',
});

const isSupportedImage = url =>
  // Check if image is a BLOB
  /^data:image\/(?:png|jpeg|bmp|gif);base64/.test(url) ||
  // Check if URL is a supported image type
  /\.(?:png|jpe?g|bmp|gif)$/i.test(url);

const isValidConfig = (config) => {
  if (config?.type !== 'backgroundReplacement') {
    return false;
  }

  const { backgroundImgUrl } = config;

  return isSupportedImage(backgroundImgUrl);
};

const BackgroundReplacementConfig = {
  isValidConfig,
  getConfig: (opt = {}) => {
    const config = getDefaultConfig(opt);
    const { backgroundImgUrl } = opt;

    if (isSupportedImage(backgroundImgUrl)) {
      config.backgroundAssetUri = backgroundImgUrl;
    }

    return config;
  },
};

export default BackgroundReplacementConfig;

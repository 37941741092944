import urlUtil from 'url';

const stripTrailingSlash = url => url.replace(/\/$/, '');
const prependProxyToUrlIfNeeded = (url, proxyUrl) => {
  if (!proxyUrl) return url;

  const proxyUrlParsed = urlUtil.parse(proxyUrl);
  const urlParsed = urlUtil.parse(url);

  if (!proxyUrlParsed.protocol || !urlParsed.protocol) return url;

  const isSsl = proxyUrlParsed.protocol.match(/^https/);
  const isWs = urlParsed.protocol.match(/^ws/i);

  const protocol = (isWs ? 'ws' : 'http') + (isSsl ? 's' : '');

  const targetUrl = stripTrailingSlash(`${protocol}://${proxyUrlParsed.host}${proxyUrlParsed.pathname}`);
  const targetPath = stripTrailingSlash(`${urlParsed.host}${urlParsed.pathname}${urlParsed.search || ''}`);

  return `${targetUrl}/${targetPath}`;
};

export default prependProxyToUrlIfNeeded;

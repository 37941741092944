import Errors from '../Errors';

const { INVALID_PARAMETER } = Errors;

const validateIceConfig = ({ otError }) => (config) => {
  if (!config) {
    return;
  }

  const check = (cond, msg) => {
    if (!cond) {
      throw otError(INVALID_PARAMETER, new Error(`iceConfig invalid: ${msg}`));
    }
  };

  if ('includeServers' in config) {
    check(
      ['all', 'custom'].indexOf(config.includeServers) !== -1,
      '.includeServers must be all or custom'
    );
  }

  if ('transportPolicy' in config) {
    check(
      ['all', 'relay'].indexOf(config.transportPolicy) !== -1,
      '.transportPolicy must be all or relay'
    );
  }

  check(Array.isArray(config.customServers), '.customServers must be an array');

  config.customServers.forEach((server) => {
    check(server.urls || !('url' in server), 'ice servers need .urls, not .url');

    check(
      typeof server.urls === 'string' || Array.isArray(server.urls),
      'ice server .urls must be a string or an array'
    );

    check(
      !server.username || typeof server.username === 'string',
      'ice server .username must be a string if present'
    );

    check(
      !server.credential || typeof server.credential === 'string',
      'ice server .credential must be a string if present'
    );
  });
};

export default validateIceConfig;

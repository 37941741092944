// Converts an ArrayBuffer to a hexadecimal string
// For example: `[0xa, 0xbe, 0xef]` -> `0x0abeef`
export default (arrayBuffer) => {
  if (!Array.isArray(arrayBuffer)) {
    return null;
  }

  // Converts `[0xa, 0xbe, 0xef]` -> `0abeef`
  // (Notice that the `0x` prefix is missing)
  const hex = arrayBuffer.map(x => x.toString(16).padStart(2, '0')).join('');

  return `0x${hex}`;
};

/* eslint-disable global-require */
import lodashOnce from 'lodash/once';
import envDefault from '../helpers/env';

// Indicates whether this client needs its H264 profiles to be swapped
// Currently this is just Chrome on Android (OPENTOK-34457)
// See: https://bugs.chromium.org/p/webrtc/issues/detail?id=8584
const check = (deps = {}) => {
  const env = deps.env || envDefault;
  return (
    env.name === 'Chrome' &&
    env.userAgent.toLowerCase().indexOf('android') > -1 &&
    env.version < 65
  );
};

const once = lodashOnce(() => check());

export default {
  once,
  check,
};

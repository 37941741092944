import hasEndToEndEncryptionSupport from '../helpers/hasE2eeCapability';

/**
 * Whether the client supports end-to-end encryption.
 *
 * <p>
 * The following example enables the end-to-end encryption, if supported
 * by the client:
 * <pre>
 * // Publish in a session with end-to-end encryption, if supported
 * if (OT.hasEndToEndEncryptionSupport()) {
 *   session.publish(publisher, error) = {
 *     ...
 *   });
 * }
 * </pre>
 *
 * @return {Boolean} Whether the client supports end-to-end encryption (<code>true</code>),
 *  or not (<code>false</code>).
 *
 * @method OT.hasEndToEndEncryptionSupport
 * @memberof OT
 *
 * @see <a href="#initSession">OT.initSession()</a>
 * @see <a href="Session.html#publish">Session.publish()</a>
 */
export default hasEndToEndEncryptionSupport;

/* eslint-disable global-require */
import createLogger from '../helpers/log';
import currentAudioOutputDevice from './currentAudioOutputDevice';
import deviceHelpers from '../helpers/deviceHelpers';
import updateSubscribersSinkIdDefault from './update-subscribers-sinkid';

const audioOutputDevicesChangeHandlerFactory = (deps = {}) => {
  const {
    getAudioOutputMediaDevices,
    getDefaultAudioOutputDeviceId,
    hasDevice,
  } = deps.deviceHelpers || deviceHelpers();
  const {
    getCurrentAudioOutputDeviceId,
    setCurrentAudioOutputDeviceId,
  } = deps.currentAudioOutputDevice || currentAudioOutputDevice;
  const updateSubscribersSinkId = deps.updateSubscribersSinkId
    || updateSubscribersSinkIdDefault;
  const logging = deps.logging || createLogger('currentAudioOutputDevice');

  const audioOutputDevicesChangeHandler = async () => {
    const devices = await getAudioOutputMediaDevices();

    // If there are no devices, let's store an empty value.
    if (devices.length === 0) {
      setCurrentAudioOutputDeviceId('');
      return;
    }

    const isStillConnected = hasDevice(devices, getCurrentAudioOutputDeviceId());
    if (isStillConnected) {
      // The current output device is still connected, so we don't need to update it.
      return;
    }

    // Since the current output device was removed, let's use the default device.
    const defaultDeviceId = getDefaultAudioOutputDeviceId(devices);
    setCurrentAudioOutputDeviceId(defaultDeviceId);

    // Update the current audio output device ID
    try {
      await updateSubscribersSinkId(defaultDeviceId);
    } catch (error) {
      logging.debug('Failed to set the default audio output device.', error);
    }
  };

  return audioOutputDevicesChangeHandler;
};

export default audioOutputDevicesChangeHandlerFactory;

// @todo enable the following disabled rules see OPENTOK-31136 for more info
import Widget from './behaviour/widget';

// BackingBar Chrome Widget
//
// nameMode (String)
// Whether or not the name panel is being displayed
// Possible values are: "auto" (the name is displayed
// when the stream is first displayed and when the user mouses over the display),
// "off" (the name is not displayed), and "on" (the name is displayed).
//
// muteMode (String)
// Whether or not the mute button is being displayed
// Possible values are: "auto" (the mute button is displayed
// when the stream is first displayed and when the user mouses over the display),
// "off" (the mute button is not displayed), and "on" (the mute button is displayed).
//
// displays a backing bar
// can be shown/hidden
// can be destroyed
export default function BackingBar(options) {
  let _nameMode = options.nameMode;
  let _muteMode = options.muteMode;

  function getDisplayMode() {
    if (_nameMode === 'on' || _muteMode === 'on') {
      return 'on';
    } else if (_nameMode === 'mini' || _muteMode === 'mini') {
      return 'mini';
    } else if (_nameMode === 'mini-auto' || _muteMode === 'mini-auto') {
      return 'mini-auto';
    } else if (_nameMode === 'auto' || _muteMode === 'auto') {
      return 'auto';
    }

    return 'off';
  }

  // Mixin common widget behaviour
  Widget(this, {
    mode: getDisplayMode(),
    nodeName: 'div',
    htmlContent: '',
    htmlAttributes: {
      className: 'OT_bar OT_edge-bar-item',
    },
  });

  this.setNameMode = function (nameMode) {
    _nameMode = nameMode;
    this.setDisplayMode(getDisplayMode());
  };

  this.setMuteMode = function (muteMode) {
    _muteMode = muteMode;
    this.setDisplayMode(getDisplayMode());
  };
}

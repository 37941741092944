import assign from 'lodash/assign';
import eventing from '../../helpers/eventing';

const values = obj => Object.keys(obj).map(key => obj[key]);

// Manages N Chrome elements
export default function Chrome(properties) {
  const _widgets = {};

  // Private helper function
  const _set = (name, widget) => {
    assign(widget, { parent: this });
    widget.appendTo(properties.parent);
    _widgets[name] = widget;
    this[name] = widget;
  };

  if (!properties.parent) {
    // @todo raise an exception
    return;
  }

  eventing(this);

  this.destroy = () => {
    this.off();
    this.hideWhileLoading();
    values(_widgets).forEach(widget => widget.destroy());
  };

  this.showAfterLoading = () => {
    values(_widgets).forEach(widget => widget.showAfterLoading());
  };

  this.hideWhileLoading = () => {
    values(_widgets).forEach(widget => widget.hideWhileLoading());
  };

  // Adds the widget to the chrome and to the DOM. Also creates a accessor
  // property for it on the chrome.
  //
  // @example
  //  chrome.set('foo', new FooWidget());
  //  chrome.foo.setDisplayMode('on');
  //
  // @example
  //  chrome.set({
  //      foo: new FooWidget(),
  //      bar: new BarWidget()
  //  });
  //  chrome.foo.setDisplayMode('on');
  //
  this.set = (widgetName, widget) => {
    if (typeof widgetName === 'string' && widget) {
      _set(widgetName, widget);
    } else {
      Object.keys(widgetName)
        .forEach((name) => {
          _set(name, widgetName[name]);
        });
    }
    return this;
  };
}

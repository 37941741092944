import createLogger from '../../helpers/log';

const logging = createLogger('applySdpTransform');

export default (transforms, localRemote, offerAnswer, transformOptions, sdp) => {
  const transform = transforms[localRemote][offerAnswer];
  const type = `${localRemote} ${offerAnswer}`;

  const pubSub = (type === 'local offer' || type === 'remote answer' ?
    'publisher' :
    'subscriber'
  );

  const logPrefix = `sdpTransforms (${pubSub}): ${localRemote} ${offerAnswer}`;

  const createdReceived = (localRemote === 'local' ? 'created' : 'received');
  logging.debug(`${logPrefix}: ${createdReceived}:\n${sdp}`);

  const transformedSdps = transform(transformOptions, sdp);

  if (transformedSdps.local === sdp) {
    logging.debug(`${logPrefix}: not modified`);
  } else {
    logging.debug(`${logPrefix}: modified to:\n${transformedSdps.local}`);
  }

  return transformedSdps;
};

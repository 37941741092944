const getPacketStats = (stats, mediaKind) => {
  const outboundRtpDataStats = stats.filter(({ type, kind }) => type === 'outbound-rtp' && kind === mediaKind);
  const remoteInboundRtpDataStats = stats.filter(({ type, kind }) => type === 'remote-inbound-rtp' && kind === mediaKind);
  const { timestamp } = remoteInboundRtpDataStats[0];

  // We only collect fractionLost for first track, so only use this value for audioPL
  // TODO: VIDCS-1373 add video fractionLost for each simulcast layer weighted by packetsSent
  const { fractionLost } = remoteInboundRtpDataStats[0];

  // Sum total packets from all rtp stats, could be up to 3 video streams with simulcast
  const packetsSent = outboundRtpDataStats.reduce((acc, data) => acc + data.packetsSent, 0);
  const packetsLost = remoteInboundRtpDataStats.reduce((acc, data) => acc + data.packetsLost, 0);

  return {
    [`${mediaKind}ReportTimestamp`]: timestamp,
    [`${mediaKind}PacketsSent`]: packetsSent,
    [`${mediaKind}PacketsLost`]: packetsLost,
    [`${mediaKind}FractionLost`]: fractionLost,
  };
};

export const getVideoResolution = (stats) => {
  const outboundRtpDataStats = stats
    .filter(({ type, kind }) => type === 'outbound-rtp' && kind === 'video')
    .filter(stat => stat.frameHeight !== undefined && stat.frameWidth !== undefined);

  // get largest resolution of simulcast layers
  const resolution = outboundRtpDataStats.reduce((acc, data) => ({
    width: Math.max(acc.width, data.frameWidth),
    height: Math.max(acc.height, data.frameHeight),
  }), {
    width: 0,
    height: 0,
  });

  return resolution;
};

export const getAudioPacketStats = stats => getPacketStats(stats, 'audio');

export const getVideoPacketStats = stats => getPacketStats(stats, 'video');

export const getSelectedCandidatePairId = (stats) => {
  const transportStats = stats.find(({ type }) => type === 'transport');
  return transportStats?.selectedCandidatePairId;
};

const getBandwidthForCandidatePairId = (stats, selectedCandidatePairId) => { // eslint-disable-line consistent-return
  if (selectedCandidatePairId) {
    const candidatePairData = stats.find(({ id, type }) => id === selectedCandidatePairId && type === 'candidate-pair');
    return candidatePairData?.availableOutgoingBitrate;
  }
};

export const getBandwidth = (stats) => {
  const bandwidth = getBandwidthForCandidatePairId(stats, getSelectedCandidatePairId(stats));
  if (bandwidth !== undefined) {
    return bandwidth;
  }
  const candidatePairData = stats.find(({ type, availableOutgoingBitrate }) => (type === 'candidate-pair' && availableOutgoingBitrate !== undefined));
  return candidatePairData?.availableOutgoingBitrate;
};

export const parseStats = (stats) => {
  const { audioPacketsSent, audioPacketsLost, audioReportTimestamp, audioFractionLost }
      = getAudioPacketStats(stats);
  const { videoPacketsSent, videoPacketsLost, videoReportTimestamp }
      = getVideoPacketStats(stats);
  const bandwidth = getBandwidth(stats);
  const videoResolution = getVideoResolution(stats);
  return {
    bandwidth,
    audioFractionLost,
    audioReportTimestamp,
    audioPacketsSent,
    audioPacketsLost,
    videoReportTimestamp,
    videoPacketsSent,
    videoPacketsLost,
    videoResolution,
  };
};

const safeDivide = (num, den) => {
  if (den === 0) {
    return 0;
  }
  return num / den;
};

const getPacketLoss = (type, currentParsedStats, prevParsedStats) => {
  const packetsSentDelta = currentParsedStats[`${type}PacketsSent`] - prevParsedStats[`${type}PacketsSent`];
  const packetsLostDelta = currentParsedStats[`${type}PacketsLost`] - prevParsedStats[`${type}PacketsLost`];
  return safeDivide(packetsLostDelta, packetsSentDelta);
};

export const getAudioPacketLoss = (currentParsedStats, prevParsedStats) => getPacketLoss('audio', currentParsedStats, prevParsedStats);
export const getVideoPacketLoss = (currentParsedStats, prevParsedStats) => getPacketLoss('video', currentParsedStats, prevParsedStats);

// Tracks one timer so that when you reset it you can't accidentally get multiple timers.
export default class Timer {
  name;
  logger;
  duration;

  currId;

  constructor({ name, logger, duration }) {
    this.name = name;
    this.logger = logger;
    this.duration = duration;
  }

  reset(callback) {
    this.clear();

    const id = setTimeout((() => {
      if (this.currId === undefined) {
        // This shouldn't be necessary, except for this bug in jasmine's fake clock:
        // https://github.com/jasmine/jasmine/issues/1426
        return;
      }

      this.logger.spam(`${this.name} timer with id ${this.currId} expired, entering callback`);
      this.currId = undefined;
      callback();
    }), this.duration);

    this.currId = id;

    this.logger.spam(`Started ${this.name} timer with id ${this.currId}, expires in ` +
      `${this.duration}ms`);
  }

  clear() {
    if (this.currId === undefined) {
      return;
    }

    clearTimeout(this.currId);
    this.logger.spam(`Cleared ${this.name} timer with id ${this.currId}`);
    this.currId = undefined;
  }

  isRunning() {
    return this.currId !== undefined;
  }
}

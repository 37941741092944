import getOtError from '../helpers/otError';
import Errors from '../ot/Errors';

const otError = getOtError();

export default function createCanvasVideoTrack(dimensions) {
  const canvas = document.createElement('canvas');

  if (!canvas.captureStream) {
    throw otError(
      Errors.NOT_SUPPORTED,
      new Error('Your browser does not support HTMLCanvasElement.captureStream()')
    );
  }

  canvas.width = dimensions.width;
  canvas.height = dimensions.height;

  canvas.getContext('2d').fillRect(0, 0, 1, 1);

  const [canvasTrack] = canvas.captureStream().getVideoTracks();
  canvasTrack.isCreatedCanvas = true;

  // we never actually intend to send frames from the canvas so it gets disabled
  canvasTrack.enabled = false;
  return canvasTrack;
}

let currentAudioOutputDeviceId = '';

const setCurrentAudioOutputDeviceId = (newDeviceId) => {
  currentAudioOutputDeviceId = newDeviceId;
};

const getCurrentAudioOutputDeviceId = () => currentAudioOutputDeviceId;

const currentAudioOutputDevice = {
  getCurrentAudioOutputDeviceId,
  setCurrentAudioOutputDeviceId,
};

export default currentAudioOutputDevice;

import StateMachine from 'javascript-state-machine';
import elapsed from '../helpers/elapsed';
import { codes } from './socketCloseCodes';
import createLogger from '../helpers/log';

const logger = createLogger('ReconnectableSocketStateMachine');

const { CLOSE_UNSUPPORTED, CLOSE_UNEXPECTED_CONDITION } = codes;

export default ({ reconnectMaxDuration, CLOSED, CLOSING }) => {
  let hasReconnectMaxDurationElapsed;

  function handleDisconnect({ code }) {
    if (this.state === 'open' || !hasReconnectMaxDurationElapsed) {
      hasReconnectMaxDurationElapsed = elapsed(reconnectMaxDuration);
    }

    const shouldReconnect = reconnectMaxDuration > 0 &&
      !hasReconnectMaxDurationElapsed() &&
      [CLOSE_UNSUPPORTED, CLOSE_UNEXPECTED_CONDITION].indexOf(code) === -1 &&
      [CLOSED, CLOSING].indexOf(this.readyState) === -1;

    if (shouldReconnect) {
      return 'reconnecting';
    }

    return 'closing';
  }

  return new StateMachine({
    init: 'connecting',
    methods: {
      onInvalidTransition(transition, from, to) {
        logger.error('Invalid state transition', { transition, from, to });
      },
    },
    transitions: [
      { name: 'open', from: ['connecting', 'reconnecting'], to: 'open' },
      { name: 'close', from: ['connecting', 'reconnecting', 'open'], to: 'closing' },
      { name: 'closed', from: 'closing', to: 'closed' },
      { name: 'disconnect', from: ['reconnecting', 'open', 'connecting'], to: handleDisconnect },
    ],
  });
};

import createLogger from '../log';

const log = createLogger('WidgetView:fixMini');

const miniWidth = 128;
const miniHeight = 128;
const microWidth = 64;
const microHeight = 64;

export default function fixMini(/** @type {HTMLElement} */ container) {
  const { clientWidth, clientHeight } = container;

  container.classList.remove('OT_micro');
  container.classList.remove('OT_mini');

  if (clientWidth < microWidth || clientHeight < microHeight) {
    container.classList.add('OT_micro');
    log.debug('OT_micro class added', { clientWidth, clientHeight, microWidth, microHeight });
  }

  if (clientWidth < miniWidth || clientHeight < miniHeight) {
    container.classList.add('OT_mini');
    log.debug('OT_mini class added', { clientWidth, clientHeight, miniWidth, miniHeight });
  }
}

import currentAudioOutputDevice from './currentAudioOutputDevice';
import deviceHelpers from '../helpers/deviceHelpers';
import noop from '../helpers/noop';
import AnalyticsHelper from '../helpers/analytics';
import sessionObjects from './session/objects';
import getActiveAudioOutputDevice from '../ot/get-active-audio-output-device';

const {
  setCurrentAudioOutputDeviceId,
} = currentAudioOutputDevice;

const {
  getDefaultAudioOutputDeviceId,
  getAudioOutputMediaDevices,
} = deviceHelpers();

const analytics = new AnalyticsHelper();
const logUpdateSubscriberSinkId = (variation, payload) => {
  analytics.logEvent({
    action: 'updateSubscribersSinkId',
    variation,
    payload,
  });
};

const updateSinkId = (newDeviceId) => {
  const updateSubscribersSinkId = sessionObjects.subscribers.map(subscriber =>
    subscriber._.setSinkId(newDeviceId)
  );
  return Promise.all(updateSubscribersSinkId);
};

const rollbackToDevice = async (rollbackDeviceId) => {
  // A noop is useful to make sure updateSinkId is executed once the pending tasks are finished.
  await noop();

  try {
    logUpdateSubscriberSinkId('Attempt', { deviceId: rollbackDeviceId });
    await updateSinkId(rollbackDeviceId);
    logUpdateSubscriberSinkId('Success', { deviceId: rollbackDeviceId });
  } catch (error) {
    logUpdateSubscriberSinkId('Failure', {
      deviceId: rollbackDeviceId,
      error: error?.message,
    });

    throw error;
  }
};

const rollbackToDefaultDevice = async () => {
  // Get the defaut device ID
  const devices = await getAudioOutputMediaDevices();
  const defaultDeviceId = getDefaultAudioOutputDeviceId(devices);
  try {
    await rollbackToDevice(defaultDeviceId);
    setCurrentAudioOutputDeviceId(defaultDeviceId);
  } catch (error) {
    // Ignoring this error. We're already logging in rollToDevice()
  }
};

const onUpdateSubscriberSinkIdError = async () => {
  // Attempt to rollback to the previous deviceId if possible.
  const currentDevice = await getActiveAudioOutputDevice();
  try {
    await rollbackToDevice(currentDevice.deviceId);
  } catch (error) {
    // In the case the first rollback failed, let's try a final attempt
    // with the default device. If this last attempt fails, we stop trying here.
    await rollbackToDefaultDevice();
  }
};

export default async (deviceId) => {
  try {
    logUpdateSubscriberSinkId('Attempt', { deviceId });
    await updateSinkId(deviceId);
    logUpdateSubscriberSinkId('Success', { deviceId });
    setCurrentAudioOutputDeviceId(deviceId);
  } catch (error) {
    logUpdateSubscriberSinkId('Failure', {
      deviceId,
      error: error?.message,
    });

    await onUpdateSubscriberSinkIdError();

    // Even the rollback mechanism worked, we will let the user know that the selected device
    // has an issue.
    throw error;
  }
};

import env from './env';

/**
 * Firefox, Edge, Safari and Chrome v58+ will use the standard version
 * of getStats API.
 * https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/getStats#Browser_compatibility
 */
export default () => (
  (env.name === 'Chrome' && env.version >= 58) ||
  (['Firefox', 'Edge', 'Safari', 'Opera'].indexOf(env.name) !== -1)
);

import ExceptionCodes from '../ot/exception_codes';

export default function convertAnvilErrorCode(code) {
  switch (code) {
    case ExceptionCodes.AUTHENTICATION_ERROR:
    case ExceptionCodes.INVALID_SESSION_ID:
    case ExceptionCodes.TERMS_OF_SERVICE_FAILURE:
    case ExceptionCodes.UNEXPECTED_SERVER_RESPONSE:
      return code;
    case ExceptionCodes.ANVIL_EMPTY_RESPONSE_BODY:
    case ExceptionCodes.ANVIL_CONNECT_FAILED:
      return ExceptionCodes.CONNECT_FAILED;
    case ExceptionCodes.ANVIL_BADLY_FORMED_RESPONSE:
    case ExceptionCodes.ANVIL_INVALID_HTTP_STATUS:
    case ExceptionCodes.ANVIL_XDOMAIN_OR_PARSING_ERROR:
    case ExceptionCodes.ANVIL_UNKNOWN_HTTP_ERROR:
    case ExceptionCodes.ANVIL_UNEXPECTED_ERROR_CODE:
    default:
      return ExceptionCodes.UNEXPECTED_SERVER_RESPONSE;
  }
}

/* global MediaStream */
/* eslint-disable global-require, no-param-reassign, no-void, no-shadow */
/* eslint-disable func-names */
import uuid from 'uuid';
import eventing from '../../helpers/eventing';
import { collectRtcStatsReportHelper, collectGetStatsHelper } from './collectStatsHelper';

const createNativeMediaStream = () => new MediaStream();

export default function SinglePeerConnectionAdapterFactory(deps = {}) {
  const createMediaStream = deps.createMediaStream || createNativeMediaStream;
  return function SinglePeerConnectionAdapter(options, controller) {
    const _transceivers = {};
    const _tracks = [];
    const mediaStream = createMediaStream();

    this._id = uuid();

    // Trigger wrappers to ensure events only fired once
    let _iceConnectedEmitted = false;
    const _onIceConnected = () => {
      if (!_iceConnectedEmitted) {
        this.trigger('iceConnected');
        _iceConnectedEmitted = true;
      }
    };

    let _iceConnectionStateConnectedEmitted = false;
    const _onIceConnectionStateChanged = (state) => {
      if (state === 'connected' && _iceConnectionStateConnectedEmitted) {
        return;
      }
      _iceConnectionStateConnectedEmitted = state === 'connected';
      this.trigger('iceConnectionStateChange', state);
    };

    // SPC events wrapper for PeerConnection
    const _onClosed = () => this.trigger('close');
    const _onRemoteVideoSupported = supported => this.trigger('remoteVideoSupported', supported);
    const _onPeerError = ({ reason, prefix }) => this.trigger('error', { reason, prefix });
    const _onQoS = (qos) => {
      qos.parsedStats.singlePeerConnection = true;
      this.trigger('qos', qos);
    };
    const _onSignalingStateChange = state => this.trigger('signalingStateChange', state);
    const _onSignalingStateStable = state => this.trigger('signalingStateStable', state);
    const _onDecryptFailed = () => this.trigger('decryptFailed');
    const _onDecryptRestored = () => this.trigger('decryptRestored');

    eventing(this);

    const _peerConnection = controller.getPeerConnection(options, this);

    _peerConnection.on({
      iceConnected: _onIceConnected,
      close: _onClosed,
      signalingStateChange: _onSignalingStateChange,
      signalingStateStable: _onSignalingStateStable,
      error: _onPeerError,
      qos: _onQoS,
      iceConnectionStateChange: _onIceConnectionStateChanged,
      remoteVideoSupported: _onRemoteVideoSupported,
      decryptFailed: _onDecryptFailed,
      decryptRestored: _onDecryptRestored,
    }, this);

    // Specific SPC logic
    this.onTrackAdded = ({ track, transceiver }) => {
      _transceivers[track.id] = transceiver.mid;
      controller.addSubscriberMid(transceiver.mid, this._id);
      _tracks.push(track);
      mediaStream.addTrack(track);
      if (_peerConnection.iceConnectionStateIsConnected()) {
        // For SPC if ICE state is already connected when track is added
        // then this is not the first subscriber and we must trigger the event manually
        // using the following wrappers protect against firing events multiple times.
        _onIceConnected();
        _onIceConnectionStateChanged('connected');
      }
      this.trigger('trackAdded', { stream: mediaStream, track });
    };

    this.disconnect = () => {
      controller.removeSubscriber(this._id, Object.values(_transceivers));
    };

    this.remoteTracks = () => _tracks;

    // SPC wrapper for PeerConnection

    this.generateOfferAndSend = () => _peerConnection.generateOfferAndSend();

    this.getDataChannel = (label, options, completion) => {
      _peerConnection.getDataChannel(label, options, completion);
    };

    this.getSourceStreamId = () => _peerConnection.getSourceStreamId();

    this.processOffer = async (message) => {
      message.content.sdp = await controller.processOffer(message.content.sdp);
      _peerConnection.processMessage('offer', message);
    };

    this.processAnswer = (message) => {
      message.content.sdp = controller.processAnswer(message.content.sdp);

      _peerConnection.processMessage('answer', message);
      // When Mantis sent an answer, the negotiation is done.
      controller.subscriberComplete();
    };

    this.processMessage = (type, message) => {
      if (type === 'offer') {
        this.processOffer(message);
      } else if (type === 'answer') {
        this.processAnswer(message);
      } else {
        _peerConnection.processMessage(type, message);
      }
    };

    this.remoteDescription = () => _peerConnection.remoteDescription();

    this.getStats = (track, callback) => {
      if (track) {
        // getStats for a specific track.
        _peerConnection.getStats(track, callback);
      } else {
        // getStats for all tracks.
        collectGetStatsHelper(_peerConnection, _tracks, callback);
      }
    };

    this.getSynchronizationSources = (callback) => {
      _peerConnection.getSynchronizationSources(callback);
    };

    this.getRtcStatsReport = (track, callback) => {
      if (track) {
        // getRtcStatsReport for a specific track.
        _peerConnection.getRtcStatsReport(track, callback);
      } else {
        // getRtcStatsReport for all tracks.
        collectRtcStatsReportHelper(_peerConnection, _tracks, callback);
      }
    };

    this.remoteStreams = () => _peerConnection.remoteStreams();

    this.hasRelayCandidates = () => _peerConnection.hasRelayCandidates();

    this.iceRestart = () => _peerConnection.iceRestart();

    this.iceConnectionStateIsConnected = () => _peerConnection.iceConnectionStateIsConnected();

    this.startDecryption = (connectionId, transceiver) =>
      _peerConnection.startDecryption(connectionId, transceiver);
  };
}

class QueueRunner {
  queue = [];
  running = false;
  constructor({ waitTime = 50 } = {}) {
    this.waitTime = waitTime;
  }
  add(fn) {
    this.queue.push(fn);
    this.run();
  }
  run() {
    if (this.running) {
      return;
    }
    this.running = true;
    const loop = () => {
      if (this.queue.length === 0) {
        this.running = false;
        return;
      }
      const fn = this.queue.shift();
      fn(() => {
        setTimeout(loop, this.waitTime);
      });
    };
    loop();
  }
}
export default QueueRunner;

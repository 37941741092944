/**
 * Returns the number of streams allowed for simulcast
 * @param {Boolean} isChromiumEdge
 * @param {String} browserName
 * @param {Boolean} capableSimulcastScreenshare
 * @param {Boolean} isScreenSharing
 * @param {Boolean} isCustomVideoTrack
 * @param {Boolean} scalableVideo
 * @param {Object} sessionInfo
 * @param {Object} constraints
 * @param {Object} videoDimensions
 * @return {Number}
*/
export default function calculateCapableSimulcastStreams(opt) {
  const supportedBrowsers = ['Chrome', 'Safari', 'Edge', 'Opera'];
  if (
    supportedBrowsers.indexOf(opt.browserName) === -1 ||
    (opt.browserName === 'Edge' && !opt.isChromiumEdge) ||
    (opt.isScreenSharing && !opt.capableSimulcastScreenshare) ||
    opt.sessionInfo.p2pEnabled ||
    (!opt.constraints.video && !opt.isCustomVideoTrack) ||
    (opt.scalableVideo === false)
  ) {
    // We only support simulcast on Chromium browsers and Safari for routed sessions
    return 1;
  }

  // HD and above gets three streams. Otherwise they get 2.
  if (opt.videoDimensions.width > 640 && opt.videoDimensions.height > 480) {
    return 3;
  }

  return 2;
}

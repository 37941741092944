export default (peerConnection, completion) => {
  let stats = [];
  let error = null;
  if (typeof peerConnection.getReceivers === 'function') {
    const audioTrack = peerConnection.getReceivers()
      .find(r => r.track.kind === 'audio');
    if (audioTrack) {
      stats = audioTrack.getSynchronizationSources();
    }
  } else {
    error = new Error('peerConnection does not support getReceivers function');
  }

  if (completion) {
    completion(error, stats);
  }
};

// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable one-var, no-use-before-define, no-param-reassign */
/* eslint-disable max-len, no-var, vars-on-top, global-require */
import blacklistedStats from '../ot/peer_connection/qos/blacklistedStats';
import isSessionProxiedDefault from './isSessionProxied';

export default function sanitizeQosData(deps = {}) {
  const isSessionProxied = deps.isSessionProxied || isSessionProxiedDefault;
  const { sessionId } = deps;

  if (!isSessionProxied(sessionId)) {
    return;
  }

  blacklistedStats.forEach((stat) => {
    if (stat in deps) {
      deps[stat] = '';
    }
  });
}

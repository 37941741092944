import clone from 'lodash/clone';
import createLogger from './log';
import env from '../helpers/env';
import filterIceServers from './filterIceServers';
import shouldUsePlanBSDP from '../helpers/shouldUsePlanBSDP';

const logging = createLogger('createPeerConnection');

export default function createPeerConnection({
  window,
  config: originalConfig,
  constraints,
}) {
  logging.debug('called');

  const config = clone(originalConfig);

  config.iceServers = filterIceServers(env, config.iceServers);
  if (shouldUsePlanBSDP()) {
    config.sdpSemantics = 'plan-b';
  }
  const pc = new window.RTCPeerConnection(config, constraints);

  return Promise.resolve(pc);
}

// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-param-reassign, global-require */
import otErrorFactory from '../otError';
import ErrorsDefault from '../../ot/Errors';

export default function videoElementErrorMapFactory(deps = {}) {
  const Errors = deps.Errors || ErrorsDefault;
  const otError = deps.otError || otErrorFactory();

  // See http://www.w3.org/TR/2010/WD-html5-20101019/video.html#error-codes
  const videoErrorDetails = {};

  // Checking for global.MediaError for IE compatibility, just so we don't throw
  // exceptions when the script is included
  if (global.MediaError) {
    videoErrorDetails[global.MediaError.MEDIA_ERR_ABORTED] = {
      name: Errors.MEDIA_ERR_ABORTED,
      message: 'The fetching process for the media resource was aborted by the ' +
        'user agent at the user\'s request.',
    };

    videoErrorDetails[global.MediaError.MEDIA_ERR_NETWORK] = {
      name: Errors.MEDIA_ERR_NETWORK,
      message: 'A network error of some description caused the user agent to ' +
        'stop fetching the media resource, after the resource was established ' +
        'to be usable.',
    };

    videoErrorDetails[global.MediaError.MEDIA_ERR_DECODE] = {
      name: Errors.MEDIA_ERR_DECODE,
      message: 'An error of some description occurred while decoding the media ' +
        'resource, after the resource was established to be usable.',
    };

    videoErrorDetails[global.MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED] = {
      name: Errors.MEDIA_ERR_SRC_NOT_SUPPORTED,
      message: 'The media resource indicated by the src attribute was not ' +
        'suitable.',
    };
  }

  return function videoElementErrorMap(error) {
    const errorDetails = videoErrorDetails[error.code];

    if (!errorDetails) {
      return new Error(`An unknown error occurred${error.message ? `: ${error.message}` : '.'}`);
    }

    return otError(
      errorDetails.name,
      new Error(`There was an unexpected problem with the Video Stream: ${
        errorDetails.message}`)
    );
  };
}

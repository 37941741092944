import getProtocolFromPriority from './getProtocolFromPriority';

export default (selectedCandidatePairId, stats) => {
  let localRelayProtocol = '';

  if (!selectedCandidatePairId || !stats) {
    return localRelayProtocol;
  }

  const activeCandidatePair = stats.get(selectedCandidatePairId);

  if (activeCandidatePair) {
    const { localCandidateId } = activeCandidatePair;

    if (localCandidateId) {
      const localCandidate = stats.get(localCandidateId);

      if (localCandidate) {
        const { relayProtocol, priority } = localCandidate;

        if (relayProtocol) {
          const RELAY_PROTOCOL = relayProtocol.toUpperCase();

          localRelayProtocol = `TURN/${RELAY_PROTOCOL}`;
        } else {
          localRelayProtocol = getProtocolFromPriority(priority);
        }
      }
    }
  }

  return localRelayProtocol;
};

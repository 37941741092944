export default ({ socket, uri, content: defaultContent }) =>
  ({ type: method, content }, completion) => socket.send({
    message: {
      uri,
      method,
      content: {
        ...defaultContent,
        ...content,
      },
    },
  }, completion);

import createLogger from '../../../helpers/log';

const logging = createLogger('partialSdp');
/**
 * Construct partialSdp answer with only header and new track media sections
 * @param {ParsedSdp} newAnswer - full local remote answer from the PC
 * @param {Array<string>} newMids - array of mids of tracks added in this operation
 * @returns {ParsedSdp} partialSdp - partialSdp containing only the new headers and
 * the media sections of the new tracks.
 */
const getPartialSdpAnswer = (newAnswer, newMids) => {
  logging.debug('full answer: ', newAnswer);
  const newlyAddedMediaSections = newAnswer.mediaSections.filter((mediaSection) => {
    const includesSomeId = newMids.some((mid) => {
      const mediaSectionString = mediaSection.join('');
      const hasSection = mediaSectionString.includes(`a=mid:${mid}`);
      return hasSection;
    });
    return includesSomeId;
  });
  const partialAnswer = {
    headers: newAnswer.headers,
    mediaSections: newlyAddedMediaSections,
  };
  logging.debug('partial answer: ', partialAnswer);
  return partialAnswer;
};

export default getPartialSdpAnswer;

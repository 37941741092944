import env from '../helpers/env';

export default () => {
  const isBrowser = !env.isNode && !env.isElectron;

  if (!isBrowser) {
    return false;
  }

  // More recent browsers don't expose GUM when called in insecure context
  // From https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
  const isSecureContextRequired =
    // See https://www.chromestatus.com/feature/4924861776396288
    ((env.isChrome || env.isOpera || env.isChromiumEdge) && env.version >= 74) ||
    (env.isFirefox && env.version >= 68) ||
    // See https://webkit.org/blog/7763/a-closer-look-into-webrtc/
    (env.isSafari && env.version >= 11);

  return isSecureContextRequired;
};

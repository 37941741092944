/* eslint-disable no-constant-condition */

import logEvents from './logEvents';
import { codes } from './socketCloseCodes';
import Timer from './Timer';
import eventHelper from '../helpers/eventHelper';
import createReadyStateMachine from './createReadyStateMachine';
import drainWebSocket from './drainWebSocket';

const removeReturnVal = fn => (...args) => { fn(...args); };
const isValidCode = code => code === 1000 || (code >= 3000 && code <= 4999);

const { CLOSE_TIMEOUT } = codes;

export default ({
  AnalyticsHelper,
  EventEmitter,
  WebSocket,
  logging,
  allocateId,
  BUFFER_DRAIN_INTERVAL = 100,
  BUFFER_DRAIN_MAX_RETRIES = 10,
}) => class ReconnectableSocket extends EventEmitter {
  static CONNECTING = WebSocket.CONNECTING;
  static OPEN = WebSocket.OPEN;
  static CLOSING = WebSocket.CLOSING;
  static CLOSED = WebSocket.CLOSED;

  _url;

  get url() { return typeof this._url === 'function' ? this._url() : this._url; }
  get reconnecting() { return this.readyStateMachine.state === 'reconnecting'; }
  get binaryType() { return this._webSocket.binaryType; }
  get bufferedAmount() { return this._webSocket.bufferedAmount; }
  get protocol() { return this._webSocket.protocol; }
  get extensions() { return this._webSocket.extensions; }
  get readyState() {
    const STATE_MAP = {
      reconnecting: ReconnectableSocket.OPEN,
      open: ReconnectableSocket.OPEN,
      closing: ReconnectableSocket.CLOSING,
      closed: ReconnectableSocket.CLOSED,
      connecting: ReconnectableSocket.CONNECTING,
    };
    return STATE_MAP[this.readyStateMachine.state];
  }

  _id = allocateId();
  _logger = logging(`ReconnectableSocket:${this._id}`);
  _webSocket;
  _connectTimer;
  _pingTimer;
  _pingWarningTimer;
  _disconnectTimer;
  _queuedMessages = [];
  _draining = false;
  _analytics = new AnalyticsHelper();

  constructor(opt) {
    super();
    const {
      url,
      connectionId,
      sessionId,

      // Max time for opening a websocket
      connectTimeout = 15000,

      // Time without sending a message after which a ping should be sent (needsPing is emitted for
      // user to take care of this).
      pingThreshold = 3000,

      // After emitting needsPing, we check after this delay whether a message was sent, if not, a
      // warning is emitted.
      pingWarningDelay = 100,

      // With reconnections, a disconnect may be followed by reconnecting, otherwise we go straight
      // to closed.
      disconnectThreshold = 5900,

      // Set this to 0 for no reconnections.
      reconnectMaxDuration = 60000,
    } = opt;

    if (!url) {
      throw new Error('Must provide url');
    }
    this.connectionId = connectionId;
    this.sessionId = sessionId;

    this.readyStateMachine = createReadyStateMachine({
      reconnectMaxDuration,
      CLOSED: ReconnectableSocket.CLOSED,
      CLOSING: ReconnectableSocket.CLOSING,
    });

    this._url = url;

    logEvents({
      logger: this._logger,
      obj: this,
      eventNames: [
        'error', 'open', 'message', 'needsPing', 'reconnecting', 'reconnectAttempt',
        'reconnectFailure', 'reconnected', 'close',
      ],
    });

    this.readyStateMachine.observe('onEnterOpen', ({ from }) => {
      this._resetPingTimers();
      this._resetDisconnectTimer();
      this._connectTimer.clear();

      if (from === 'reconnecting') {
        this._queuedMessages.forEach(msg => this.send(msg, true));
        this._queuedMessages = [];
        this.emit('reconnected');
        this._logAnalyticsEvent({ action: 'WebSocket:reconnect', variation: 'Success' });
      } else {
        this.emit('open');
        this._logAnalyticsEvent({ action: 'WebSocket:open' });
      }
    });

    this.readyStateMachine.observe('onEnterClosed', ({ from, code, reason }, error) => {
      this.emit('close', error);
      if (from === 'reconnecting') {
        this._logAnalyticsEvent({
          action: 'WebSocket:reconnect',
          variation: 'Failure',
          payload: { code, reason },
        });
      }
    });

    this.readyStateMachine.observe('onEnterClosing', removeReturnVal(async ({ from }, { code, reason }) => {
      this._clearTimers();

      await this._clearSocket({ drain: from === 'open', code, reason });

      if (from === 'reconnecting') {
        this.emit('reconnectFailure', { code, reason });
        this._logAnalyticsEvent({ action: 'WebSocket:reconnect', variation: 'Failure' });
      }

      setTimeout(() => {
        this.readyStateMachine.closed({ code, reason });
      });
    }));

    this.readyStateMachine.observe('onEnterReconnecting', () => {
      this.emit('reconnecting');
      this._logAnalyticsEvent({ action: 'WebSocket:reconnect', variation: 'Attempt' });
    });

    this.readyStateMachine.observe('onDisconnect', removeReturnVal(async ({ from, to }, { code, reason }) => {
      if (to === 'reconnecting') {
        // prevent reconnections overlapping
        if (this._draining) { return; }

        this._clearTimers();

        this.emit('reconnectAttempt');
        await this._clearSocket({ drain: from === 'open', code, reason });

        this._createSocket();
      } else {
        this._logAnalyticsEvent({ action: 'WebSocket:disconnect', payload: { code, reason } });
      }
    }));

    const createTimer = (name, duration) => new Timer({ name, duration, logger: this._logger });

    this._connectTimer = createTimer('connect', connectTimeout);
    this._pingTimer = createTimer('ping', pingThreshold);
    this._pingWarningTimer = createTimer('pingWarning', pingWarningDelay);
    this._disconnectTimer = createTimer('disconnect', disconnectThreshold);

    this._createSocket();
  }

  _logAnalyticsEvent = ({
    action,
    variation,
    payload,
  }) => {
    this._analytics.logEvent({
      action,
      variation,
      payload,
      sessionId: this.sessionId,
      connectionId: this.connectionId,
    });
  };

  _createSocket() {
    const ws = new WebSocket(this.url);

    ws.binaryType = 'arraybuffer';
    const wsEvents = eventHelper(ws);

    wsEvents.on('open', () => {
      this.readyStateMachine.open();
    });
    wsEvents.on('close', ({ code, reason }) => {
      this.readyStateMachine.disconnect({ code, reason });
    });
    wsEvents.on('error', (event) => { this.emit('error', event); });
    wsEvents.on('message', (event) => {
      if (this.readyState !== ReconnectableSocket.CLOSING) {
        // Without this you can receive a message while we are closing the socket and we end up
        // trying to reconnect again afterwards
        this._resetDisconnectTimer();
      }
      this.emit('message', event);
    });
    this._webSocket = ws;
    this._webSocketEvents = wsEvents;

    this._connectTimer.reset(() => {
      const closeData = { code: CLOSE_TIMEOUT, reason: 'Timeout while opening connection' };
      const error = new Error(closeData.reason);
      error.code = CLOSE_TIMEOUT;
      this.emit('error', error);
      this.readyStateMachine.disconnect(closeData);
    });
  }

  async _clearSocket({ drain = false, code, reason } = {}) {
    this._draining = true;

    if (this._webSocketEvents) {
      this._webSocketEvents.removeAll();
    }

    if (this._webSocket.readyState !== ReconnectableSocket.CLOSED) {
      if (drain) {
        await drainWebSocket(this._webSocket, { BUFFER_DRAIN_INTERVAL, BUFFER_DRAIN_MAX_RETRIES });
      }

      this._logAnalyticsEvent({ action: 'WebSocket:close', payload: { code, reason }, variation: 'Attempt' });
      try {
        if (!isValidCode(code) || (code === undefined && reason === undefined)) {
          // because IE
          this._webSocket.close();
        } else {
          this._webSocket.close(code, reason);
        }
        this._logAnalyticsEvent({ action: 'WebSocket:close', payload: { code, reason }, variation: 'Success' });
      } catch (err) {
        logging.error('Could not close websocket', err);
        this._logAnalyticsEvent({ action: 'WebSocket:close', payload: { err }, variation: 'Failure' });
      }
    }

    this._draining = false;
  }

  send(data, retryAfterReconnect = true) {
    // send deviates from WebSocket a little in that it returns a status instead of nothing.

    if (this.readyState === ReconnectableSocket.OPEN) {
      this._resetPingTimers();
    }

    const sendMessage = () => {
      try {
        this._webSocket.send(data);
        return 'sent';
      } catch (err) {
        if (this._webSocket.readyState === WebSocket.OPEN) {
          // On the other hand, there is a known bug in Firefox where .send throws an exception
          // even though it's open:
          // https://bugzilla.mozilla.org/show_bug.cgi?id=1204727
          this._logger.debug('webSocket.send threw exception even though it was open:', err);
          return 'dropped';
        }
        throw err;
      }
    };

    if (this.readyStateMachine.state === 'reconnecting') {
      // the old socket is still open, let's send the message there as well.
      if (this._webSocket.readyState === WebSocket.OPEN) {
        try {
          sendMessage();
        } catch (err) {
          // but lets ignore any errors so that we can queue it as wel
        }
      }
      if (retryAfterReconnect) {
        this._queuedMessages.push(data);
        return 'queued';
      }
      this._logger.debug(
        'Dropping message during reconnection since retryAfterReconnect is false:',
        data
      );

      return 'dropped';
    }

    return sendMessage();
  }

  close(code, reason) {
    if (this.readyState === ReconnectableSocket.CLOSING) {
      throw new Error('Can not call close on a closing ReconnectableSocket');
    }
    if (this.readyState === ReconnectableSocket.CLOSED) {
      throw new Error('Can not call close on an already closed ReconnectableSocket');
    }
    this.readyStateMachine.close({ code, reason });
  }

  _resetPingTimers() {
    this._pingWarningTimer.clear();

    this._pingTimer.reset(() => {
      this._pingWarningTimer.reset(() => {
        this._logger.warn('Did not send message after needsPing event. Other side may ' +
          'disconnect.');
      });

      this.emit('needsPing');
    });
  }

  _resetDisconnectTimer() {
    this._disconnectTimer.reset(() => this.readyStateMachine.disconnect({
      code: CLOSE_TIMEOUT,
      reason: 'No activity',
    }));
  }

  _clearTimers() {
    [
      this._disconnectTimer,
      this._connectTimer,
      this._pingTimer,
      this._pingWarningTimer,
    ].forEach(timer => timer.clear());
  }
};

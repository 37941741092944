// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-param-reassign, global-require, no-void, prefer-rest-params, no-shadow */
import uuid from 'uuid';
import otErrorDefault from '../../helpers/otError';
import ErrorsDefault from '../Errors';
import haveGetDisplayMediaDefault from '../../helpers/haveGetDisplayMedia';

export default function chromeExtensionHelperFactory({
  haveGetDisplayMedia = haveGetDisplayMediaDefault,
  global: context = global,
  Errors = ErrorsDefault,
  otError = otErrorDefault(),
} = {}) {
  const isSupportedInThisBrowser = (
    // don't bother supporting extension when we have display media
    !haveGetDisplayMedia &&
    !!context.navigator.webkitGetUserMedia &&
    !context.navigator.userAgent.match(/android/i) &&
    typeof context.chrome !== 'undefined'
  );

  return {
    isSupportedInThisBrowser,
    autoRegisters: false,
    extensionRequired: true,
    getConstraintsShowsPermissionUI: true,
    sources: {
      screen: true,
      application: false,
      window: true,
      browser: true,
    },

    register(extensionID, version) {
      if (version === 2) {
        return this.registerVersion2(extensionID);
      }
      return this.registerVersion1(extensionID);
    },

    registerVersion1(extensionID) {
      if (!extensionID) {
        throw new Error('initChromeScreenSharingExtensionHelper: extensionID is required.');
      }

      const isChrome = isSupportedInThisBrowser;

      const callbackRegistry = {};
      let isInstalled = void 0;

      const prefix = `com.tokbox.screenSharing.${extensionID}`;
      const request = function (method, payload) {
        const res = { payload, from: 'jsapi' };
        res[prefix] = method;
        return res;
      };

      const addCallback = function (fn, timeToWait) {
        let timeout;
        const requestId = uuid();

        callbackRegistry[requestId] = function () {
          clearTimeout(timeout);
          timeout = null;
          fn(...arguments);
        };
        if (timeToWait) {
          timeout = setTimeout(() => {
            delete callbackRegistry[requestId];
            fn(otError(
              Errors.TIMEOUT,
              new Error('Timeout waiting for response to screensharing request.')
            ));
          }, timeToWait);
        }
        return requestId;
      };

      const isAvailable = function (callback) {
        if (!callback) {
          throw new Error('isAvailable: callback is required.');
        }

        if (!isChrome) {
          setTimeout(callback.bind(null, false));
          return;
        }

        if (isInstalled !== void 0) {
          setTimeout(callback.bind(null, isInstalled));
        } else {
          const requestId = addCallback((event) => {
            if (isInstalled !== true) {
              isInstalled = (event === 'extensionLoaded');
            }
            callback(isInstalled);
          }, 2000);
          const post = request('isExtensionInstalled', { requestId });
          context.postMessage(post, '*');
        }
      };

      const getConstraints = function (source, constraints, callback) {
        if (!callback) {
          throw new Error('getSourceId: callback is required');
        }
        isAvailable((isInstalled) => {
          if (!isInstalled) {
            return callback(otError(
              Errors.SCREEN_SHARING_EXTENSION_NOT_INSTALLED,
              new Error('Extension is not installed')
            ));
          }

          const requestId = addCallback((event, payload) => {
            if (typeof event === 'object' && event.name && event.stack) {
              // FIXME: The callback shouldn't be overloaded like this ('event' is actually an
              // error), but I'm adding tests and don't want to refactor right now without testing
              // more thorougly.
              const error = event;
              callback(error);
            } else if (event === 'permissionDenied') {
              callback(otError(
                Errors.USER_MEDIA_ACCESS_DENIED,
                new Error('User denied access to screensharing')
              ));
            } else {
              if (!constraints.video) {
                constraints.video = {};
              }
              if (!constraints.video.mandatory) {
                constraints.video.mandatory = {};
              }
              constraints.video.mandatory.chromeMediaSource = 'desktop';
              constraints.video.mandatory.chromeMediaSourceId = payload.sourceId;
              callback(void 0, constraints);
            }
          });

          context.postMessage(
            request('getSourceId', { requestId, source }),
            '*'
          );

          return undefined;
        });
      };

      context.addEventListener('message', (event) => {
        if (event.origin !== context.location.origin) {
          return;
        }

        if (!(event.data != null && typeof event.data === 'object')) {
          return;
        }

        if (event.data.from !== 'extension') {
          return;
        }

        const method = event.data[prefix];
        const payload = event.data.payload;

        if (payload && payload.requestId) {
          const callback = callbackRegistry[payload.requestId];
          delete callbackRegistry[payload.requestId];
          if (callback) {
            callback(method, payload);
          }
        }

        if (method === 'extensionLoaded') {
          isInstalled = true;
        }
      });

      return {
        extensionAPIVersion: 1,
        extensionID,
        isInstalled: isAvailable,
        getConstraints,
      };
    },

    registerVersion2(extensionID) {
      const isChrome = (
        isSupportedInThisBrowser &&
        typeof context.chrome.runtime !== 'undefined'
      );

      const isInstalled = function (callback) {
        if (!callback) {
          throw new Error('isAvailable: callback is required.');
        }

        if (!isChrome) {
          setTimeout(callback.bind(null, false));
          return;
        }

        context.chrome.runtime.sendMessage(extensionID, {
          type: 'isInstalled',
        }, null, (response) => {
          setTimeout(callback.bind(null, !!response));
        });
      };

      const getConstraints = function (source, constraints, callback) {
        if (!callback) {
          throw new Error('getSourceId: callback is required');
        }
        isInstalled((installed) => {
          if (!installed) {
            return callback(otError(
              Errors.SCREEN_SHARING_EXTENSION_NOT_INSTALLED,
              new Error('Extension is not installed')
            ));
          }

          context.chrome.runtime.sendMessage(extensionID, {
            type: 'getSourceId',
            source,
          }, null, (data) => {
            if (data.error === 'permissionDenied') {
              callback(otError(
                Errors.USER_MEDIA_ACCESS_DENIED,
                new Error('User denied access to screensharing')
              ));
            } else if (data.error) {
              callback(new Error(`UnexpectError: ${data.error}`));
            } else {
              if (!constraints.video) {
                constraints.video = {};
              }
              if (!constraints.video.mandatory) {
                constraints.video.mandatory = {};
              }
              constraints.video.mandatory.chromeMediaSource = 'desktop';
              constraints.video.mandatory.chromeMediaSourceId = data.sourceId;
              callback(void 0, constraints);
            }
          });
          return undefined;
        });
      };

      return {
        extensionAPIVersion: 2,
        extensionID,
        isInstalled,
        getConstraints,
      };
    },
  };
}

/**
 * promisify - Promisify a node style callback function into a promise
 *
 * Returns a promisifed version of the function, if it's callback is called
 * with a truthy error parameter the promise will be rejected. If the callback
 * is called with one argument, it's value will be resolved. If the callback
 * is called with multiple arguments, the promise will resolve to an array
 * of those arguments.
 *
 * @param {function} fn Function to promisify
 * @returns {function} Promise returning function
 */

export default function promisify(fn) {
  return function promisified(...args) {
    return new Promise((resolve, reject) => {
      fn(...args, (err, ...callbackArgs) => {
        if (err) {
          reject(err);
          return;
        }
        if (callbackArgs.length === 1) {
          resolve(callbackArgs[0]);
          return;
        }
        resolve(callbackArgs);
      });
    });
  };
}

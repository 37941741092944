const performance = global.performance || {};

/**
 * Returns the number of milliseconds since the the UNIX epoch
 *
 * When available (performance api exists), this function will not be skewed
 * by clock adjustments. Only use if you require this functionality, otherwise
 * use Date.now().
 *
 * @returns {number} Number of milliseconds since UNIX epoch
 */

export default function highResolutionNow() {
  if (performance.now) {
    return performance.timing.navigationStart + performance.now();
  }

  return Date.now();
}

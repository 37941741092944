import eventing from './eventing';
import isPageHideSupportedDefault from './isPageHideSupported';

function isDomReady() {
  // if there is no document, how is the document object model ready?
  return (
    typeof document === 'undefined' ||
    document.readyState === 'complete' ||
    (document.readyState === 'interactive' && document.body)
  );
}

let unloaded = false;

const domStateFactory = (deps = {}) => {
  const windowMock = deps.window || window;
  const isPageHideSupported = deps.isPageHideSupported || isPageHideSupportedDefault;

  const domState = {
    isDomLoaded: isDomReady,
    isDomUnloaded() {
      return unloaded;
    },
    whenUnloaded: new Promise((resolve) => {
      if (typeof windowMock !== 'undefined') {
        const pagehideOrUnloadHandler = () => {
          unloaded = true;
          resolve();
        };
        const pagehideOrUnload = isPageHideSupported() ? 'pagehide' : 'unload';
        windowMock.addEventListener(pagehideOrUnload, pagehideOrUnloadHandler);
      }
    }),
    whenLoaded: new Promise((resolve) => {
      if (isDomReady()) {
        resolve();
        return;
      }
      const load = () => {
        document.removeEventListener('DOMContentLoaded', load);
        document.removeEventListener('load', load);
        domState.emit('load');
        resolve();
      };
      document.addEventListener('DOMContentLoaded', load);
      document.addEventListener('load', load);
    }),
  };

  eventing(domState);

  return domState;
};

export default domStateFactory;

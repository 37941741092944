import OTHelpers from '../common-js-helpers/OTHelpers';

let value;
if (OTHelpers?.env?.name === 'Node') {
  value = '';
} else {
  // Script embed
  const scriptSrc = Array.prototype.slice.call(document.getElementsByTagName('script'))
    ?.pop()
    ?.getAttribute('src');
  const [, apiKey] = (scriptSrc && scriptSrc.match(/[?&]apikey=([^&]+)/i)) || [];

  // TODO: The indirection here is due to the need to set APIKEY in testing. We should find a better
  // solution.
  value = apiKey || '';
}

export default { value };

/*
 * Installs a watcher that will calls the given callback if the audio acquisition seems to not
 * work porperly.
 *
 * It runs every 3 seconds and poll getStats to find any sign of audio bytes sent. If it can't find
 * any it signals the anomaly by executing the given function.
 *
 * @param {function(cb: function(DOMError, Array<RTCStats>))} getStats
 *    the function to call to get the stats
 * @param {function} warningCb to be called when a potential problem is detected
 * @returns {function} cancel the watch
 */
export default function watchAudioAcquisition(getStats, warningCb) {
  let previousBytesSent = 0;
  let isCallbackNeeded = true;

  const isAudioStat = rtcStats =>
    rtcStats.type === 'outbound-rtp' &&
    rtcStats.ssrc &&
    (rtcStats.kind === 'audio' || rtcStats.mediaType === 'audio');

  const isAnomalyDetected = currentBytesSent =>
    currentBytesSent === previousBytesSent && isCallbackNeeded;

  const isAnomalyResolved = currentBytesSent =>
    currentBytesSent !== previousBytesSent;

  const handleStats = (error, stats) => {
    if (error) return;

    stats.forEach((rtcStats) => {
      if (isAudioStat(rtcStats)) {
        const statType = typeof rtcStats.bytesSent;
        let currentBytesSent;
        // Number.MAX_SAFE_INTEGER is a 16 digit number, but it is only 53 bits. We manually modulo
        // 15 digits to avoid big number problems.
        const maxDigit = 15;
        if (statType === 'number') {
          currentBytesSent = rtcStats.bytesSent % (10 ** maxDigit);
        } else if (statType === 'string') {
          const safeIntegerBytesSent =
           rtcStats.bytesSent.slice(rtcStats.bytesSent.length - maxDigit);
          currentBytesSent = parseInt(safeIntegerBytesSent, 10);
        } else {
          return;
        }

        if (isAnomalyDetected(currentBytesSent)) {
          warningCb();
          // Execute the callback only once per audio loss
          isCallbackNeeded = false;
        } else if (isAnomalyResolved(currentBytesSent)) {
          // Once resolved, we may need to use the callback in future audio loss
          isCallbackNeeded = true;
        }

        previousBytesSent = currentBytesSent;
      }
    });
  };

  const audioMonitor = setInterval(() => {
    getStats(handleStats);
  }, 3000);

  return function cancel() {
    clearInterval(audioMonitor);
  };
}

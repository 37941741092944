// Block subsequent calls to an async function until the previous call has completed
// Throws an error if you call the function too soon.
export default function blockCallsUntilComplete(fn) {
  let blocked = false;
  return async function blocker(...args) {
    if (blocked) {
      throw new Error('The previous call to this function has not yet completed.');
    }
    blocked = true;
    try {
      return await fn.call(this, ...args);
    } finally {
      blocked = false;
    }
  };
}

import eventing from '../eventing';
import guidStorage from '../guid_storage';
import Analytics from './analytics';
import SessionInfo from '../../ot/session/SessionInfo';
import staticConfigFactory from '../StaticConfig';
import getMessagingUrl from '../../common-js-helpers/get-messaging-url';
import sanitizeQosData from '../sanitizeQosData';
import defaultGetUserAgentClientHintData from './getUserAgentClientHintData';

const StaticConfig = staticConfigFactory();

const LOG_VERSION = '2';

export default class AnalyticsHelper {
  constructor({
    staticConfig = StaticConfig.onlyLocal(),
    sessionInfo = new SessionInfo(),
    ajax,
    queue,
    getUserAgentClientHintData = defaultGetUserAgentClientHintData,
    userAgentData = (typeof navigator !== 'undefined') && navigator.userAgentData,
  } = {}) {
    this.ajax = ajax;
    this.queue = queue;
    this.sessionInfo = sessionInfo;
    this.getUserAgentClientHintData = getUserAgentClientHintData;
    this.userAgentData = userAgentData;

    // setting static config invokes the setter below and instantiates Analytics
    // This must be called last.
    this.staticConfig = staticConfig;
  }

  get staticConfig() {
    return this._staticConfig;
  }

  set staticConfig(staticConfig) {
    this._staticConfig = staticConfig;
    this._analytics = new Analytics({
      loggingUrl: this.staticConfig.loggingUrl,
      ajax: this.ajax,
      queue: this.queue,
    });
  }

  async _getCachedUserAgentData() {
    if (!this.cachedUserAgentData) {
      this.cachedUserAgentData = await this.getUserAgentClientHintData(this.userAgentData);
    }
    return this.cachedUserAgentData;
  }

  commonQoSFields() {
    const { proxyUrl } = this._analytics;
    const { messagingURL } = this.sessionInfo;

    return {
      clientVersion: this.staticConfig.clientVersion,
      buildHash: this.staticConfig.buildHash,
      source: global.location && global.location.href,
      logVersion: LOG_VERSION,
      apiServer: this.staticConfig.apiUrl,
      clientSystemTime: new Date().getTime(),
      sessionId: this.sessionInfo.sessionId,
      mediaServerName: this.sessionInfo.mediaServerName,
      relayServer: this.getTurnServerName(this.sessionInfo.iceServers),
      p2p: this.sessionInfo.p2pEnabled,
      messagingServer: this.sessionInfo.messagingServer,
      messagingUrl: getMessagingUrl(proxyUrl, messagingURL),
      version: this.staticConfig.version,
      partnerId: this.sessionInfo.partnerId,
    };
  }

  async _getCommonDataFields() {
    const guid = await guidStorage.getAsync();
    const userAgentDataFields = await this._getCachedUserAgentData();
    return {
      guid,
      ...this.commonQoSFields(),
      ...userAgentDataFields,
    };
  }

  getTurnServerName = (iceServers) => {
    let turnNoTLSServerName;
    let turnTLSServerName;

    // In case we don't find the server name we will be returning nothing
    // which will be ignored and relay server will not be present in the logs
    if (!iceServers || typeof iceServers !== 'object') return turnNoTLSServerName;

    const servers = Array.isArray(iceServers) ? iceServers : [iceServers];
    servers.forEach((server) => {
      const urls = server.urls || server.url;
      const arrUrl = Array.isArray(urls) ? urls : [urls];
      arrUrl.forEach((url) => {
        // Index where server name value starts
        const prefixIndex = url.indexOf(':') + 1;
        // Index of the port number
        const suffixIndex = url.lastIndexOf(':');

        // We assume that TLS turn, i.e. turns, is the one which contains
        // the domain name.
        if (url.includes('turns')) {
          turnTLSServerName = url.substring(prefixIndex, suffixIndex);
        } else {
          turnNoTLSServerName = url.substring(prefixIndex, suffixIndex);
        }
      });
    });
    return turnTLSServerName || turnNoTLSServerName;
  }

  logError(code, type, message, details, options = {}) {
    this._getCommonDataFields().then((commonDataFields) => {
      const args = [code, type, message, details, { ...commonDataFields, ...options }];
      AnalyticsHelper.emit('logError', ...args);
      this._analytics.logError(...args);
    });
  }

  logEvent(options = {}, throttle, completionHandler) {
    this._getCommonDataFields().then((commonDataFields) => {
      const logData = { ...commonDataFields, ...options };
      AnalyticsHelper.emit('logEvent', logData);
      this._analytics.logEvent(
        logData,
        false,
        throttle,
        completionHandler
      );
    });
  }

  logQOS(options = {}) {
    this._getCommonDataFields().then((commonDataFields) => {
      const qosData = { duration: 0, ...commonDataFields, ...options };
      sanitizeQosData(qosData);
      AnalyticsHelper.emit('logQOS', qosData);
      this._analytics.logQOS(qosData);
    });
  }
}

eventing(AnalyticsHelper);

// Changing this to a import es6 module causes a circular dependency to break
// This will be addressed in VIDCS-1110
/* eslint-disable-next-line import/no-commonjs */
module.exports = AnalyticsHelper;

import pick from 'lodash/pick';

const keys = [
  'timestamp',
  'rawAudioPL',
  'rawVideoPL',
  'rawBW',
  'videoWidth',
  'videoHeight',
  'avgAudioPL',
  'avgVideoPL',
  'avgBW',
];

const createCongestionDataCsvHelper = (maxDataPoints = 10) => {
  let loggingDataPoints = [];
  return {
    addData(data) {
      loggingDataPoints.push(pick(data, keys));
      if (loggingDataPoints.length > maxDataPoints) {
        // We only keep the last 10 data points for logging
        loggingDataPoints.shift();
      }
    },
    reset() {
      loggingDataPoints = [];
    },
    getCsvString() {
      const csvData = loggingDataPoints.map((dataPoint) => {
        const data = keys.map(key => dataPoint[key]);
        return data.join(',');
      });
      return [
        keys.join(','),
        ...csvData,
      ].join('\r\n');
    },
  };
};

export default createCongestionDataCsvHelper;

// Errors titles by code
export default {
  1004: 'Authentication error',
  1005: 'Invalid Session ID',
  1006: 'Connect Failed',
  1007: 'Connect Rejected',
  1008: 'Connect Time-out',
  1009: 'Security Error',
  1010: 'Not Connected',
  1011: 'Invalid Parameter',
  1012: 'Peer-to-peer Stream Play Failed',
  1013: 'Connection Failed',
  1014: 'API Response Failure',
  1015: 'PeerConnection not connected, cannot call this method',
  1021: 'Request Timeout',
  1026: 'Terms of Service Violation: Export Compliance',
  1027: 'Connection Limit Exceeded',
  1040: 'Set Video Source Failure',
  1041: 'Invalid Video Source',
  1500: 'Unable to Publish',
  1501: 'Unable to Subscribe',
  1502: 'Unsupported Video Codec',
  1503: 'No TURN server found',
  1520: 'Unable to Force Disconnect',
  1530: 'Unable to Force Unpublish',
  1540: 'Unable to Force Mute',
  1553: 'Publisher ICEWorkflow failed',
  1554: 'Subscriber ICEWorkflow failed',
  1600: 'createOffer, createAnswer, setLocalDescription, setRemoteDescription',
  1605: 'Stream Limit Exceeded',
  2000: 'Internal Error',
  2001: 'Unexpected Server Response',
  2021: 'Proxy URL Timing Error',
  2022: 'Proxy URL Already Set Error',
  2031: 'getRtcStatsReport is not supported in this browser',
  4000: 'WebSocket Connection Failed',
  4001: 'WebSocket Network Disconnected',
  5001: 'Unable to access media engine',
};

import EventEmitter from 'events';
import createAudioFallbackVideoStateMachine from './audioFallbackVideoStateMachine';
import AudioFallbackVideoStates from './audioFallbackVideoStates';
import { LOW, MEDIUM, CRITICAL } from '../peer_connection/congestionLevel/congestionLevels';

const { ACTIVE_VIDEO, ACTIVE_VIDEO_WITH_WARNING, SUSPENDED_VIDEO } = AudioFallbackVideoStates;

const createAudioFallbackCoordinator = ({
  getAllPeerConnections,
}) => {
  const ee = new EventEmitter();

  const audioFallbackStateMachine = createAudioFallbackVideoStateMachine();
  audioFallbackStateMachine.on('stateChange', (...args) => ee.emit('stateChange', ...args));

  const calculateState = async () => {
    const pcs = await getAllPeerConnections();
    const publisherVideoState = pcs.reduce((acc, pc) =>
      Math.max(acc, pc.getAudioFallbackState()?.videoState), ACTIVE_VIDEO);
    audioFallbackStateMachine.setState(publisherVideoState);
  };

  const onPeerConnectionStateChange = ({
    state: videoState, peerConnection, publishVideo, stream, peerId,
  }) => {
    if (publishVideo) {
      const subscriberId = peerConnection.getSourceStreamId() === 'MANTIS' ? undefined : peerId;
      const setStreamChannelActiveState = ({ activeState, congestionLevel }) => {
        stream.setChannelActiveState({
          activeState,
          congestionLevel,
          channelType: 'video',
          activeReason: 'auto',
          subscriberId,
        });
      };
      switch (videoState) {
        case ACTIVE_VIDEO:
          setStreamChannelActiveState({ activeState: true, congestionLevel: LOW });
          break;
        case ACTIVE_VIDEO_WITH_WARNING:
          setStreamChannelActiveState({ activeState: true, congestionLevel: MEDIUM });
          break;
        case SUSPENDED_VIDEO:
          setStreamChannelActiveState({ activeState: false, congestionLevel: CRITICAL });
          break;
        default:
          break;
      }
    }
    calculateState();
  };

  return Object.assign(
    ee,
    {
      onPeerConnectionStateChange,
      onPeerConnectionDisconnected: () => { calculateState(); },
      reset: () => audioFallbackStateMachine.reset(),
      getState: () => audioFallbackStateMachine.getState(),
    });
};

export default createAudioFallbackCoordinator;

// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable one-var, no-mixed-operators */
import EventEmitter from 'events';
import defineProperties from '../../helpers/defineProperties';
import Widget from './behaviour/widget';
import OTHelpers from '../../common-js-helpers/OTHelpers';

export default function AudioLevelMeter(options) {
  const _audioLevelMeter = this;
  const _eventEmitter = new EventEmitter();

  let _voiceOnlyIconElement;
  let _meterValueElement;
  let _value;
  let _lastComputedVisibility;

  // display the widget by default but can be hidden when calling hideWhileLoading
  let _displayAroundLoading = true;

  let _audioOnly = false;
  let _displayMode = 'auto';

  const _maxValue = options.maxValue || 1;
  const _minValue = options.minValue || 0;

  function onCreate() {
    _meterValueElement = OTHelpers.createElement('div', {
      className: 'OT_audio-level-meter__value',
    }, '');
    _voiceOnlyIconElement = OTHelpers.createElement('div', {
      className: 'OT_audio-level-meter__audio-only-img',
    }, '');

    const domElement = _audioLevelMeter.domElement;
    domElement.appendChild(_voiceOnlyIconElement);
    domElement.appendChild(_meterValueElement);

    _audioLevelMeter.watchVisibilityChanged((visible) => {
      if (visible) {
        OTHelpers.removeClass(_audioLevelMeter.domElement, 'OT_hide-forced');
      } else {
        OTHelpers.addClass(_audioLevelMeter.domElement, 'OT_hide-forced');
      }
    });
  }

  function onDestroy() {
    _eventEmitter.removeAllListeners('visibilityChanged');
  }

  function updateView() {
    const percentSize = (_value - _minValue) / (_maxValue - _minValue);
    _meterValueElement.style.transform = `scale(${Math.sqrt(percentSize)})`;
  }

  // computes the visibility value from the different "inputs" variables and asynchronously triggers
  // the internal "visibilityChanged" events if the value changed from last time it was computed
  function computeVisibility() {
    const computedVisibility = (_audioOnly && _displayMode === 'auto' || _displayMode === 'on')
      && _displayAroundLoading;
    if (_lastComputedVisibility !== computedVisibility) {
      _lastComputedVisibility = computedVisibility;
      _eventEmitter.emit('visibilityChanged', computedVisibility);
    }
  }

  defineProperties(_audioLevelMeter, {
    audioOnly: {
      get() { return _audioOnly; },
      set(audioOnly) {
        _audioOnly = audioOnly;
        computeVisibility();
      },
    },
  });

  _audioLevelMeter.setValue = function (value) {
    _value = value;
    updateView();
  };

  /**
   * Registers an callback to be executed when the visibility of the audio level meter changes.
   * "true" means the widget is shown.
   * The contracts is that the handlers should not try to change the visibility of the widget by
   * changing the value of visibility "inputs" (setting "audioOnly", "displayMode" or calling
   * "hideWhileLoading" and "showAfterLoading")
   *
   * @param {function(boolean)} cb the callback to be executed when the display value changes.
   * The callback is also executed with the last computed value when registered.
   * @returns {function} a callback to unregister the handler
   */
  _audioLevelMeter.watchVisibilityChanged = function (cb) {
    _eventEmitter.on('visibilityChanged', cb);
    Promise.resolve().then(() => {
      cb(_lastComputedVisibility);
    });
    return function stopWatching() {
      _eventEmitter.removeListener('visibilityChanged', cb);
    };
  };

  // Mixin common widget behaviour
  const widgetOptions = {
    mode: options ? options.mode : 'auto',
    nodeName: 'div',
    htmlAttributes: {
      className: 'OT_audio-level-meter',
    },
    onCreate,
    onDestroy,
  };

  Widget(this, widgetOptions);

  // The methods underneath are mixed in by "Widget" but overridden
  // Doing so, we can bypass it and compute the display value ourselves without relying on CSS
  _audioLevelMeter.setDisplayMode = function (mode) {
    _displayMode = mode;
    computeVisibility();
  };

  _audioLevelMeter.getDisplayMode = function () {
    return _displayMode;
  };

  _audioLevelMeter.showAfterLoading = function () {
    _displayAroundLoading = true;
    computeVisibility();
  };

  _audioLevelMeter.hideWhileLoading = function () {
    _displayAroundLoading = false;
    computeVisibility();
  };

  // compute the initial visibility value
  computeVisibility();
}

let currentAudioInputDevice = '';

const setCurrentAudioInputDevice = (newDevice) => {
  currentAudioInputDevice = newDevice;
};

const getCurrentAudioInputDevice = () => currentAudioInputDevice;

export default {
  getCurrentAudioInputDevice,
  setCurrentAudioInputDevice,
};

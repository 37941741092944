import createVideoElementUsingNativeAPI from './createVideoElementUsingNativeAPI';

const MAX_VIDEO_ELEMENTS = 20;
const videoElements = [];

const sizeOfBuffer = () => videoElements.length;

const fillBuffer = () => {
  while (sizeOfBuffer() < MAX_VIDEO_ELEMENTS) {
    const videoElement = createVideoElementUsingNativeAPI();

    // This is an async method but we don't await because it's not required.  Reason
    // being, we just want to register the `play` request so that it'll inherit
    // the autoplay permission that is/was granted.
    videoElement
      .play()
      .catch(() => {
        // We intentional suppress the exception, since we just want the request to
        // be queued up.
      });

    // Since we don't care about awaiting for `play()`, we immediately add the
    // element.
    videoElements.push(videoElement);
  }
};

const getNext = () => videoElements.shift();

// Useful for testing
const clearBuffer = () => {
  videoElements.length = 0;
};

export {
  fillBuffer,
  getNext,
  sizeOfBuffer,
  clearBuffer,
  MAX_VIDEO_ELEMENTS,
};

import EventEmitter from 'events';
import createNetworkProbeDefault from './networkProbe';
import { LOW } from '../congestionLevels';
import getDelayForProbeNumberDefault from './getDelayForProbeNumber';

const createNetworkQualityMonitor = (deps = {}) => {
  const ee = new EventEmitter();
  const {
    getStats,
    setVideoActive,
    createNetworkProbe = createNetworkProbeDefault,
  } = deps;

  let networkProbe;
  let timeoutId;

  const stop = () => {
    networkProbe?.removeAllListeners();
    clearTimeout(timeoutId);
    networkProbe?.stop();
    networkProbe = undefined;
  };

  const start = ({ getDelayForProbeNumber = getDelayForProbeNumberDefault,
  } = {}) => {
    if (networkProbe) {
      // ensure we never run more than one instance at the same time
      stop();
    }
    networkProbe = createNetworkProbe({ getStats });
    let probeCount = 0;

    const runProbeWithDelay = () => {
      const delay = getDelayForProbeNumber(probeCount);
      probeCount++;
      timeoutId = setTimeout(() => {
        setVideoActive(true);
        networkProbe.start();
      }, delay);
    };

    networkProbe.on('probeResult', (congestionLevel) => {
      if (congestionLevel === LOW) {
        ee.emit('networkRecovered');
      } else {
        setVideoActive(false);
        runProbeWithDelay();
      }
    });
    runProbeWithDelay();
  };

  return Object.assign(ee, {
    start,
    stop,
  });
};

export default createNetworkQualityMonitor;

import env from '../helpers/env';

/*
the capture screen share with audio is only avaible for Chrome >= 74 & Chromium Edge >= 79, see
https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getDisplayMedia#browser_compatibility

Also note that capture audio is only avaible for the user via the checkmark in tab selection
*/

export default Boolean((env.isChrome && env.version >= 74) || (env.isEdge && env.version >= 79));

/* eslint-disable global-require */
import lodashOnce from 'lodash/once';
import createLogger from '../helpers/log';
import hasGetUserMediaCapabilityDefault from './hasGetUserMediaCapability';
import staticConfigFactory from './StaticConfig';
import envDefault from '../helpers/env';
import hasPeerConnectionCapabilityDefault from './hasPeerConnectionCapability';

const StaticConfig = staticConfigFactory();

// Indicates whether this client supports WebRTC
//
// This is defined as: getUserMedia + PeerConnection + exceeds min browser version
//
const check = (deps = {}) => {
  const env = deps.env || envDefault;
  const hasGetUserMediaCapability =
    deps.hasGetUserMediaCapability || hasGetUserMediaCapabilityDefault;
  const hasPeerConnectionCapability =
    deps.hasPeerConnectionCapability || hasPeerConnectionCapabilityDefault;
  const logging = deps.logging || createLogger('hasOpenTokSupport');
  /** @type StaticConfig */
  const staticConfig = deps.staticConfig || StaticConfig.onlyLocal();
  const minimumVersions = staticConfig.minimumVersion || {};
  const minimumVersion = minimumVersions[env.name.toLowerCase()];

  if (minimumVersion && minimumVersion > env.version) {
    logging.debug('Support for', env.name, 'is disabled because we require',
      minimumVersion, 'but this is', env.version);
    return false;
  }

  if (env.name === 'Node') {
    // Node works, even though it doesn't have getUserMedia
    return true;
  }

  return hasGetUserMediaCapability() && hasPeerConnectionCapability();
};

const once = lodashOnce(() => check());

export default {
  check,
  once,
};

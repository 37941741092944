import StaticConfigFactory from './StaticConfig';

const StaticConfig = StaticConfigFactory();

const isEnvironment = (environmentUrl, deps) => {
  const config = deps.StaticConfig || StaticConfig.onlyLocal();
  const { apiUrl } = config;
  return apiUrl.includes(environmentUrl);
};

export const isProd = (deps = {}) => isEnvironment('anvil.opentok.com', deps);

export const isDev = (deps = {}) => isEnvironment('api.dev.opentok.com', deps);

export const isRel = (deps = {}) => isEnvironment('api.rel.opentok.com', deps);

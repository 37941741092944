import SDPHelpers from '../sdp_helpers';

/**
 * Reconstructs the media section array for the full remote offer SDP from mantis
 * from a partial offer by combining it with the current remote description
 * @param {ParsedSdp} partialRemoteOffer
 * @param {ParsedSdp} currentRemoteDescription
 * @returns SDP in string format
 */
const getMediaSectionsForOffer = (partialRemoteOffer, currentRemoteDescription) => {
  const currentMediaSections = currentRemoteDescription.mediaSections.map(section => [...section]);
  // m-line recycling:  Iterate through current media sections and add new media sections into disabled slots
  // if not disabled slots then append media section to array
  let currentMediaSectionIndex = 0;
  partialRemoteOffer.mediaSections.forEach((mediaSection) => {
    for (currentMediaSectionIndex;
      currentMediaSectionIndex < currentMediaSections.length;
      currentMediaSectionIndex++) {
      const currentMediaSection = currentMediaSections[currentMediaSectionIndex];
      if (SDPHelpers.isDisabledMediaSection(currentMediaSection)) {
        currentMediaSections[currentMediaSectionIndex] = mediaSection;
        return;
      }
    }
    currentMediaSections.push(mediaSection);
  });

  return currentMediaSections;
};

export default getMediaSectionsForOffer;

// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable prefer-rest-params, no-cond-assign */

const DelayedEventQueue = function DelayedEventQueue(eventDispatcher) {
  const queue = [];

  this.enqueue = function enqueue(/* arg1, arg2, ..., argN */) {
    queue.push(Array.prototype.slice.call(arguments));
  };

  this.triggerAll = function triggerAll() {
    let event;

    // Array.prototype.shift is actually pretty inefficient for longer Arrays,
    // this is because after the first element is removed it reshuffles every
    // remaining element up one (1). This involves way too many allocations and
    // deallocations as the queue size increases.
    //
    // A more efficient version could be written by keeping an index to the current
    // 'first' element in the Array and increasing that by one whenever an element
    // is removed. The elements that are infront of the index have been 'deleted'.
    // Periodically the front of the Array could be spliced off to reclaim the space.
    //
    // 1. http://www.ecma-international.org/ecma-262/5.1/#sec-15.4.4.9
    //
    //
    // TLDR: Array.prototype.shift is O(n), where n is the array length,
    // instead of the expected O(1). You can implement your own shift that runs
    // in amortised constant time.
    //
    // @todo benchmark and see if we should actually care about shift's performance
    // for our common queue sizes.
    //
    while ((event = queue.shift())) {
      eventDispatcher.trigger(...event);
    }
  };
};

export default DelayedEventQueue;

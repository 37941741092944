import getShouldUseStandardGetStats from '../../helpers/shouldUseStandardGetStats';

const shouldUseStandardGetStats = getShouldUseStandardGetStats();

const getRtcStats = (peerConnection, track, completion) => {
  peerConnection.getStats(track)
    .then(stats => completion(null, stats))
    .catch(completion);
};

const getRtcStatsLegacy = (peerConnection, completion) => {
  peerConnection.getStats((stats) => {
    completion(null, stats);
  },
  completion);
};

// This returns the native or "raw" RtcStatsReport object
const getRtcStatsReportAdapter = (peerConnection, track, completion) => {
  if (shouldUseStandardGetStats) {
    getRtcStats(peerConnection, track, completion);
  } else {
    getRtcStatsLegacy(peerConnection, completion);
  }
};

export default getRtcStatsReportAdapter;

import envDefault from '../env';
import createVideoElementUsingNativeAPIDefault from './createVideoElementUsingNativeAPI';
import createVideoElementForSafariDefault from './createVideoElementForSafari';

// Tries to return an always autoplayable video element
const createVideoElement = (deps = {}) => {
  // The mocked `env` can be set to `undefined` to test some edge case(s), hence
  // the use of `hasOwnProperty` instead of the other patterns below.
  // eslint-disable-next-line no-prototype-builtins
  const env = deps.hasOwnProperty('env')
    ? deps.env
    : envDefault;
  const createVideoElementUsingNativeAPI =
    deps.createVideoElementUsingNativeAPI || createVideoElementUsingNativeAPIDefault;
  const createVideoElementForSafari =
    deps.createVideoElementForSafari || createVideoElementForSafariDefault;

  let videoElement;

  if (env?.isSafari) {
    // See VIDCS-1523
    // Safari requires special handling to ensure that the video element is
    // autoplayable.
    videoElement = createVideoElementForSafari();
  } else {
    // For most browsers, once any video element is granted autoplay permission, then
    // all video elements in the execution context inherit the same permissions.  This
    // is true when if the video element was created after the browser tab/window
    // loses focus and was backgrounded.
    videoElement = createVideoElementUsingNativeAPI();
  }

  videoElement.autoplay = true;
  videoElement.playsinline = true;

  // Safari on iOS requires setAttribute OPENTOK-37229
  videoElement.setAttribute('autoplay', '');
  videoElement.setAttribute('playsinline', '');

  return videoElement;
};

export default createVideoElement;

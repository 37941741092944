const createCircleElement = () => {
  const circle = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'circle'
  );
  circle.setAttribute('cx', '50%');
  circle.setAttribute('cy', '50%');
  circle.setAttribute('r', '50%');
  circle.setAttribute('fill', '%23000000');
  return circle;
};

const createTextElement = (initials) => {
  const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
  text.setAttribute('x', '50%');
  text.setAttribute('y', '50%');
  text.setAttribute('dy', '5%');
  text.setAttribute('line-height', '133%');
  text.setAttribute('font-size', '108');
  text.setAttribute('font-weight', '500');
  text.setAttribute('font-style', 'normal');
  text.setAttribute('fill', '%23FFFFFF');
  text.setAttribute('font-family', 'spezia,sans-serif');
  text.setAttribute('dominant-baseline', 'middle');
  text.setAttribute('text-anchor', 'middle');
  const textNode = document.createTextNode(initials);
  text.appendChild(textNode);
  return text;
};

export default (initials) => {
  const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  svg.setAttribute('viewBox', '0 0 300 300');
  svg.setAttribute(
    'style',
    'width: 50%; dominant-baseline: middle; text-anchor: middle;'
  );

  const circle = createCircleElement();
  const text = createTextElement(initials);

  svg.appendChild(circle);
  svg.appendChild(text);
  return svg;
};

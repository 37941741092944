// Values taken from 'fairQualityBitrate': https://github.com/opentok/chef-rumor/blob/master/attributes/default.rb
const fairQualityBitrateForResolution = [
  { width: '320', height: '180', fairQualityBitrate: 120000 },
  { width: '320', height: '240', fairQualityBitrate: 120000 },
  { width: '352', height: '288', fairQualityBitrate: 125000 },
  { width: '480', height: '270', fairQualityBitrate: 125000 },
  { width: '640', height: '360', fairQualityBitrate: 150000 },
  { width: '640', height: '480', fairQualityBitrate: 150000 },
  { width: '960', height: '540', fairQualityBitrate: 150000 },
  { width: '1280', height: '720', fairQualityBitrate: 400000 },
  { width: '1920', height: '1080', fairQualityBitrate: 500000 },
].sort((a, b) => a.fairQualityBitrate - b.fairQualityBitrate); // ensure sorted by desc bitrate

// https://github.com/opentok/chef-rumor/blob/master/attributes/default.rb
const defaultFairQualityBitrate = 250000;
const maxQualityBitrate = fairQualityBitrateForResolution.reduce(
  (currentMax, { fairQualityBitrate }) =>
    Math.max(currentMax, fairQualityBitrate), defaultFairQualityBitrate);

const getFairQualityBandwidthForResolution = ({ width: videoWidth, height: videoHeight }) => {
  if (!videoWidth || !videoHeight) return defaultFairQualityBitrate;

  // Step function returns upper limit fair bitrate for resolution area
  // using lookup table as input data.
  const bitrate = fairQualityBitrateForResolution.find(({ height, width }) =>
    (videoHeight * videoWidth <= height * width))?.fairQualityBitrate;

  return bitrate || maxQualityBitrate;
};

export default getFairQualityBandwidthForResolution;

import { ONE_SECOND_IN_MS } from '../../../../common-js-helpers/time';
import getDelayForProbeNumber from './getDelayForProbeNumber';

const getDelayForAmrProbe = (probeNumber) => {
  if (probeNumber === 0) {
    return 5 * ONE_SECOND_IN_MS;
  }
  return getDelayForProbeNumber(probeNumber - 1);
};

export default getDelayForAmrProbe;

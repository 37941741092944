import SFrameClientDefault from './standardSFrameClient';

export default function SFrameClientStoreFactory(
  { ...deps } = {}
) {
  let sframe;
  const createClient = async senderId => sframe.create(senderId);

  const SFrameClientStore = function SFrameClientStore() {
    this._clientStore = {};
    sframe = deps.sframe || SFrameClientDefault;

    // Useful for unit tests
    this._initClientStore = (clientStore) => {
      this._clientStore = clientStore;
    };

    this.createSender = async (senderId = 0) => {
      if (this.getSender(senderId)) {
        return this.getSender(senderId);
      }

      if (!this._clientStore[senderId]) {
        this._clientStore[senderId] = {};
      }

      try {
        this._clientStore[senderId].sender = await createClient(senderId);
      } catch (err) {
        delete this._clientStore[senderId]?.sender;
      }

      return this.getSender(senderId);
    };

    this.createReceiver = async (senderId) => {
      if (this.getReceiver(senderId)) {
        return this.getReceiver(senderId);
      }

      if (!this._clientStore[senderId]) {
        this._clientStore[senderId] = {};
      }

      try {
        this._clientStore[senderId].receiver = await createClient(senderId);
      } catch (err) {
        delete this._clientStore[senderId]?.receiver;
      }

      return this.getReceiver(senderId);
    };

    this.getSender = senderId =>
      this._clientStore[senderId]?.sender;

    this.getReceiver = senderId =>
      this._clientStore[senderId]?.receiver;

    this.deleteReceiver = (senderId) => {
      delete this._clientStore[senderId]?.receiver;
    };

    this.deleteSender = (senderId) => {
      delete this._clientStore[senderId]?.sender;
    };
  };

  return SFrameClientStore;
}

import uuid from 'uuid';

export default function getOrCreateContainer(elementOrDomId, insertMode) {
  /** @type {HTMLElement} */
  let container;
  let domId;

  if (elementOrDomId && elementOrDomId.nodeName) {
    // It looks like we were given a DOM element. Grab the id or generate
    // one if it doesn't have one.
    container = elementOrDomId;
    if (!container.getAttribute('id') || container.getAttribute('id').length === 0) {
      container.setAttribute('id', `OT_${uuid()}`);
    }

    domId = container.getAttribute('id');
  } else if (elementOrDomId) {
    // We may have got an id, try and get it's DOM element.
    container = document.getElementById(elementOrDomId);
    if (container) { domId = elementOrDomId; }
  }

  if (!domId) {
    domId = `OT_${uuid().replace(/-/g, '_')}`;
  }

  if (!container) {
    container = document.createElement('div');
    container.id = domId;
    container.setAttribute('id', domId);
    container.style.backgroundColor = '#000000';
    document.body.appendChild(container);
    return container;
  }

  const placeHolder = document.createElement('div');
  placeHolder.id = `OT_${uuid()}`;

  switch (insertMode) {
    case 'append':
      container.appendChild(placeHolder);
      return placeHolder;
    case 'before':
      container.parentNode.insertBefore(placeHolder, container);
      return placeHolder;
    case 'after':
      container.parentNode.insertBefore(placeHolder, container.nextSibling);
      return placeHolder;
    case 'replace':
    default:
      while (container.firstChild) {
        container.removeChild(container.firstChild);
      }
      return container;
  }
}

export default ({ connectivityState, onWarning, onTimeout, warningMs, timeoutMs }) => {
  const timeoutIds = [];

  const cancelBindGuards = () => {
    timeoutIds.forEach(timeoutId => clearTimeout(timeoutId));
  };

  connectivityState.observe({
    onEnterConnecting() {
      timeoutIds.push(
        setTimeout(onWarning, warningMs),
        setTimeout(onTimeout, timeoutMs)
      );
    },
    onEnterConnected: cancelBindGuards,
    onEnterDisconnected: cancelBindGuards,
  });
};

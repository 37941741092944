import ExceptionCodes from '../exception_codes';

const statusCodeToExceptionCode = {
  400: ExceptionCodes.INVALID_SESSION_ID,
  403: ExceptionCodes.AUTHENTICATION_ERROR,
  404: ExceptionCodes.INVALID_SESSION_ID,
  409: ExceptionCodes.TERMS_OF_SERVICE_FAILURE,
  500: ExceptionCodes.UNEXPECTED_SERVER_RESPONSE,
};

/**
 * Maps an Anvil status code error to an exception code
 * @param {number} code - Anvil status code
 * @return {string|undefined} Exception code
 */
function mapStatusCodeToErrorCode(code) {
  return statusCodeToExceptionCode[code];
}

export default mapStatusCodeToErrorCode;

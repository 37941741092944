import deviceHelpers from '../helpers/deviceHelpers';
import audioOutputDevicesChangeHandlerFactory from './audioOutputDevicesChangeHandler';

const audioOutputDevicesChangeHandler = audioOutputDevicesChangeHandlerFactory();
const {
  hasAudioOutputApiSupport,
  getNativeMediaDevices,
} = deviceHelpers();

export default () => {
  // In order to keep the current device ID synchronized, let's listen to the device change event
  if (hasAudioOutputApiSupport()) {
    const mediaDevices = getNativeMediaDevices();
    let isProcessing = false;
    mediaDevices.addEventListener('devicechange', async () => {
      if (isProcessing) {
        return;
      }
      isProcessing = true;
      await audioOutputDevicesChangeHandler();
      isProcessing = false;
    });
  }
};

import Widget from './behaviour/widget';
import OTHelpers from '../../common-js-helpers/OTHelpers';

export default function VideoUnsupportedIndicator(options) {
  let videoUnsupported = false;

  const updateClasses = (element) => {
    const shouldDisplay = ['auto', 'on'].indexOf(this.getDisplayMode()) > -1;

    OTHelpers.removeClass(element, [
      'OT_active',
    ].join(' '));

    if (!shouldDisplay || !videoUnsupported) {
      return;
    }

    OTHelpers.addClass(element, 'OT_active');
  };

  this.setVideoUnsupported = (value) => {
    videoUnsupported = value;
    updateClasses(this.domElement);
  };

  // Mixin common widget behaviour
  Widget(this, {
    mode: options.mode || 'auto',
    nodeName: 'div',
    htmlAttributes: {
      className: 'OT_video-unsupported',
    },
    htmlContent: (
      '<div class="OT_video-unsupported-bar"></div>' +
      '<div class="OT_video-unsupported-img"></div>' +
      '<span class="OT_video-unsupported-text">' +
      'Video format not supported' +
      '</span>'
    ),
  });

  const parentSetDisplayMode = this.setDisplayMode.bind(this);
  this.setDisplayMode = (mode) => {
    parentSetDisplayMode(mode);
    updateClasses(this.domElement);
  };
}

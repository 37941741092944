// Normalizes CrytoKey by either passing it through (e.g., if already an array
// buffer or an invalid type) or casts it to an array buffer
const normalizeKey = async (key) => {
  if (!(key instanceof window?.CryptoKey)) {
    return key;
  }

  const exportedKey = await window?.crypto.subtle.exportKey('raw', key);

  return new Uint8Array(exportedKey);
};

export default normalizeKey;

import IntervalRunner from '../ot/interval_runner';

const connectivityAttemptPingerFactory = ({ connectivityState, logAttempt }) => {
  const connectivityAttemptPinger = new IntervalRunner(logAttempt, 1 / 5, 6);

  connectivityState.observe({
    onEnterConnecting() {
      connectivityAttemptPinger.start();
    },
    onEnterDisconnected() {
      connectivityAttemptPinger.stop();
    },
    onEnterConnected() {
      connectivityAttemptPinger.stop();
    },
  });
};

export default connectivityAttemptPingerFactory;

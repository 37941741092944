import promisify from '../../helpers/promisify';

const collectStatsHelper = async (options = {}, callback) => {
  const { tracks, getStats, merge } = options;

  const getStatsP = promisify(getStats);
  let statsMerged;
  try {
    const statsArray = await Promise.all(tracks.map(track => getStatsP(track)));
    statsMerged = statsArray.reduce(merge);
  } catch (error) {
    callback(error);
    return;
  }
  callback(null, statsMerged);
};

const collectGetStatsHelper = (peerConnection, tracks, callback) => {
  // getStats uses an Array for the collected stats.
  const merge = (previous = [], stats) => [...previous, ...stats];
  collectStatsHelper({
    tracks,
    getStats: (track, completion) => peerConnection.getStats(track, completion),
    merge,
  }, callback);
};

const collectRtcStatsReportHelper = (peerConnection, tracks, callback) => {
  // getRtcStatsReport uses an RTCStatsReport object that cannot be merged
  // so we convert it to a Map
  const mapMerge = (previous = new Map(), stats) => new Map([...previous, ...stats]);

  collectStatsHelper({
    tracks,
    getStats: (track, completion) => peerConnection.getRtcStatsReport(track, completion),
    merge: mapMerge,
  }, callback);
};

export { collectStatsHelper, collectRtcStatsReportHelper, collectGetStatsHelper };

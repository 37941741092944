const isDomElement = (el) => {
  if (!el || typeof el.getBoundingClientRect !== 'function') {
    return false;
  }
  return true;
};

// Check if the element is partially in the ViewPort
const isInViewPort = (el) => {
  const rect = el.getBoundingClientRect();
  const windowHeight = window.innerHeight || document.documentElement.clientHeight;
  const windowWidth = window.innerWidth || document.documentElement.clientWidth;
  const vertInView = rect.top <= windowHeight && (rect.top + rect.height) >= 0;
  const horInView = rect.left <= windowWidth && (rect.left + rect.width) >= 0;

  return vertInView && horInView;
};

// Check whether the element or its DOM parents are hidden
// either by display='none' or visibility='hidden'
const isVisible = (domElement) => {
  const isVisibilityHidden = (el) => {
    if (el.style && el.style.visibility === 'hidden') {
      return true;
    }

    // Check if parent has visibility=hidden
    if (el.parentNode) {
      return isVisibilityHidden(el.parentNode);
    }

    return false;
  };

  return domElement.offsetParent !== null && !isVisibilityHidden(domElement);
};

/** Returns whether the HTMLDomElement is visibe or not */
export default domElement =>
  isDomElement(domElement) && isInViewPort(domElement) && isVisible(domElement);

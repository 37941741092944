import pick from 'lodash/pick';
import mapValues from 'lodash/mapValues';
import isObject from 'lodash/isObject';
import calculateFrameRate from './calculate-frame-rate';

const frameRateTrackers = {};

function getFrameRate(stat) {
  return Number(stat.framerateMean || stat.googFrameRateSent || stat.googFrameRateReceived ||
    stat.googFrameRateInput || stat.googFrameRateOutput || 0);
}

function getFrames(stat) {
  return Number(stat.framesEncoded || stat.framesDecoded);
}

function calcFrameRate(stat, startTime) {
  if (getFrameRate(stat)) {
    return getFrameRate(stat);
  }

  if (!getFrames(stat)) {
    return undefined;
  }

  let frameRate = 0;
  const prevStatFrames = frameRateTrackers[stat.id] ? frameRateTrackers[stat.id].frames : 0;
  const prevTimestamp = frameRateTrackers[stat.id] ?
    frameRateTrackers[stat.id].timestamp : startTime;

  frameRate = calculateFrameRate({
    currentStatFrames: getFrames(stat),
    currentTimestamp: stat.timestamp,
    prevStatFrames,
    prevTimestamp,
  });

  frameRateTrackers[stat.id] = {
    frames: getFrames(stat),
    timestamp: stat.timestamp,
  };

  return frameRate;
}

function getLinkedTrack(stat, allStats) {
  if (!allStats) { return {}; }
  const linkedTrack = allStats.filter(x => x.id === stat.mediaTrackId);
  return linkedTrack.length ? linkedTrack[0] : {};
}

function totalPacketsLostReducer(totalPacketsLost, stat) {
  if ('packetsLost' in stat) {
    return totalPacketsLost + stat.packetsLost;
  }
  return totalPacketsLost;
}

const getStatsHelpers = {
  isVideoStat(stat, allStats) {
    const linkedTrack = getLinkedTrack(stat, allStats);
    // When kind is 'video', we need to check the type since there are other
    // stats with kind 'video' without the information we need.
    const isVideoKind = stat.kind === 'video' &&
      ['outbound-rtp', 'inbound-rtp'].includes(stat.type);

    return isVideoKind || stat.mediaType === 'video' ||
      'googFrameWidthReceived' in stat || // Old Chrome
      'googFrameWidthInput' in stat || // Old Chrome
      (stat.type === 'inbound-rtp' && stat.id.indexOf('Video') !== -1) || // Safari
      (stat.type === 'inboundrtp' && linkedTrack.frameWidth !== 0); // Edge
  },
  isAudioStat(stat, allStats) {
    const linkedTrack = getLinkedTrack(stat, allStats);
    // When kind is 'audio', we need to check the type since there are other
    // stats with kind 'audio' without the information we need.
    const isAudioKind = stat.kind === 'audio' &&
      ['outbound-rtp', 'inbound-rtp'].includes(stat.type);

    return isAudioKind || stat.mediaType === 'audio' ||
      'audioInputLevel' in stat || // Old Chrome
      'audioOutputLevel' in stat || // Old Chrome
      (stat.type === 'inbound-rtp' && stat.id.indexOf('Audio') !== -1) || // Safari
      (stat.type === 'inboundrtp' && linkedTrack.frameWidth === 0); // Edge;
  },
  isInboundStat(stat) {
    return 'bytesReceived' in stat || 'packetsReceived' in stat;
  },
  isOutboundStat(stat) {
    return 'bytesSent' in stat || 'packetsSent' in stat;
  },
  isVideoTrackStat(stat) {
    return stat.type === 'track' && (
      stat.frameHeight > 0 ||
      stat.frameWidth > 0 ||
      stat.framesCorrupted > 0 ||
      stat.framesDecoded > 0 ||
      stat.framesPerSecond > 0 ||
      stat.framesSent > 0 ||
      stat.framesReceived > 0
    );
  },
  isVideoRemoteInboundRtpStat(stat) {
    return stat.type === 'remote-inbound-rtp' && stat.kind === 'video';
  },
  isAudioRemoteInboundRtpStat(stat) {
    return stat.type === 'remote-inbound-rtp' && stat.kind === 'audio';
  },
  isVideoInboundRtpStat(stat) {
    return stat.type === 'inbound-rtp' && stat.kind === 'video';
  },
  parseStatCategory(stat) {
    const statCategory = {
      packetsLost: 0,
      packetsReceived: 0,
      bytesReceived: 0,
      // frameRate is only set for video stat
    };

    if ('packetsReceived' in stat) {
      statCategory.packetsReceived = parseInt(stat.packetsReceived, 10);
    }
    if ('packetsLost' in stat) {
      statCategory.packetsLost = parseInt(stat.packetsLost, 10);
    }
    if ('bytesReceived' in stat) {
      statCategory.bytesReceived = parseInt(stat.bytesReceived, 10);
    }

    if (this.isVideoStat(stat)) {
      if ('framerateMean' in stat) {
        statCategory.frameRate = Number(stat.framerateMean);
      } else if ('googFrameRateOutput' in stat) {
        statCategory.frameRate = Number(stat.googFrameRateOutput);
      } else if ('googFrameRateInput' in stat) {
        statCategory.frameRate = Number(stat.googFrameRateInput);
      }
    }

    return statCategory;
  },
  getVideoPacketsLost(stats) {
    return stats
      .filter(stat => this.isVideoRemoteInboundRtpStat(stat, stats))
      .reduce(totalPacketsLostReducer, 0);
  },
  getAudioPacketsLost(stats) {
    return stats
      .filter(stat => this.isAudioRemoteInboundRtpStat(stat, stats))
      .reduce(totalPacketsLostReducer, 0);
  },
  normalizeTimestamp(timestamp, now = Date.now()) {
    if (isObject(timestamp) && 'getTime' in timestamp) {
      // Chrome as of 39 delivers a "kind of Date" object for timestamps
      // we duck check it and get the timestamp
      return timestamp.getTime();
    }

    if (Math.abs((timestamp / (1000 * now)) - 1) < 0.05) {
      // If the timestamp is within 5% of 1000x now, we assume its unit is microseconds and
      // divide by 1000 to correct for this.
      return timestamp / 1000;
    }

    return timestamp;
  },

  normalizeStats(stats, inbound = true, startTime) {
    const videos = stats
      .filter(stat => getStatsHelpers.isVideoStat(stat, stats))
      .filter(stat => getStatsHelpers.isInboundStat(stat) === inbound);
    const audio = stats
      .filter(stat => this.isAudioStat(stat, stats))
      .filter(stat => this.isInboundStat(stat) === inbound)[0];

    // There may be more than one video stream (e.g., when simulcast is enabled)
    // We just need to grab one of them for its id and timestamp
    const video = videos[0];

    if (!audio && !video) {
      throw new Error('could not find stats for either audio or video');
    }

    const timestamp = getStatsHelpers.normalizeTimestamp(
      video ? video.timestamp : audio.timestamp);
    const otStats = {
      timestamp,
    };

    if (video && !inbound && video.packetsLost === undefined) {
      video.packetsLost = this.getVideoPacketsLost(stats);
    }

    if (audio && !inbound && audio.packetsLost === undefined) {
      audio.packetsLost = this.getAudioPacketsLost(stats);
    }

    const outboundStatNames = ['packetsSent', 'packetsLost', 'bytesSent'];
    const getOutboundStats = stat => pick(stat, outboundStatNames);
    const getInboundStats = stat => pick(stat, ['packetsReceived', 'packetsLost', 'bytesReceived']);
    const getCommonStats = inbound ? getInboundStats : getOutboundStats;

    const accumulateVideoStats = (videoArray) => {
      // We want to accumulate all these stats, if they exist.
      const frameStats = ['framerateMean', 'googFrameRateSent', 'googFrameRateReceived',
        'googFrameRateInput', 'googFrameRateOutput', 'framesEncoded', 'framesDecoded'];
      const outboundStatNamesWithFrames = outboundStatNames.concat(frameStats);

      const accumulatedVideo = {
        timestamp,
        id: video.id,
      };

      outboundStatNamesWithFrames.forEach((statName) => {
        accumulatedVideo[statName] = videoArray.map(x => Number(x[statName] || 0))
          .reduce((accumulator, stat) => accumulator + stat, 0);
      });

      return accumulatedVideo;
    };

    if (videos && videos.length > 0) {
      const accumulatedVideo = accumulateVideoStats(videos);

      otStats.video = Object.assign(
        { frameRate: calcFrameRate(accumulatedVideo, startTime) },
        mapValues(getCommonStats(accumulatedVideo), Number)
      );
    }

    if (audio) {
      otStats.audio = mapValues(getCommonStats(audio), Number);
    }

    return otStats;
  },
};

export default getStatsHelpers;

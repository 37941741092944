/**
 * castToBoolean
 *
 * @param {any} The value to cast
 * @returns {Boolean} True if value === 'true' or value === true, otherwise false.
 */

const castToBoolean = (value, defaultValue = false) => (
  value === undefined ? defaultValue : value === 'true' || value === true
);

export default castToBoolean;

import * as socketCloseCodes from '../socketCloseCodes';
import errors from '../../ot/Errors';

export default class SocketError {
  code;
  message;
  name;

  constructor(code) {
    this.code = socketCloseCodes.codes.CLOSE_FALLBACK_CODE;
    if (Object.values(socketCloseCodes.codes).includes(code)) {
      this.code = code;

      switch (this.code) {
        case socketCloseCodes.codes.CLOSE_GOING_AWAY:
        case socketCloseCodes.codes.CLOSE_PROTOCOL_ERROR:
        case socketCloseCodes.codes.CLOSE_UNSUPPORTED:
        case socketCloseCodes.codes.CLOSE_NO_STATUS:
        case socketCloseCodes.codes.CLOSE_ABNORMAL:
        case socketCloseCodes.codes.CLOSE_INCONSISTENT_DATA:
        case socketCloseCodes.codes.CLOSE_POLICY_VIOLATION:
        case socketCloseCodes.codes.CLOSE_TOO_LARGE:
        case socketCloseCodes.codes.CLOSE_UNEXPECTED_CONDITION:
        case socketCloseCodes.codes.CLOSE_CONNECTIVITY_LOSS:
        case socketCloseCodes.codes.CLOSE_CONNECT_EXCEPTION:
        case socketCloseCodes.codes.CLOSE_TIMEOUT:
          this.name = errors.DISCONNECTED;
          this.message = 'Action failed due to session disconnection.';
          return;

        default:
          break;
      }
    }
    this.message = socketCloseCodes.messages[this.code] || 'No message available from code.';
  }
}

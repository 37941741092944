export default (audioTrack, deps = {}) => {
  const globalDocument = deps.document || document;
  const MediaStream = deps.MediaStream || global.MediaStream;

  const audioTrackHolder = globalDocument.createElement('video');
  const audioStream = new MediaStream([audioTrack]);
  audioTrackHolder.srcObject = audioStream;

  return audioTrackHolder;
};

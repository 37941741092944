import EventEmitter from 'events';
import createBandwidthCongestionLevelDefault from '../congestionLevelCalculators/bandwidthCongestionLevel';
import createCongestionLevelStatsDefault from '../congestionLevelStats';
import createMovingAverageTrackerDefault from '../exponentialMovingAverageTracker';
import { LOW } from '../congestionLevels';

const createRecoveryCongestionLevelCalculator = (deps = {}) => {
  const ee = new EventEmitter();
  const {
    getStats,
    createMovingAverageTracker = createMovingAverageTrackerDefault,
    createBandwidthCongestionLevel = createBandwidthCongestionLevelDefault,
    createCongestionLevelStats = createCongestionLevelStatsDefault,
  } = deps;

  const bandwidthCongestionLevel = createBandwidthCongestionLevel();
  const audioPacketLossStats = createMovingAverageTracker();
  const videoPacketLossStats = createMovingAverageTracker();
  const congestionLevelStats = createCongestionLevelStats(getStats);

  const resetStats = () => {
    audioPacketLossStats.reset();
    videoPacketLossStats.reset();
  };

  const stopStatsCalculation = () => {
    congestionLevelStats.stop();
    resetStats();
  };

  const getCongestionLevel = () => {
    if (videoPacketLossStats.getDataPointCount() === 0
    || audioPacketLossStats.getDataPointCount() === 0) {
      return LOW;
    }
    return bandwidthCongestionLevel.getLevel({
      audioPacketLoss: audioPacketLossStats.getMovingAverageValue(),
      videoPacketLoss: videoPacketLossStats.getMovingAverageValue(),
    });
  };

  const onStatsAvailable = ({ audioPacketLoss, videoPacketLoss }) => {
    audioPacketLossStats.addValue(audioPacketLoss);
    videoPacketLossStats.addValue(videoPacketLoss);
    const congestionLevel = getCongestionLevel();
    ee.emit('congestionLevel', congestionLevel);
  };

  congestionLevelStats.on('statsAvailable', onStatsAvailable);

  return Object.assign(ee, {
    start() {
      congestionLevelStats.start();
    },
    stop() {
      stopStatsCalculation();
    },
  });
};

export default createRecoveryCongestionLevelCalculator;

const logLevels = {
  error: { priority: 1, method: 'error' },
  warn: { priority: 2, method: 'warn' },
  info: { priority: 3, method: 'info' },
  log: { priority: 4, method: 'log' },
  debug: { priority: 5, method: 'debug' },
  spam: { priority: 6, method: 'log' },
};

export default logLevels;

/* eslint-disable global-require */
import envDefault from '../helpers/env';
import AnalyticsHelper from '../helpers/analytics';

export default (deps = {}) => {
  const env = deps.env || envDefault;
  const analytics = deps.analytics || new AnalyticsHelper();

  analytics.logEvent({
    action: 'UserAgentParsing',
    variation: 'Attempt',
    payload: {
      userAgent: env.userAgent,
    },
  });

  analytics.logEvent({
    action: 'UserAgentParsing',
    variation: env.error ? 'Failure' : 'Success',
    payload: {
      userAgent: env.userAgent,
    },
  });
};

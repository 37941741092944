// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-param-reassign, global-require, one-var, prefer-const */
/* eslint-disable no-restricted-syntax, no-prototype-builtins, no-continue */
import assign from 'lodash/assign';
import isObject from 'lodash/isObject';
import eventing from '../helpers/eventing';
import createLogger from '../helpers/log';
import OTErrorClass from '../ot/ot_error_class';
import promiseDelay from './promiseDelay';
import trackUsage from './trackUsage';
import AnalyticsHelper from './analytics';
import envDefault from './env';
import errorsDefault from '../ot/Errors';
import createOTError from './otError';
import createScreenSharing from '../ot/screensharing/screen_sharing';
import getDisplayMediaExtensionHelperDefault from '../ot/screensharing/getDisplayMedia_extension_helper';

export default function getUserMediaFactory(deps = {}) {
  const env = deps.env || envDefault;
  const Errors = deps.Errors || errorsDefault;
  const otError = deps.otError || createOTError();
  const logging = deps.logging || createLogger('getUserMedia');
  const screenSharing = deps.screenSharing || createScreenSharing();
  const getDisplayMediaExtensionHelper = deps.getDisplayMediaExtensionHelper
  || getDisplayMediaExtensionHelperDefault;
  const customGetUserMedia = deps.customGetUserMedia;
  const windowMock = deps.global || global;
  /** @type {AnalyticsHelper} */
  const analytics = deps.analytics || new AnalyticsHelper();

  const { navigator, location } = windowMock;

  const getGetUserMediaFn = () => {
    if (customGetUserMedia) {
      return customGetUserMedia;
    }
    if (navigator.mediaDevices) {
      return ::navigator.mediaDevices.getUserMedia;
    }
    return undefined;
  };

  const getUserMedia = (...args) => {
    const getUserMediaFn = getGetUserMediaFn();
    if (getUserMediaFn) {
      return getUserMediaFn(...args);
    }
    return Promise.reject(new Error('Cannot find getUserMedia'));
  };

  const getGetDisplayMediaFn = () => {
    if (customGetUserMedia) {
      return customGetUserMedia;
    }
    if (navigator.mediaDevices && navigator.getDisplayMedia) {
      return ::navigator.getDisplayMedia;
    }
    if (navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia) {
      return ::navigator.mediaDevices.getDisplayMedia;
    }
    return undefined;
  };

  const getDisplayMedia = (...args) => {
    const getDisplayMediaFn = getGetDisplayMediaFn();
    if (getDisplayMediaFn) {
      return getDisplayMediaFn(...args);
    }
    return Promise.reject(new Error('Cannot find getDisplayMedia'));
  };

  const vendorToOTErrorDetails = (() => {
    const userMediaAccessDenied = {
      name: Errors.USER_MEDIA_ACCESS_DENIED,
      message: 'End-user denied permission to hardware devices',
    };

    const notSupported = {
      name: Errors.NOT_SUPPORTED,
      message: 'A constraint specified is not supported by the browser.',
    };

    const constraintsNotSatisfied = {
      name: Errors.CONSTRAINTS_NOT_SATISFIED,
      message: 'It\'s not possible to satisfy one or more constraints ' +
        'passed into the getUserMedia function',
    };

    const noDevicesFound = {
      name: Errors.NO_DEVICES_FOUND,
      message: 'No voice or video input devices are available on this machine.',
    };

    const hardwareUnavailable = {
      name: Errors.HARDWARE_UNAVAILABLE,
      message: 'The selected voice or video devices are unavailable. Verify ' +
        'that the chosen devices are not in use by another application.',
    };

    const screenCaptureError = {
      name: Errors.UNABLE_TO_CAPTURE_SCREEN,
      message: 'Unable to capture screen. Unknown error occurred',
    };

    const abortError = {
      name: Errors.UNABLE_TO_CAPTURE_MEDIA,
      message: 'Unable to capture media. Unknown error occurred',
    };

    // Mozilla error strings and the equivalent W3C names. NOT_SUPPORTED_ERROR does not
    // exist in the spec right now, so we'll include Mozilla's error description.
    // Chrome TrackStartError is triggered when the camera is already used by another app (Windows)
    const errorMap = {
      // Spec:
      AbortError: abortError,
      NotAllowedError: userMediaAccessDenied,
      NotFoundError: noDevicesFound,
      NotReadableError: hardwareUnavailable,
      OverconstrainedError: constraintsNotSatisfied,
      SecurityError: assign({}, userMediaAccessDenied, {
        message: 'End-user denied permission to hardware devices or user media' +
          ' support may be disabled on this page',
      }),
      TypeError: constraintsNotSatisfied,

      // Old errors, probably not used anymore. @todo log usage to be removed.
      PermissionDeniedError: userMediaAccessDenied,
      PermissionDismissedError: userMediaAccessDenied,
      NotSupportedError: notSupported,
      ConstraintNotSatisfiedError: constraintsNotSatisfied,
      MandatoryUnsatisfiedError: constraintsNotSatisfied,
      OverConstrainedError: constraintsNotSatisfied,
      DevicesNotFoundError: noDevicesFound,
      NoDevicesFoundError: noDevicesFound,
      HardwareUnavailableError: hardwareUnavailable,
      SourceUnavailableError: hardwareUnavailable,
      TrackStartError: hardwareUnavailable,
      ScreenCaptureError: screenCaptureError,
      TabCaptureError: screenCaptureError,
    };

    // track usage of the following keys so we know if they are still being used
    // and by which browsers/ operating systems.
    trackUsage({
      objectToTrack: errorMap,
      keys: [
        'PermissionDeniedError',
        'PermissionDismissedError',
        'NotSupportedError',
        'ConstraintNotSatisfiedError',
        'MandatoryUnsatisfiedError',
        'OverConstrainedError',
        'DevicesNotFoundError',
        'NoDevicesFoundError',
        'HardwareUnavailableError',
        'SourceUnavailableError',
        'TrackStartError',
        'ScreenCaptureError',
        'TabCaptureError',
      ],
      onUsage(key) {
        analytics.logEvent({
          action: 'DeprecatedPropertyUsage:getUserMedia:errorMap',
          variation: 'Event',
          payload: { key },
        });
      },
    });

    return errorMap;
  })();

  // Map vendor error strings to errors we emit
  const errorFromVendorNameAndMessage = (vendorErrorName, message) => {
    let name = vendorErrorName;
    if (
      name === 'AbortError' &&
      typeof message === 'string' &&
      message.match(/(screen|tab).?capture/i)
    ) {
      name = 'ScreenCaptureError';
    }
    const otErrorDetails = vendorToOTErrorDetails[name];

    let error;
    if (otErrorDetails) {
      error = otError(
        otErrorDetails.name,
        new Error(`${otErrorDetails.message} (getUserMedia error: ${vendorErrorName})`)
      );
    } else {
      error = new Error(`Unknown error while getting user media: ${vendorErrorName}`);
    }
    error.originalMessage = message;
    error.originalName = vendorErrorName;

    return error;
  };

  // Parse and normalise a getUserMedia error event from Chrome or Mozilla
  // @ref http://dev.w3.org/2011/webrtc/editor/getusermedia.html#idl-def-NavigatorUserMediaError
  const parseErrorEvent = (event) => {
    if (isObject(event) && event.name) {
      let { name, message } = event;

      if (message === 'Cannot find getUserMedia' || message === 'Cannot find getDisplayMedia') {
        if (env.name === 'Chrome' && env.version > 73 && windowMock.location.protocol !== 'https:') {
          name = 'NotAllowedError';
        } else {
          name = 'NotSupportedError';
        }
      }

      // Errors in Safari have incorrect name property
      // See https://bugs.webkit.org/show_bug.cgi?id=180777
      if (env.name === 'Safari' && name === 'Error') {
        const protoName = Object.prototype.toString.call(event);
        const matches = protoName.match(/^\[object (\w+Error)\]$/);
        if (matches) {
          name = matches[1];
        }
      }

      const error = errorFromVendorNameAndMessage(name, message);
      error.constraintName = event.constraint || event.constraintName;

      if (!(error instanceof OTErrorClass)) {
        analytics.logEvent({
          action: 'UnexpectedError:getUserMedia:parseErrorEvent',
          variation: 'Event',
          payload: {
            name: error.name,
            message: error.message,
            originalMessage: error.originalMessage,
          },
        });
      }

      return error;
    }

    if (typeof event === 'string') {
      const error = errorFromVendorNameAndMessage(event);
      analytics.logEvent({
        action: 'CheckUnreachable:getUserMedia:parseErrorEvent',
        variation: 'Event',
        payload: {
          info: 'error is string',
          error: event,
        },
      });
      return error;
    }

    analytics.logEvent({
      action: 'CheckUnreachable:getUserMedia:parseErrorEvent',
      variation: 'Event',
      payload: {
        info: 'error was not string or object with name property',
        error: event,
      },
    });

    return new Error('Unknown error type while getting media');
  };

  // Validates a Hash of getUserMedia constraints. Currently we only
  // check to see if there is at least one non-false constraint.
  const areInvalidConstraints = function (constraints) {
    if (!constraints || !isObject(constraints)) { return true; }

    for (const key in constraints) {
      if (!constraints.hasOwnProperty(key)) {
        continue;
      }
      if (constraints[key]) { return false; }
    }

    return true;
  };

  // A wrapper for the builtin navigator.getUserMedia. In addition to the usual
  // getUserMedia behaviour, this helper method also accepts a accessDialogOpened
  // and accessDialogClosed callback.
  //
  // @memberof OTHelpers
  // @private
  //
  // @param {Object} constraints
  //      A dictionary of constraints to pass to getUserMedia. See
  //      http://dev.w3.org/2011/webrtc/editor/getusermedia.html#idl-def-MediaStreamConstraints
  //      in the Media Capture and Streams spec for more info.
  //
  // @param {function} success
  //      Called when getUserMedia completes successfully. The callback will be passed a WebRTC
  //      Stream object.
  //
  // @param {function} failure
  //      Called when getUserMedia fails to access a user stream. It will be passed an object
  //      with a code property representing the error that occurred.
  //
  // @param {function} accessDialogOpened
  //      Called when the access allow/deny dialog is opened.
  //
  // @param {function} accessDialogClosed
  //      Called when the access allow/deny dialog is closed.
  //
  // @param {function} accessDenied
  //      Called when access is denied to the camera/mic. This will be either because
  //      the user has clicked deny or because a particular origin is permanently denied.
  //
  return (constraints, isScreenSharing = false) => {
    logging.debug('getUserMedia wrapper called with', constraints);

    if (areInvalidConstraints(constraints)) {
      // All constraints are false, we don't allow this. This may be valid later
      // depending on how/if we integrate data channels.
      logging.error('Couldn\'t get UserMedia: All constraints were false');

      return eventing(Promise.reject(otError(
        Errors.NO_VALID_CONSTRAINTS,
        new Error('Video and Audio was disabled, you need to enable at least one')
      )));
    }

    const getMedia = (isScreenSharing && getDisplayMediaExtensionHelper.isSupportedInThisBrowser) ?
      getDisplayMedia : getUserMedia;

    const throttledRequest = eventing(getMedia(constraints)
      .catch((browserError) => {
        // This also restores bluebird's ability to check we handle the error since we ignored
        // it when creating `finalized` above.
        const error = parseErrorEvent(browserError);

        if (error.name === Errors.UNABLE_TO_CAPTURE_SCREEN) {
          return new Promise((resolve, reject) => {
            screenSharing.checkCapability((response) => {
              if (response.extensionRequired === 'chrome') {
                if (response.extensionRegistered && !response.extensionInstalled) {
                  const newErr = otError(Errors.SCREEN_SHARING_EXTENSION_NOT_INSTALLED, error);
                  newErr.message = 'Screen sharing extension not installed see https://tokbox.com/developer/guides/screen-sharing/js/#chrome-extension';
                  reject(newErr);
                } else if (!response.extensionRegistered) {
                  const newErr = otError(Errors.SCREEN_SHARING_EXTENSION_NOT_REGISTERED, error);
                  newErr.message = 'Screen sharing extension not registered see https://tokbox.com/developer/guides/screen-sharing/js/#chrome-extension';
                  reject(newErr);
                }
              } else {
                reject(error);
              }
            });
          });
        }
        throw error;
      })
      .then((stream) => {
        logging.debug('got stream:', {
          stream,
          tracks: stream.getTracks(),
          trackLabels: stream.getTracks().map(track => track.label),
        });

        return stream;
      }));

    // The 'remember me' functionality of WebRTC only functions over HTTPS, if
    // we aren't on HTTPS then we should definitely be displaying the access
    // dialog.
    //
    // If we are on HTTPS, we'll wait 500ms to see if we get a stream
    // immediately. If we do then the user had clicked 'remember me'. Otherwise
    // we assume that the accessAllowed dialog is visible.
    //
    // Otherwise, we still wait 100ms to give the client a chance to bind to the
    // event.
    //
    // @todo benchmark and see if 500ms is a reasonable number. It seems like
    // we should know a lot quicker.
    //
    const dialogOpenedDelay = location.protocol.indexOf('https') !== -1 ? 500 : 100;

    let waitingForStream = true;
    const setWaitingForStreamFalse = () => { waitingForStream = false; };
    throttledRequest.then(setWaitingForStreamFalse, setWaitingForStreamFalse);

    promiseDelay(dialogOpenedDelay)
      .then(() => {
        if (!waitingForStream) {
          return;
        }

        const closeDialog = () => { throttledRequest.emit('accessDialogClosed'); };
        throttledRequest.emit('accessDialogOpened');
        throttledRequest.then(closeDialog, closeDialog);
      });

    return throttledRequest;
  };
}

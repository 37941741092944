export default (session) => {
  if (!session) {
    // Logs must be tagged to the API key, which we'll only know after we
    // connect to a session.
    return false;
  }

  // Logs must be linked the the API
  return !!session.apiKey;
};

import createLogger from '../../../helpers/log';

const logging = createLogger('partialSdp');
/**
 * creates a partialSdp object with header and first mediaSection only
 * @param {ParsedSdp} offer - new remote offer generated by PC
 * @returns partialSdp containing new header and first mediaSection only
 */
const getPartialIceRestartSdpOffer = (offer) => {
  logging.debug('full offer: ', offer);
  const headMid = offer.mids.find(mid => mid !== null);
  const headMediaSection = offer.mediaSections.find((section) => {
    const mediaSectionString = section.join('');
    const isHead = mediaSectionString.includes(`a=mid:${headMid}`);
    return isHead;
  });
  const partialOffer = {
    headers: offer.headers,
    mediaSections: [headMediaSection],
  };
  logging.debug('partial offer: ', partialOffer);
  return partialOffer;
};

export default getPartialIceRestartSdpOffer;

import debug from 'debug';
import memoize from 'lodash/memoize';
import logLevels from './logLevels';

/**
 * @typedef {Object} Logger
 * @property {Function} error
 * @property {Function} warn
 * @property {Function} info
 * @property {Function} log
 * @property {Function} debug
 * @property {Function} spam
 */

const createLogger = memoize((namespace) => {
  /** @type Logger */
  const API = {};

  const setMethods = () => {
    Object.keys(logLevels).forEach((name) => {
      const method = logLevels[name].method;
      const log = debug(`OpenTok:${namespace}:${name.toLowerCase()}`);
      log.log = console[method].bind(console); // eslint-disable-line no-console
      API[name.toLowerCase()] = log;
    });
  };

  setMethods();

  return API;
});

createLogger.setLogLevel = (level) => {
  let oldRules;
  try {
    oldRules = global.localStorage.debug;
  } catch (err) {
    // will get Uncaught DOMException: Failed to read the 'localStorage' property from 'Window':
    // The document is sandboxed and lacks the 'allow-same-origin' flag.
    oldRules = '';
  }
  const newDebugRules = Object.keys(logLevels)
    .map(name =>
      `${level >= logLevels[name].priority ? '' : '-'}(autogen)?OpenTok:*:${name.toLowerCase()}`
    );

  const debugStr = [
    ...newDebugRules,
    ...(oldRules || '').split(/[\s,]/)
      .filter(rule => rule.indexOf('(autogen)?') === -1),
  ].filter(x => x).join(',');

  debug.enable(debugStr);
};

export default createLogger;

const removeItem = (array, itemToRemove) => {
  array.forEach((item, index) => {
    const itemId = item.id || item;
    const itemToRemoveId = itemToRemove.id || itemToRemove;
    if (itemId === itemToRemoveId) {
      array.splice(index, 1);
    }
  });
};

export default removeItem;

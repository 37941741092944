const createExponentialMovingAverageTracker = (initialValue = 0) => {
  let movingAverageValue = initialValue;
  let period = 10000;
  let lastSampleTime;
  let dataPointCount = 0;

  const getExponentialMovingAverageWeight = () => {
    const currentTime = Date.now();

    if (!lastSampleTime) {
      lastSampleTime = currentTime;
      return 1;
    }

    const diff = currentTime - lastSampleTime;
    const expWeight = 1 - Math.exp(-diff / period);
    lastSampleTime = currentTime;
    return expWeight;
  };

  return {
    setPeriod(time) {
      period = time;
    },
    getMovingAverageValue() { return movingAverageValue; },
    addValue(value) {
      if (value === undefined || isNaN(value)) return;

      const expWeight = getExponentialMovingAverageWeight();
      movingAverageValue = (value * expWeight) + (movingAverageValue * (1 - expWeight));
      dataPointCount += 1;
    },
    reset() {
      movingAverageValue = initialValue;
      lastSampleTime = undefined;
      dataPointCount = 0;
    },
    getDataPointCount() {
      return dataPointCount;
    },
  };
};

export default createExponentialMovingAverageTracker;

import promiseFinally from 'promise-finally';
import pTimeout from 'p-timeout';
import eventHelper from './eventHelper';
import VideoElementFacadeFactory from './video_element/index';

// eslint-disable-next-line no-unused-vars
const typeVideoElementFacade = VideoElementFacadeFactory();

/**
 * Returns a Promise that will resolve when the video resolution is greater than
 * 2 in either dimension, or a timeout occurs.
 *
 * @param {typeVideoElementFacade} videoElementFacade
 * @param {Number} timeoutMs
 *
 * @returns Promise<Boolean> true if resolution succeeded, false otherwise
 */
function waitForCorrectResolution(
  videoElementFacade,
  timeoutMs
) {
  const videoElementFacadeEvents = eventHelper(videoElementFacade);

  const promise = new Promise((resolve) => {
    const checkResolution = () => {
      if (videoElementFacade.videoWidth() > 2 || videoElementFacade.videoHeight() > 2) {
        resolve(true);
      }
    };
    videoElementFacadeEvents.on('videoDimensionsChanged', checkResolution);
    checkResolution();
  });

  return promiseFinally(
    pTimeout(promise, timeoutMs, () => false),
    () => videoElementFacadeEvents.removeAll()
  );
}

export default waitForCorrectResolution;

import ExceptionCodes from '../ot/exception_codes';
import Errors from '../ot/Errors';
import getOtError from './otError';

const otError = getOtError();

export default (error) => {
  // @todo Can we provide more specific errors for these codes? Are these still the only
  // codes that we expect?
  // Error codes: https://github.com/opentok/rumor-reloaded/blob/develop/rumor/src/main/java/com/tokbox/symphony/control/ErrorResponses.java
  const expectedErrorCodes = [
    '403', // FORBIDDEN ERRORS: forbiddenConnection
    '404', // NOT FOUND ERRORS: streamNotFound
    '409', // CONFLICT ERRORS: streamLimitExceeded
  ];

  const errorCodeStr = String(error.code);

  if (errorCodeStr === '405' && error.message === 'IncompatibleEncryptionSettings') {
    return otError(Errors.STREAM_CREATE_FAILED,
      new Error('IncompatibleEncryptionSettings: Tried to publish a stream to an encrypted session without enabling encryption'),
      ExceptionCodes.UNABLE_TO_PUBLISH
    );
  }

  return expectedErrorCodes.includes(errorCodeStr) ?
    otError(Errors.STREAM_CREATE_FAILED,
      new Error(`Failed to create stream in server model: ${error.message}`),
      ExceptionCodes.UNABLE_TO_PUBLISH
    ) :
    otError(
      Errors.UNEXPECTED_SERVER_RESPONSE,
      new Error(`Unexpected server response: ${error.message}`),
      ExceptionCodes.UNEXPECTED_SERVER_RESPONSE
    );
};

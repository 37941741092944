import webrtcAdapter from 'webrtc-adapter/src/js/adapter_factory';
import createLogger from '../helpers/log';
import extendES5Native from '../helpers/extendES5Native';
import env from '../helpers/env';

const logging = createLogger('createWindowMock');

const windowKeys = [
  { key: 'location', type: 'object' },
  { key: 'setTimeout', type: 'function' },
  { key: 'requestAnimationFrame', type: 'function' },
  { key: 'URL', type: 'function' },
  { key: 'MediaStream', type: 'class' },
  { key: 'webkitMediaStream', type: 'class' },
  { key: 'RTCIceCandidate', type: 'class' },
  { key: 'mozRTCIceCandidate', type: 'class' },
  { key: 'RTCSessionDescription', type: 'class' },
  { key: 'mozRTCSessionDescription', type: 'class' },
  { key: 'RTCIceGatherer', type: 'class' },
  { key: 'RTCIceTransport', type: 'class' },
  { key: 'RTCDtlsTransport', type: 'class' },
  { key: 'RTCSctpTransport', type: 'class' },
  { key: 'RTCRtpReceiver', type: 'class' },
  { key: 'HTMLMediaElement', type: 'class' },
  { key: 'RTCPeerConnection', type: 'class' },
  { key: 'webkitRTCPeerConnection', type: 'class' },
  { key: 'mozRTCPeerConnection', type: 'class' },
  { key: 'MediaStreamTrack', type: 'class' },
  { key: 'RTCRtpSender', type: 'class' },
  { key: 'RTCTrackEvent', type: 'class' },
  { key: 'RTCTransceiver', type: 'class' },
  { key: 'RTCDtmfSender', type: 'class' },
  { key: 'RTCDTMFSender', type: 'class' },
  { key: 'MediaStreamTrackEvent', type: 'class' },
];

const navigatorKeys = [
  { key: 'userAgent', type: 'string' },
  { key: 'getUserMedia', type: 'function' },
  { key: 'getDisplayMedia', type: 'function' },
  { key: 'webkitGetUserMedia', type: 'function' },
  { key: 'mozGetUserMedia', type: 'function' },
];

const mediaDevicesKeys = [
  { key: 'getUserMedia', type: 'function' },
  { key: 'getDisplayMedia', type: 'function' },
  { key: 'enumerateDevices', type: 'function' },
  { key: 'getSupportedConstraints', type: 'function' },
  { key: 'addEventListener', type: 'function' },
  { key: 'removeEventListener', type: 'function' },
];

const bindWithStaticProperties = (context, func) => {
  if (typeof func !== 'function') {
    logging.warn('Non-function passed into bindWithStaticProperties()');
    return func;
  }

  const bound = func.bind(context);
  Object.keys(func)
    .forEach((key) => {
      bound[key] = func[key];
    });
  return bound;
};

const extendClass = (ParentClass) => {
  if (typeof ParentClass !== 'function') {
    logging.warn('Non-function passed into extendClass()');
    return ParentClass;
  }

  return extendES5Native(ParentClass);
};

const getCopyProperties = () => (target, source, keys) => {
  keys.forEach(({ key, type }) => {
    let value = source[key];
    if (value !== undefined) {
      switch (type) {
        case 'function':
          value = bindWithStaticProperties(source, value);
          break;

        case 'class':
          value = extendClass(value);
          break;

        case 'string':
        case 'object':
          break;

        default:
          throw new Error(`Invalid type of window key: ${type}`);
      }
      target[key] = value; // eslint-disable-line no-param-reassign
    }
  });
};

const cloneWindow = (window) => {
  const windowMock = {};

  const copyProperties = getCopyProperties();

  copyProperties(windowMock, window, windowKeys);

  if (window.navigator !== undefined) {
    windowMock.navigator = {};

    copyProperties(windowMock.navigator, window.navigator, navigatorKeys);

    if (window.navigator.mediaDevices !== undefined) {
      windowMock.navigator.mediaDevices = {};

      copyProperties(
        windowMock.navigator.mediaDevices,
        window.navigator.mediaDevices,
        mediaDevicesKeys
      );
    }
  }

  return windowMock;
};

export default function createWindowMock(window) {
  // We avoid shimming twice because adapter is not idempotent.
  // Unfortunately checking for window.adapter can be used to detect
  // adapter.js but not adapter_no_global.js, there is no reliable
  // way of detecting it.
  if (window.adapter !== undefined || env.isLegacyEdge) {
    return window;
  }

  const windowMock = cloneWindow(window);
  webrtcAdapter({ window: windowMock });
  return windowMock;
}

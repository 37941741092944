const minSecretLength = 8;
const maxSecretLength = 256;

const validateSecret = (secret) => {
  if (typeof secret !== 'string') {
    throw new Error('secret must be a string');
  } else if (secret.length < minSecretLength) {
    throw new Error('secret must have at least 8 characters');
  } else if (secret.length > maxSecretLength) {
    throw new Error('secret must not exceed 256 characters');
  }
};

export default validateSecret;

import EventEmitter from 'events';

import createCounter from '../createCounter';
import logging from '../../helpers/log';
import ReconnectableSocketFactory from '../ReconnectableSocket';
import RumorSocketFactory from '../rumor/RumorSocket';
import WebSocketImport from './WebSocket';
import AnalyticsHelper from '../../helpers/analytics';

export default function defaultRumorSocket({ WebSocket = WebSocketImport } = {}) {
  const allocateId = createCounter();

  const ReconnectableSocket = ReconnectableSocketFactory({
    AnalyticsHelper,
    EventEmitter,
    WebSocket,
    logging,
    allocateId,
  });

  const RumorSocket = RumorSocketFactory({
    EventEmitter,
    ReconnectableSocket,
    logging,
    allocateId,
  });

  return RumorSocket;
}

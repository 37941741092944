/* eslint-disable no-underscore-dangle */
const finalTaskDelay = 100;

const createSubscriberPeerConnectionQueue = () => {
  const queue = [];
  let isProcessing = false;
  let timeoutId;
  let finalTask;

  const processFinalTaskWithDelay = () => {
    timeoutId = setTimeout(() => {
      timeoutId = undefined;
      isProcessing = true;
      finalTask();
      finalTask = undefined;
    }, finalTaskDelay);
  };

  const enqueue = (task) => {
    if (isProcessing) {
      queue.push(task);
    } else {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      isProcessing = true;
      task();
    }
  };

  const finallyTask = (task) => {
    finalTask = task;
    if (isProcessing) {
      return;
    }

    if (timeoutId) {
      // do nothing, we have updated the finalTask but we need not extend the time
      return;
    }
    processFinalTaskWithDelay();
  };

  const dequeueAndProcessNext = () => {
    const nextProcess = queue.shift();
    if (nextProcess) {
      nextProcess();
    } else {
      if (finalTask) {
        processFinalTaskWithDelay();
      }
      isProcessing = false;
    }
  };

  return {
    enqueue,
    finally: finallyTask,
    dequeueAndProcessNext,
  };
};

export default createSubscriberPeerConnectionQueue;

import createVideoElementUsingNativeAPIDefault from './createVideoElementUsingNativeAPI';
import * as videoElementBufferDefault from './videoElementBuffer';

// VIDCS-1523: tries to create an video element that's autoplayable
// despite the application losing focus.
// This approach is similar to what Youenn from Apple suggested.
// See https://bugs.webkit.org/show_bug.cgi?id=219318#c7
const createVideoElementForSafari = (deps = {}) => {
  const documentMock = deps.document || document;
  const videoElementBuffer = deps.videoElementBuffer || videoElementBufferDefault;
  const createVideoElementUsingNativeAPI =
    deps.createVideoElementUsingNativeAPI || createVideoElementUsingNativeAPIDefault;

  if (documentMock.hasFocus()) {
    // We create and/or top off the buffer only when we have focus, so that the video
    // elements will autoplay.
    videoElementBuffer.fillBuffer();

    // The buffer of autoplayable elements is reserved for when the
    // application doesn't have focus.  That's why we return dynamically
    // create elements instead.
    return createVideoElementUsingNativeAPI();
  }

  // Since we don't have focus, if we create a video element using
  // native APIs, then Safari will deny autoplay.  To mitigate, we
  // *try* to get one from the autoplayable bucket of video elements.
  return videoElementBuffer.getNext() ||
    createVideoElementUsingNativeAPI();
};

export default createVideoElementForSafari;

/* eslint-disable no-param-reassign */
import isScreenSharingSource from './isScreenSharingSource';

const supportsScalableScreenShare = properties => !!properties?.scalableScreenshare;

export default (properties) => {
  if (!properties) {
    properties = {};
  }

  const isScreenshare = !!isScreenSharingSource(properties?.videoSource);

  // eslint-disable-next-line no-unused-expressions
  properties.capableSimulcastScreenshare = isScreenshare && supportsScalableScreenShare(properties);
};

import env from '../../helpers/env';
import screenSharingSources from './screenSharingSources';
import isElectronScreenSharingSource from '../screensharing/is_electron_screen_sharing_source';

export default (videoSource) => {
  if (env.isElectron) {
    // Electron only supports a subset of `screenSharingSources` available on
    // other user agents
    if (videoSource === 'screen') {
      return true;
    }

    // Checks if this video source is a custom screensharing source
    return isElectronScreenSharingSource(videoSource);
  }

  return screenSharingSources.indexOf(videoSource) > -1;
};

import url from 'url';
import defaultAxios from 'axios';
import promiseEndeavour from 'promise-endeavour';
import get from 'lodash/get';
import assign from 'lodash/assign';
import ExceptionCodes from '../exception_codes';
import SessionInfo from './SessionInfo';
import mapErrorCodeToName from './mapErrorCodeToName';
import mapStatusCodeToErrorCode from './mapStatusCodeToErrorCode';

const LANGUAGE = {
  EMPTY_RESPONSE: 'Response body was empty, probably due to connectivity loss',
  BADLY_FORMED: 'Unknown error: JSON response was badly formed',
  NO_ERROR: 'No error message',
  UNEXPECTED_SERVER_RESPONSE: details =>
    `Unexpected server response (${details}). Try this operation again later.`,
};

const RETRY_DELAYS = [0, 600, 1200];

/**
 * @typedef {Object} getSessionInfoConfig Configuration
 * @property {String} anvilUrl The base anvil url
 * @property {String} sessionId The id of the session to retrieve info for
 * @property {String} token The token used for authentication
 * @property {String} clientVersion The client version passed to Anvil
 * @property {String} connectionId The connection id passed to Anvil
 *
 * @typedef {Object} getSessionDependencies
 * @property {String=} axios
 */

/**
 * Factory for injecting dependencies into and returning a getSessionInfo function
 *
 * @param {getSessionDependencies} dependencies
 * @returns {getSessionInfo}
 */
function getSessionInfoFactory({ axios = defaultAxios, retryDelays = RETRY_DELAYS } = {}) {
  /**
   * getSessionInfo - Fetch session details from Anvil and return a SessionInfo object
   *
   * @function getSessionInfo
   * @param {getSessionInfoConfig} config
   * @returns {Promise<SessionInfo>} The session info
   */
  function getSessionInfo(/** @type getSessionInfoConfig */ {
    anvilUrl,
    sessionId,
    token,
    clientVersion,
    connectionId,
  }) {
    const parts = url.parse(anvilUrl, true);
    const targetUrl = url.format({
      protocol: parts.protocol,
      auth: parts.auth,
      host: parts.host,
      pathname: url.resolve((parts.pathname || '').replace(/\/?$/, '/'), `session/${sessionId}`),
      query: { ...parts.query, extended: true },
    });

    return axios.get(targetUrl, {
      validateStatus: status => status >= 200 && status < 300,
      headers: {
        'X-OPENTOK-AUTH': token,
        'X-TB-VERSION': 1,
        'X-TB-CLIENT-VERSION': clientVersion,
        'X-TB-CONNECTIONID': connectionId,
        Accept: 'application/json',
      },
    })
      // general http connectivity and status errors from axios
      .catch((error) => {
        if (error.response) {
          const newError = new Error(LANGUAGE.UNEXPECTED_SERVER_RESPONSE(
            `${error.response.statusCode}${error.response.status ? ` ${error.response.status}` : ''}`
          ));
          newError.code = ExceptionCodes.ANVIL_INVALID_HTTP_STATUS;
          newError.name = mapErrorCodeToName(newError.code);
          throw newError;
        } else if (error.request) {
          const newError = new Error(LANGUAGE.CONNECT_FAILED);
          newError.code = ExceptionCodes.ANVIL_CONNECT_FAILED;
          newError.name = mapErrorCodeToName(newError.code);
          throw newError;
        } else {
          const code = ExceptionCodes.ANVIL_UNKNOWN_HTTP_ERROR;
          const name = mapErrorCodeToName(error.code);
          assign(error, { code, name });
          throw error;
        }
      })
      .then(
        ({ data }) => {
          if (typeof data === 'string') { // axios could not decode JSON
            if (data.length > 0) {
              try {
                JSON.parse(data);
              } catch (err) {
                err.message = LANGUAGE.UNEXPECTED_SERVER_RESPONSE(err.toString());
                err.code = ExceptionCodes.ANVIL_XDOMAIN_OR_PARSING_ERROR;
                err.name = mapErrorCodeToName(err.code);
                throw err;
              }
            }
            const error = new Error(
              data.length === 0 ? LANGUAGE.EMPTY_RESPONSE : LANGUAGE.BADLY_FORMED
            );
            error.code = data.length === 0 ?
              ExceptionCodes.ANVIL_EMPTY_RESPONSE_BODY :
              ExceptionCodes.ANVIL_BADLY_FORMED_RESPONSE;
            error.name = mapErrorCodeToName(error.code);
            throw error;
          }

          if (data === null || data.length === 0) { // anvil returned empty session info
            const error = new Error(LANGUAGE.EMPTY_RESPONSE);
            error.code = ExceptionCodes.ANVIL_EMPTY_RESPONSE_BODY;
            error.name = mapErrorCodeToName(error.code);
            throw error;
          }

          const [errorNode] = data.filter(node => node.error != null);

          if (errorNode) {
            const code = mapStatusCodeToErrorCode(get(errorNode, 'error.code', '500'));

            if (get(errorNode, 'error.errorMessage.message') === 'Invalid token format') {
              errorNode.error.errorMessage.message += ` Token: ${token}`;
            }

            const error = new Error(
              get(errorNode, 'error.errorMessage.message', code === undefined ?
                `Unknown error: ${errorNode.error.code}` : LANGUAGE.NO_ERROR)
            );
            error.code = code || ExceptionCodes.ANVIL_UNEXPECTED_ERROR_CODE;
            error.name = mapErrorCodeToName(error.code);
            throw error;
          }
          return new SessionInfo(data[0]);
        }
      );
  }

  const getSessionInfoRetry = promiseEndeavour(
    getSessionInfo,
    (error, attempt) =>
      error.code && error.code >= 3000 && error.code <= 3007 && retryDelays[attempt]
  );

  return getSessionInfoRetry;
}

export default getSessionInfoFactory;

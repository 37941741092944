import Cancellation from 'cancel';

export const createCancellationFactory = () => {
  let cancellation = new Cancellation();
  return () => {
    cancellation.cancel();
    cancellation = new Cancellation();
    return cancellation;
  };
};

export const callWithCancellation = (fn) => {
  const getCancellation = createCancellationFactory();
  return (...args) => {
    const cancellation = getCancellation();
    return fn(...args, cancellation);
  };
};

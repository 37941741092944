/**
 * Calculates the video framerate based on current and last frames,
 * and current and last stat timestamp.
 *
 * @typedef {Object} videoFrameStatValues
 * @property {Number} currentStatFrames Frames taken from current stats.
 * @property {Number} currentTimestamp Timestamp taken from current stats.
 * @property {Number} prevStatFrames Frames taken from previous stats.
 * @property {Number} prevTimestamp Timestamp taken from previous stats.
 *
 * @param {Stats} frameStatValues
 * @returns {Number} Video frameRate
 */

const calculateFrameRate = (videoFrameStatValues = {}) => {
  if (!videoFrameStatValues) {
    return 0;
  }

  const {
    currentStatFrames = 0,
    prevStatFrames = 0,
    currentTimestamp = 0,
    prevTimestamp = 0,
  } = videoFrameStatValues;

  if (currentStatFrames >= prevStatFrames && currentTimestamp > prevTimestamp) {
    const videoFrameRate = (currentStatFrames - prevStatFrames) /
      ((currentTimestamp - prevTimestamp) / 1000);
    return Math.round(videoFrameRate * 100) / 100;
  }

  return 0;
};

export default calculateFrameRate;

import hasServerReflexiveCandidatesBug from './hasServerReflexiveCandidatesBug';

// https://jira.vonage.com/browse/OPENTOK-45642
// should force TURN for bugged iOS and Safari versions
export default (sourceStreamId) => {
  // We should only force TURN for relayed participants
  if (sourceStreamId !== 'P2P') {
    return false;
  }

  return hasServerReflexiveCandidatesBug();
};

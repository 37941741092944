const hintToFieldMap = {
  architecture: 'event_ua_arch',
  bitness: 'event_ua_bitness',
  model: 'event_ua_model',
  platformVersion: 'event_ua_platform_version',
};

const getUserAgentClientHintData = async (userAgentData) => {
  const result = {};
  if (userAgentData?.getHighEntropyValues) {
    const data = await userAgentData.getHighEntropyValues(Object.keys(hintToFieldMap));
    if (data) {
      Object.entries(hintToFieldMap).forEach(([hint, field]) => {
        if (data[hint] !== undefined) {
          result[field] = data[hint];
        }
      });
    }
  }
  return result;
};

export default getUserAgentClientHintData;

import envDefault from './env';

// Video from iOS publishers are upside-down when viewed in picture-in-picture
// mode.
// See https://bugs.webkit.org/show_bug.cgi?id=210958
export default (deps = {}) => {
  const {
    env = envDefault,
  } = deps;

  // Notice that we're only checking the iOS version, instead of the browser
  // version.  Reason being, browsers on iOS (e.g., Chrome and Firefox) are based
  // on webkit.  This means they'll all have this bug, regardless of their
  // version number.
  return !!(env.isiOS && env.iOSVersion > 10);
};

/* eslint-disable no-param-reassign */
const setScalabilityMode = async (scalabilityMode, publisher) => {
  if (!scalabilityMode || !publisher) {
    return;
  }
  const senders = await publisher._getVideoSenders();

  const [sender] = senders;
  const senderParameters = sender.getParameters();
  senderParameters.encodings.forEach((encoding) => {
    encoding.scalabilityMode = scalabilityMode; // eslint-disable-line no-param-reassign
  });
  try {
    await sender.setParameters(senderParameters);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(`Unable to set scalabilityMode. ${err.message}`);
  }
};

export default setScalabilityMode;

import env from '../../helpers/env';
import browserRejectsOptionalMandatory from '../../helpers/browserRejectsOptionalMandatory';

const { name, version } = env;

export default function usingOptionalMandatoryStyleFactory(
  { navigator = window.navigator } = {}
) {
  return function usingOptionalMandatoryStyle(isScreenSharing) {
    return (
      // screen sharing should not be optionalMandatory in later chome or FF or chromium edge
      // see https://bugzilla.mozilla.org/show_bug.cgi?id=1321221 for firefox getDisplayMedia
      // see https://w3c.github.io/mediacapture-screen-share/#displaymediastreamconstraints for updated getDisplayMedia
      // see https://blog.addpipe.com/standards-compliant-screen-capture-in-chrome-72/ chrome constraint info
      (isScreenSharing && !browserRejectsOptionalMandatory) ||

      // Internet Explorer
      !(navigator.mediaDevices && navigator.mediaDevices.getUserMedia) ||

      // Chrome <=60 doesn't accept ideal constraints and advanced
      // constraints are ignored by Firefox and broken by adapter.js
      // so we revert to optional/mandatory style. This has been
      // updated in Chrome 61.
      // See OPENTOK-32149, OPENTOK-32129 and OPENTOK-32655
      // Fixed in adapter.js: https://github.com/webrtc/adapter/issues/553
      (name === 'Chrome' && version < 61)
    );
  };
}

// TODO: Eliminate the need for this module, which is globally tracking these objects.
import Collection from '../../common-js-helpers/collection';

const sessionObjects = {
  // Publishers are id'd by their guid
  publishers: new Collection('guid'),
  // Subscribers are id'd by their widgetId
  subscribers: new Collection('widgetId'),
  sessions: new Collection(),
};

export default sessionObjects;

import { ONE_SECOND_IN_MS, ONE_MINUTE_IN_MS } from '../../../../common-js-helpers/time';

const THIRTY_SECONDS_IN_MS = 30 * ONE_SECOND_IN_MS;
const TWO_MINUTES_IN_MS = 2 * ONE_MINUTE_IN_MS;
const probingIntervals = [THIRTY_SECONDS_IN_MS, ONE_MINUTE_IN_MS, TWO_MINUTES_IN_MS];

/** The network probes will be run at backing off time intervals e.g. 30s, 60s, 120s, 120s and so on.
 * This function takes the current probe count and returns the needed delay in ms.
 * e.g. after 2 failed probes, we run the 3rd probe. Probe count 3 -> 120s.
 */
const getDelayForProbeNumber = probeCount =>
  probingIntervals[probeCount] ?? probingIntervals[probingIntervals.length - 1];

export default getDelayForProbeNumber;

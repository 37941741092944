import EventEmitter from 'events';
import AMRStates from './amrStates';
import AMRStateDurationsDefault from './amrStateDurations';

const createAmrStateMachine = (deps = {}) => {
  const { TRANSITION_TIMEOUT } = { ...AMRStateDurationsDefault, ...deps };

  const ee = new EventEmitter();
  const amrStateMachine = {
    state: AMRStates.MANTIS,
    pendingTransitions: [],
    timeout: undefined,

    isTransitioning() {
      return this.state === AMRStates.TRANSITIONING;
    },

    clearTransitionTimeout() {
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = undefined;
      }
    },

    startTransitionToRelayed() {
      // Transition to relayed is async, so when the transition has started we just change the state
      // to TRANSITIONING.
      this.state = AMRStates.TRANSITIONING;
      this.clearTransitionTimeout();
      this.timeout = setTimeout(() => {
        ee.emit('transitionToP2PTimeout');
      }, TRANSITION_TIMEOUT);
    },

    completeTransitionTo(state) {
      // Only the transition to p2p is async. We don't call completeTransitionTo for routed.
      if (state === AMRStates.P2P) {
        this.clearTransitionTimeout();
        this._transitionTo(state);
      }
    },

    startTransitionToRouted(processTransition) {
      if (this.state === AMRStates.TRANSITIONING) {
        this.pendingTransitions.push({ to: AMRStates.MANTIS, process: processTransition });
      } else {
        this._transitionTo(AMRStates.MANTIS, processTransition);
      }
    },

    _transitionTo(state, process) {
      if (process) {
        this.state = AMRStates.TRANSITIONING;
        process();
      }

      this.state = state;
      this._onTransitionCompleted();
    },

    _onTransitionCompleted() {
      if (this.pendingTransitions.length) {
        const { to, process } = this.pendingTransitions.shift();
        this._transitionTo(to, process);
      }
    },
  };
  return Object.assign(ee, amrStateMachine);
};

export default createAmrStateMachine;

import EventEmitter from 'events';

/*
 * Creates a permission listener for detecting changes in microphone and camera permissions.
 *
 * @function createMediaPermissionListener
 * @param {boolean} hasAudioVideoDevices - Indicates if the user has been publishing audio and video.
 * @returns {Promise<EventEmitter & { createMediaPermissionListener: Function, device: string }>}
 * An EventEmitter instance with additional properties `createMediaPermissionListener` and `device`.
 *
 */
const createMediaPermissionListener = async (hasAudioVideoDevices, deps = {}) => {
  const ee = new EventEmitter();
  const navigator = deps.navigator || global.navigator;

  let videoPermissionStatus;
  let audioPermissionStatus;

  const createPermissionListeners = async () => {
    if (
      typeof navigator.permissions !== 'undefined' &&
      typeof navigator.permissions.query === 'function'
    ) {
      try {
        // This will throw in Firefox if device permissions aren't supported
        videoPermissionStatus = await navigator.permissions.query({ name: 'camera' });
        audioPermissionStatus = await navigator.permissions.query({ name: 'microphone' });
      } catch (error) {
        // Fail silently because it is not worth throwing in this situation
      }
    }
  };

  await createPermissionListeners();

  const onAudioPermissionChanged = () => {
    if (audioPermissionStatus.state === 'denied' && hasAudioVideoDevices) {
      ee.emit('accessDenied', 'microphone');
    }
  };

  const onVideoPermissionChanged = () => {
    if (videoPermissionStatus.state === 'denied' && hasAudioVideoDevices) {
      ee.emit('accessDenied', 'camera');
    }
  };

  const removeAudioListener = () => {
    audioPermissionStatus.removeEventListener('change', onAudioPermissionChanged);
  };
  const removeVideoListener = () => {
    videoPermissionStatus.removeEventListener('change', onVideoPermissionChanged);
  };

  if (videoPermissionStatus && audioPermissionStatus) {
    audioPermissionStatus.addEventListener('change', () => {
      onAudioPermissionChanged();
      removeAudioListener();
    });

    videoPermissionStatus.addEventListener('change', () => {
      onVideoPermissionChanged();
      removeVideoListener();
    });
  }

  return ee;
};

export default createMediaPermissionListener;

import DefaultNativeVideoElementWrapperFactory from './NativeVideoElementWrapper/NativeVideoElementWrapper';

/**
 * VideoElementFacadeFactory DI Container for VideoElementFacade
 *
 * @package
 * @param {Object} [dependencies]
 * @param {typeof NativeVideoElementWrapper} [dependencies.NativeVideoElementWrapper]
 * @return {typeof VideoElementFacade}
 */
function VideoElementFacadeFactory({
  NativeVideoElementWrapper = DefaultNativeVideoElementWrapperFactory(),
} = {}) {
  const VideoElementWrapper = NativeVideoElementWrapper;
  const defaultAudioVolume = 50;

  /**
   * Create a new VideoElementFacade
   *
   * Was used to simplify the strategy between Plugin and Native.
   *
   * @todo This needs to go.
   *
   * Note: Could VideoElementFacade not exist? Could it just be an expected interface
   * for VideoElementWrapper which could be either PluginVideoElementWrapper or
   * NativeVideoElementWrapper
   *
   * @package
   * @class
   * @param {Object} [config]
   * @param {String} [config.fallbackText] displayed when WebRTC is not supported
   * @param {Boolean} [config.muted] initial mute setting
   * @param {String} [config.fitMode] fitMode a parameter passed to VideoElementWrapper
   * @param {Object} [config._inject] @todo use the DI system
   * @param {Function} [errorHandler=() => {}] error callback function
   */
  function VideoElementFacade({
    fallbackText = 'Sorry, Web RTC is not available in your browser',
    fitMode,
    muted, // used if it's a publisher
    _inject,
    widgetType,
  } = {}) {
    return new VideoElementWrapper({
      fallbackText,
      fitMode,
      muted,
      defaultAudioVolume,
      _inject,
      widgetType,
    });
  }
  return VideoElementFacade;
}

export default VideoElementFacadeFactory;

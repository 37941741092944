import env from './env';
import browserSupported from './singlePeerConnectionSupportedBrowsers';

export default () => {
  const isBrowserSupported = env.isChromium || env.isFirefox || env.isSafari;

  if (!isBrowserSupported) {
    return false;
  }

  const { name, version } = env;
  const minimumVersion = browserSupported[name];

  const isVersionSupported = version >= minimumVersion;

  return isVersionSupported;
};

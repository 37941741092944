// Given "ws://foo.vonage.com/", returns "foo.vonage.com"

const trimProxyUrl = (proxyUrl) => {
  const trimmedProxyUrl = proxyUrl
    // remove trailing slashes
    .replace(/\/+$/, '')
    // remove the scheme (e.g., "ws://foo.vonage.com" -> "foo.vonage.com")
    .replace(/^.*:\/+/, '');
  return trimmedProxyUrl;
};

export default trimProxyUrl;

import SDPHelpers from '../sdp_helpers';

/**
 * Construct the full SDP media section array  combining the mantis partial answer and current local description
 * @param {*} partialRemoteAnswer - partial answer SDP from mantis
 * @param {*} currentRemoteDescription - current remote description on PC
 * @returns {Array<Array<string>>} - An array of mediaSection arrays
 */
const getMediaSectionsForAnswer = (partialRemoteAnswer, currentRemoteDescription) => {
  // This is an answer to our ICE restart offer, so the head media section has changed
  const newHeadMid = partialRemoteAnswer.mids[0];
  const tracksToDisable = currentRemoteDescription.tracks
    .slice(0, currentRemoteDescription.tracks.indexOf(newHeadMid))
    .filter(mid => mid !== null);

  const newHeadIndex = currentRemoteDescription.mids.indexOf(newHeadMid);
  // Any tracks in the SDP before the new head must be disabled
  const disabledMediaSections = currentRemoteDescription.mediaSections
    .slice(0, newHeadIndex)
    .map((section) => {
      const [mid] = SDPHelpers.getTrackMids(section);
      if (mid && tracksToDisable.includes(mid)) {
        return SDPHelpers.getDisabledSectionForMid(mid);
      }
      return section;
    });

  // We construct the new media section using first the new disabled section,
  // then the updated head section, then append the rest which stay the same
  const fullMediaSections = [
    ...disabledMediaSections,
    ...partialRemoteAnswer.mediaSections,
    ...currentRemoteDescription.mediaSections.slice(newHeadIndex + 1),
  ];
  return fullMediaSections;
};

export default getMediaSectionsForAnswer;

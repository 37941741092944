import env from '../../helpers/env';

// Callback-based API was removed in ElectronJS 7, which corresponds to
// Chrome 78
const isCallbackBasedAPI = env.version < 78;
const isPromiseBasedAPI = !isCallbackBasedAPI;

export default {
  isCallbackBasedAPI,
  isPromiseBasedAPI,
};

import assign from 'lodash/assign';
import uuid from 'uuid';
import createLogger from '../log';
import QueueRunner from '../QueueRunner';
import defaultAjax from '../../common-js-helpers/ajax';
import prependProxyToUrlIfNeeded from '../proxyUrlHelper';
import { getProxyUrl } from '../../ot/proxyUrl';

const logging = createLogger('analytics');
const clientInstanceId = uuid();
const defaultQueue = new QueueRunner();

export default function Analytics({ loggingUrl, queue = defaultQueue, ajax = defaultAjax }) {
  this.loggingUrl = loggingUrl;

  this.updateEndPoints = () => {
    if (!this.proxyUrl) {
      this.proxyUrl = getProxyUrl();
      const prependedUrl = prependProxyToUrlIfNeeded(this.loggingUrl, this.proxyUrl);
      this.loggingUrl = prependedUrl;
    }

    this.endPoint = `${this.loggingUrl}/logging/ClientEvent`;
    this.endPointQos = `${this.loggingUrl}/logging/ClientQos`;
  };
  this.updateEndPoints();

  const reportedErrors = {};

  const post = (data, onComplete = () => { }, isQos) => {
    queue.add((callback) => {
      logging.debug('sending analytics:', this.loggingUrl, data);
      ajax.post((isQos ? this.endPointQos : this.endPoint), {
        body: data,
        overrideMimeType: 'text/plain',
        headers: {
          Accept: 'text/plain',
          'Content-Type': 'application/json',
        },
      }, (err) => {
        if (err) {
          logging.debug('Failed to send ClientEvent, moving on to the next item.');
        }
        onComplete(err || undefined);
        callback();
      });
    });
  };

  const shouldThrottleError = (code, type, partnerId) => {
    if (!partnerId) {
      return false;
    }

    const errKey = [partnerId, type, code].join('_');
    const msgLimit = 100;

    return (reportedErrors[errKey] || 0) >= msgLimit;
  };

  // Log an error via ClientEvents.
  //
  // @param [String] code
  // @param [String] type
  // @param [String] message
  // @param [Hash] details additional error details
  //
  // @param [Hash] options the options to log the client event with.
  // @option options [String] action The name of the Event that we are logging. E.g.
  //  'TokShowLoaded'. Required.
  // @option options [String] variation Usually used for Split A/B testing, when you
  //  have multiple variations of the +_action+.
  // @option options [String] payload The payload. Required.
  // @option options [String] sessionId The active OpenTok session, if there is one
  // @option options [String] connectionId The active OpenTok connectionId, if there is one
  // @option options [String] partnerId
  // @option options [String] guid ...
  // @option options [String] streamId ...
  // @option options [String] section ...
  // @option options [String] clientVersion ...
  //
  // Reports will be throttled to X reports (see exceptionLogging.messageLimitPerPartner
  // from the dynamic config for X) of each error type for each partner. Reports can be
  // disabled/enabled globally or on a per partner basis (per partner settings
  // take precedence) using exceptionLogging.enabled.
  //
  this.logError = (code, type, message, details = null, options = {}) => {
    const { partnerId } = options;

    if (shouldThrottleError(code, type, partnerId)) {
      // OT.log('ClientEvents.error has throttled an error of type ' + type + '.' +
      // code + ' for partner ' + (partnerId || 'No Partner Id'));
      return;
    }

    const errKey = [partnerId, type, code].join('_');
    const payload = details;

    reportedErrors[errKey] = typeof (reportedErrors[errKey]) !== 'undefined' ?
      reportedErrors[errKey] + 1 : 1;

    this.logEvent(assign(options, {
      action: `${type}.${code}`,
      payload,
    }), false);
  };

  // Log a client event to the analytics backend.
  //
  // @example Logs a client event called 'foo'
  //  this.logEvent({
  //      action: 'foo',
  //      payload: 'bar',
  //      sessionId: sessionId,
  //      connectionId: connectionId
  //  }, false)
  //
  // @param [Hash] data the data to log the client event with.
  // @param [Boolean] qos Whether this is a QoS event.
  // @param [Boolean] throttle A number specifying the ratio of events to be sent
  //        out of the total number of events (other events are not ignored). If not
  //        set to a number, all events are sent.
  // @param [Number] completionHandler A completion handler function to call when the
  //                 client event POST request succeeds or fails. If it fails, an error
  //                 object is passed into the function. (See throttledPost().)
  //
  this.logEvent = (eventData, qos = false, throttle, completionHandler = () => { }) => {
    this.updateEndPoints();
    const data = {
      ...eventData,
      clientInstanceId,
    };

    if (throttle && !isNaN(throttle)) {
      if (Math.random() > throttle) {
        logging.debug('skipping sending analytics due to throttle:', data);
        return;
      }
    }

    logging.debug('queueing analytics:', this.loggingUrl, data);

    let serializedData;
    try {
      serializedData = JSON.stringify(data);
    } catch (err) {
      completionHandler(err);
      return;
    }

    post(serializedData, completionHandler, qos);
  };

  // Log a client QOS to the analytics backend.
  // @option options [String] action The name of the Event that we are logging.
  //  E.g. 'TokShowLoaded'. Required.
  // @option options [String] variation Usually used for Split A/B testing, when
  //  you have multiple variations of the +_action+.
  // @option options [String] payload The payload. Required.
  // @option options [String] sessionId The active OpenTok session, if there is one
  // @option options [String] connectionId The active OpenTok connectionId, if there is one
  // @option options [String] partnerId
  // @option options [String] guid ...
  // @option options [String] streamId ...
  // @option options [String] section ...
  // @option options [String] clientVersion ...
  //
  this.logQOS = options => this.logEvent(options, true);
}

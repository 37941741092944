import env from './env';

const enforcesUnifiedPlan = () =>
  // Chromium-based browsers version M90 logs a warning that Plan B is being removed
  ((env.isChrome || env.isElectron) && env.version >= 90) ||
  (env.isOpera && env.version >= 76);

export default () => (
  !enforcesUnifiedPlan() &&
  // For legacy browsers, we still enforce Plan B
  ((env.isChrome && env.version > 71) ||
   (env.isChromiumEdge && env.version < 90) ||
   (env.isOpera && env.version > 58))
);

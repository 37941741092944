/* eslint-disable global-require */
import BackgroundReplacementConfig from './backgroundReplacementConfig';
import BackgroundBlurConfig from './backgroundBlurConfig';

// These are all the transformers we support
const filterToConfiguratorMap = {
  backgroundBlur: BackgroundBlurConfig,
  backgroundReplacement: BackgroundReplacementConfig,
};

const isSupported = type =>
  !!filterToConfiguratorMap[type];

const getConfigurator = (type) => {
  if (!isSupported(type)) {
    return null;
  }

  return filterToConfiguratorMap[type];
};

const isValidConfig = (config) => {
  const { type } = config;

  if (!isSupported(type)) {
    return false;
  }

  return getConfigurator(type).isValidConfig(config);
};

const MediaTransform = {
  isSupported,
  getConfigurator,
  isValidConfig,
};

export default MediaTransform;

import promiseFinally from 'promise-finally';
import pTimeout from 'p-timeout';
import eventHelper from './eventHelper';
import VideoElementFacadeFactory from './video_element/index';

// eslint-disable-next-line no-unused-vars
const typeVideoElementFacade = VideoElementFacadeFactory();

/**
 * Wait for the video to be playing
 *
 * @param {typeVideoElementFacade} videoElementFacade
 * @param {Number} maxWaitTime
 *
 * @returns Promise<Boolean> true if we think the video is playing, false if we timeout
 */
function waitForVideoToBePlaying(
  videoElementFacade,
  maxWaitTime = 5000
) {
  const videoElementFacadeEvents = eventHelper(videoElementFacade);

  const promise = new Promise((resolve) => {
    videoElementFacadeEvents.on('timeupdate', resolve);
    videoElementFacadeEvents.on('loadedmetadata', resolve);
  });

  return promiseFinally(
    pTimeout(promise, maxWaitTime, () => false),
    () => videoElementFacadeEvents.removeAll()
  );
}

export default waitForVideoToBePlaying;

import createLogger from '../../helpers/log';

const logging = createLogger('MediaProcessorConnector');

class MediaProcessorConnector {
  constructor(connector) {
    this.connector = connector;
  }

  setTrack = async (track) => {
    let filteredTrack = null;

    try {
      filteredTrack = await this.connector.setTrack(track);
      this.originalTrack = track;
    } catch (err) {
      logging.error(`Error setting track: ${err}`);
    }

    return filteredTrack;
  };

  destroy = async () => {
    try {
      await this.connector.destroy();
    } catch (err) {
      logging.warn(`Error destroying connector: ${err}`);
    }
  };

  stopOriginalTrack = () => {
    if (this.originalTrack) {
      this.originalTrack.stop();
      this.originalTrack = null;
    }
  };

  static isValidConnector = connector => typeof connector?.setTrack === 'function'
    && typeof connector?.destroy === 'function';
}

export default MediaProcessorConnector;

function createCleanupJobs() {
  const jobs = [];
  return {
    add(job) {
      jobs.push(job);
    },
    remove(job) {
      const index = jobs.indexOf(job);
      if (index >= 0) {
        jobs.splice(index, 1);
      }
    },
    releaseAll() {
      const errors = [];
      while (jobs.length > 0) {
        const job = jobs.pop();
        try {
          job();
        } catch (err) {
          errors.push(err);
        }
      }
      const count = errors.length;
      if (count > 0) {
        throw new Error(
          `Failed to complete ${count} cleanup job${count === 1 ? '' : 's'}: ${
            errors.map(err => `<${err}>`).join(', ')}`
        );
      }
    },
  };
}

export default createCleanupJobs;
